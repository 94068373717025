import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import './HeadControlPanel.scss'
import cs from 'classnames'
import { selectElementDisplayType } from '~/selectors/useInterface'
import { DISPLAY_TYPE } from '~/config/constants/ui'

export default function HeadControlPanel({
  caption,
  children,
  fullWidth = false,
}) {
  const { sourceList: sourceListDisplayType } = useSelector(selectElementDisplayType)
  return (
    <div className={cs('head', {
      row: sourceListDisplayType !== DISPLAY_TYPE.MIN_WIDTH,
      fullWidth,
    })}
    >
      <div className={cs('head-control', {
        row: sourceListDisplayType !== DISPLAY_TYPE.MIN_WIDTH,
      })}
      >
        <span className="head-control__caption">{caption}</span>
        {Boolean(children) && (
          <div className={cs('head-control__wrap', { row: sourceListDisplayType !== DISPLAY_TYPE.MIN_WIDTH })}>
            {children}
          </div>
        )}
      </div>

    </div>
  )
}

HeadControlPanel.propType = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.element,
}

HeadControlPanel.defaultProps = {
  children: null,
}
