import { DRAGNDROP_TYPE } from 'enums'
import React, { createContext, ReactNode, useMemo } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'

export const CustomDragLayerContext = createContext({} as State)

export type ItemType = typeof DRAGNDROP_TYPE[keyof typeof DRAGNDROP_TYPE]
type State = {
  isDragging: boolean,
  clientOffset: XYCoord | null,
  sourceOffset: XYCoord | null,
  item: { id: string, selectedItems: Array<unknown>, width?: string},
  itemType: ItemType,
  initialClientOffset: XYCoord | null,
  initialSourceOffset: XYCoord | null
}

type Props = {
  children: ReactNode | ReactNode[]
}

function DndContext({ children }: Props) {
  const props = useDragLayer(monitor => ({
    item: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    sourceOffset: monitor.getSourceClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset(),
    initialSourceOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType() as ItemType,
  }))

  const { clientOffset, isDragging } = props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => props, [ clientOffset, isDragging ])

  return (
    <CustomDragLayerContext.Provider value={value}>{children}</CustomDragLayerContext.Provider>
  )
}

export default DndContext
