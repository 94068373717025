import { useDispatch, useSelector } from 'react-redux'
import { changeSplitSize, setUIElementDisplayType } from 'actions/userInterface'
import { LEFT_MENU_WIDTH, MEDIA_BLOCK_MIN_WIDTH_OPTION, TIMELINE_MIN_WIDTH_WITHOUT_RESIZER } from 'constant'
import { useRef } from 'react'
import { isNil } from 'lodash'
import { useAction } from 'hooks/utils'
import { DISPLAY_TYPE, UI_ELEMENT } from 'config/constants/ui'
import { selectElementDisplayType } from 'selectors/useInterface'

type UseEditorSplitSizeProps = {
  previewRef: React.MutableRefObject<{
    pane1: HTMLDivElement,
    pane2: HTMLDivElement
  } | null>,
  windowWidth: number
}

export const useEditorSplitSize = ({ previewRef, windowWidth }: UseEditorSplitSizeProps) => {
  const dispatch = useDispatch()
  const prevSizeNum = useRef<null | number>(null)
  const onSetUIElementDisplayType = useAction(setUIElementDisplayType)
  const {
    sourceList: sourceListDisplayType,
  } = useSelector(selectElementDisplayType)
  const onChangeSplitVertical = (newSize: number) => {
    if (isNil(prevSizeNum.current)) prevSizeNum.current = newSize
    const pane1PreviewWidth = previewRef.current?.pane1?.offsetWidth ?? 0
    const pane2PreviewWidth = previewRef.current?.pane2?.offsetWidth ?? 0
    const sumPreview = pane1PreviewWidth + pane2PreviewWidth
    const sizeAll = pane1PreviewWidth + pane2PreviewWidth + newSize
    const diff = newSize - prevSizeNum.current
    if (previewRef.current?.pane1 && (((sizeAll > windowWidth)
      && (sumPreview >= TIMELINE_MIN_WIDTH_WITHOUT_RESIZER)) || (diff <= 0))) {
      // eslint-disable-next-line no-param-reassign
      previewRef.current.pane1.style.width = `${pane1PreviewWidth - diff}px`
    }
    prevSizeNum.current = newSize
    dispatch(changeSplitSize({ value: newSize, type: 'media' }))

    const maxLeftSize = MEDIA_BLOCK_MIN_WIDTH_OPTION + LEFT_MENU_WIDTH
    if (newSize > maxLeftSize && sourceListDisplayType === DISPLAY_TYPE.MIN_WIDTH) {
      onSetUIElementDisplayType(DISPLAY_TYPE.HALF_WIDTH, UI_ELEMENT.SOURCE_LIST)
    } else if (newSize <= maxLeftSize && sourceListDisplayType !== DISPLAY_TYPE.MIN_WIDTH) {
      onSetUIElementDisplayType(DISPLAY_TYPE.MIN_WIDTH, UI_ELEMENT.SOURCE_LIST)
    }
  }

  return {
    onChangeSplitVertical,
  }
}
