import { DISPLAY_TYPE } from 'config/constants/ui'
import { PREVIEW_CONTAINER_MIN_HEIGHT, SPLIT_PREVIEW_DEFAULT_WIDTH, TIMELINE_LAYERS_DEFAULT_HEIGHT } from 'constant'
import React, { FC } from 'react'
import SplitPane from 'react-split-pane'
import { SPLIT_RESIZER } from 'types/common'
import { getWindowHeight } from 'Util'

interface IProps {
  onSetResizing(resizing: boolean, resizer?: SPLIT_RESIZER): void
  timelineDisplayType: typeof DISPLAY_TYPE[keyof typeof DISPLAY_TYPE]
}

export const PreviewWrapper:FC<IProps> = ({ children, onSetResizing, timelineDisplayType }) => {
  const windowHeight = getWindowHeight()
  switch (timelineDisplayType) {
    case DISPLAY_TYPE.FULL_WIDTH: {
      return (
        <>
          {children}
        </>
      )
    }
    case DISPLAY_TYPE.HALF_WIDTH: {
      return (
        <SplitPane
          split="horizontal"
          minSize={PREVIEW_CONTAINER_MIN_HEIGHT}
          defaultSize={SPLIT_PREVIEW_DEFAULT_WIDTH}
          maxSize={windowHeight - TIMELINE_LAYERS_DEFAULT_HEIGHT}
          onDragStarted={() => onSetResizing(true, SPLIT_RESIZER.TIMELINE)}
          onDragFinished={() => onSetResizing(false)}
        >
          {children}
        </SplitPane>
      )
    }
    default:
      return null
  }
}
