import cx from 'classnames'
import MessageBox from 'components/base/MessageBox'
import { TranslationContext } from 'contexts/TranslationContext'
import { useEditorActions } from 'hooks/useEditorActions'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Actions from '~/actions'
import { ConfirmationDialog } from '~/components/base/ConfirmationDialog/ConfirmationDialog'
import { getNameFromPath, getPathFromPathName, timeToFractions } from '../../Util'
import { useAction, useCompose, useModalDialog } from '../../hooks'
import { getAssets, getPrefferedOutputFormat, getProjectDuration } from '../../selectors'
import Button from '../base/Button'
import './TimelineFooter.scss'
import ZoomSlider from './ZoomSlider'
import { NW_JS_APP_PROFILES } from '~/enums'
import { DEFAULT_PROJECT_OUTPUT_NAME } from '~/constant'
import { DROP_CONTAINER } from '~/config/constants/ui'
import { Orientation } from '~/types/userInterface'
import { TransformUIDropContainer } from '~/components/TransformUIDropContainer'

function TimelineFooter({ saveDisabled }) {
  const { t } = useContext(TranslationContext)
  const assets = useSelector(getAssets)
  const showSaveSettings = useSelector(state => state.mainView.showSaveSettings)
  const { hours, minutes, seconds } = useSelector(useCompose(timeToFractions, getProjectDuration))
  const saveProject = useAction(Actions.saveProject.saveProject)
  const format = useSelector(getPrefferedOutputFormat)
  const outputName = useSelector(state => state.project.outputName === DEFAULT_PROJECT_OUTPUT_NAME
    ? t('PUBLISH_SETTINGS_OUTPUT_NAME')
    : state.project.outputName)
  const outputPath = useSelector(state => state.project.outputPath)
  const openSettings = useAction(Actions.mainView.showSaveSettings, true)

  const [ curAbsPathName, setCurAbsPathName ] = useState(null)

  const savingResultDialog = useModalDialog({})

  const [ unableToSave, setUnableToSave ] = React.useState(false)

  const onUnableToSaveMessageClose = useCallback(() => {
    setUnableToSave(false)
  }, [])

  const inputRef = useRef()
  const inputStyle = { display: 'none' }

  const { onPublish, isRenderingSupported } = useEditorActions({ inputRef })

  const curOutputName = curAbsPathName ? getNameFromPath(curAbsPathName) : outputName
  const curOutputPath = curAbsPathName ? getPathFromPathName(curAbsPathName) : ''
  const prepareTask = useAction(Actions.project.prepareTask, curOutputName, curOutputPath)
  const createTask = useAction(Actions.project.requestCreateTask, curAbsPathName, format)
  const onOutputFileSelected = useCallback(e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0]
      const pathName = file.path || file.name
      const pathNameWOExt = pathName.slice(0, pathName.lastIndexOf('.'))
      setCurAbsPathName(pathNameWOExt)
    }
  }, [ setCurAbsPathName ])

  useEffect(() => {
    if (curAbsPathName) {
      prepareTask()
      setCurAbsPathName(null)
      createTask()
      openSettings()
    }
  }, [ curAbsPathName,
    prepareTask,
    createTask,
    openSettings ])

  const onClickSave = async () => {
    await saveProject()
    savingResultDialog.open()
  }

  const handleClickPublish = () => {
    if (isRenderingSupported) {
      onPublish()
    } else {
      setUnableToSave(true)
    }
  }

  return (
    <>
      <TransformUIDropContainer
        orientation={Orientation.Horizontal}
        dropContainer={DROP_CONTAINER.FOOTER}
      />
      <div className="timeline-footer">
        <div className="timeline-footer__slider">
          <ZoomSlider />
        </div>
        <div className="timeline-footer__duration">
          {t('TIMELINE_FOOTER_TEXT_DURATION')}
          :
          {' '}
          <span>{`${hours} h. ${minutes} m. ${seconds} s.`}</span>
        </div>
        <div className={cx('timeline-footer__btns', { 'timeline-footer__btns--disabled': saveDisabled })}>
          <If condition={__CFG__.SAVE_PROJECT_MENU.SAVE_PROJECT_BUTTON}>
            <div className="timeline-footer__btn">
              <Button
                className="timeline__btn-save"
                disabled={assets.length === 0 || showSaveSettings}
                primary
                onClick={onClickSave}
                title={t('TIMELINE_FOOTER_BTN_SAVE_TOOLTIP')}
                data-lang-id="TIMELINE_FOOTER_BTN_SAVE"
              >
                {t('TIMELINE_FOOTER_BTN_SAVE')}
              </Button>
            </div>
          </If>
          <div className="timeline-footer__btn">
            <If condition={NW_JS_APP_PROFILES.includes(__APP_PROFILE__)}>
              <input ref={inputRef} type="file" value="" accept={`.${format}`} nwworkingdir={outputPath} nwsaveas={outputName} style={inputStyle} onChange={onOutputFileSelected} />
            </If>
            <Button
              className="timeline__btn-save"
              disabled={assets.length === 0 || showSaveSettings}
              primary
              onClick={handleClickPublish}
              title={NW_JS_APP_PROFILES.includes(__APP_PROFILE__) ? t('TIMELINE_FOOTER_BTN_SAVE_TOOLTIP') : t('TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP')}
              data-lang-id={NW_JS_APP_PROFILES.includes(__APP_PROFILE__) ? 'TIMELINE_FOOTER_BTN_SAVE' : 'TIMELINE_FOOTER_BTN_PUBLISH'}
            >
              {NW_JS_APP_PROFILES.includes(__APP_PROFILE__) ? t('TIMELINE_FOOTER_BTN_SAVE') : t('TIMELINE_FOOTER_BTN_PUBLISH')}
            </Button>
          </div>
        </div>
      </div>
      <If condition={savingResultDialog.active}>
        <ConfirmationDialog
          onCancel={savingResultDialog.cancel}
          closeBtnText="Close"
          title="The project was saved!"
        />
      </If>
      {unableToSave && (
        <MessageBox
          title={t('TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH')}
          message={t('TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED')}
          onClose={onUnableToSaveMessageClose}
        />
      )}
    </>
  )
}

export default TimelineFooter
