import { createSelector } from 'reselect'

export const selectMediaRecordingType = (state: RootState) => state.mainView.showFeatures.mediaRecordingSettings

export const selectPlayerVolume = (state: RootState) => state.mainView.playerVolume as number

export const selectOfflineRestoration = (state: RootState) => state.mainView.offlineRestoration
export const selectMenuItem = createSelector([
  (state: RootState) => state.mainView.menuItem,
], menuItem => menuItem)

export const selectShowSaveSettings = createSelector([
  (state: RootState) => state.mainView.showSaveSettings,
], showSaveSettings => showSaveSettings)

export const selectShowNabletHld = createSelector([
  (state: RootState) => state.mainView.showNabletHldSettings,
], showNabletHldSettings => showNabletHldSettings)

export const selectShowCustomizeView = createSelector([
  (state: RootState) => state.mainView.showCustomizeView,
], showCustomizeView => showCustomizeView)

export const selectShowImportFromUrl = createSelector([
  (state: RootState) => state.mainView.showImportFromUrl,
], showImportFromUrl => showImportFromUrl)

export const selectActiveDragElement = createSelector([
  state => state.mainView.activeDragElement,
], activeDragElement => activeDragElement)

export const selectShowEditorSettings = createSelector([
  (state: RootState) => state.mainView.showEditorSettings,
], showEditorSettings => showEditorSettings)

export const selectEditorSettings = createSelector([
  (state: RootState) => state.mainView.editorSettings,
], editorSettings => editorSettings)

export const selectRenderSettings = createSelector([
  (state: RootState) => state.mainView.editorSettings,
], editorSettings => editorSettings.render_settings)

export const selectDragElementBounds = createSelector([
  state => state.mainView.dragElementBounds,
], dragElementBounds => dragElementBounds as DOMRect)
