import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { AudioPanel, MediaPanel, TextPanel, TransitionPanel } from 'components/AssetPanels'
import { TranslationContext } from 'contexts/TranslationContext'
import { MENU_ITEMS } from 'enums'
import SectionPlaceholder from 'components/SectionPlaceholder/SectionPlaceholder'
import ImageAssetsPanel from 'components/AssetPanels/Images/Panel'

export const MenuPanel = () => {
  const { menuItem } = useSelector((state: RootState) => state.mainView)
  const { t } = useContext(TranslationContext)
  switch (menuItem) {
    case MENU_ITEMS.MEDIA:
      return <MediaPanel />

    case MENU_ITEMS.TRANSITIONS:
      return <TransitionPanel />

    case MENU_ITEMS.TEXT:
      return <TextPanel />

    case MENU_ITEMS.AUDIO:
      return <AudioPanel />

    case MENU_ITEMS.FILTERS:
      return <SectionPlaceholder title={t('FILTERS')} />

    case MENU_ITEMS.DESIGN:
      return <SectionPlaceholder title={t('DESIGN')} />

    case MENU_ITEMS.IMAGES:
      return <ImageAssetsPanel />

    default:
      return null
  }
}
