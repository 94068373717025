import { getFPSByRefVideo } from 'Util'
import { useSelector } from 'react-redux'
import { getReferenceVideoAsset } from 'selectors'
import { UNIT_MEASUREMENT } from 'config/constants/timecode'
import { selectTimeCodeMode } from 'selectors/playback'
import { TimeCodeContainerLocationType } from 'types/timecode'
import { useTimeCodeState } from './useTimeCodeState'
import { useTimeCodeEventListeners } from './useTimeCodeEventListeners'
import { useTimeCodeRefs } from './useTimeCodeRefs'
import { useContextMenu } from './useContextMenu'

type UseTimeCodeType = {
  progress: number
  containerLocation: TimeCodeContainerLocationType
  onRewind?(sec: number): void
  onMoveSlider?(unitSec: number): void
  fps?: number
  readonly?: boolean
  isTimelineTime?: boolean
  startTime: number
}

export const useTimeCode = ({
  progress,
  containerLocation,
  onRewind,
  onMoveSlider,
  fps,
  readonly,
  isTimelineTime,
  startTime,
}: UseTimeCodeType) => {
  const refVideo = useSelector(getReferenceVideoAsset)
  const currentFps = fps || getFPSByRefVideo(refVideo)
  const timeModes = useSelector(selectTimeCodeMode)
  const timeMode = timeModes[containerLocation]

  const {
    timeUnits,
    isActiveContainer,
    onDeactivateContainer,
    onActiveUnit,
    onKeyDown,
    onChangeMode,
    onContainerArrowsIncrementControl,
    onChangeUnit,
  } = useTimeCodeState({
    containerLocation,
    progress,
    onRewind,
    onMoveSlider,
    fps: currentFps,
    readonly,
    timeMode,
    isTimelineTime,
    startTime,
  })

  const { timeCodeRefs, timeCodeContainer } = useTimeCodeRefs({ timeUnits })

  useTimeCodeEventListeners({
    timeCodeContainer,
    onActiveUnit,
    onKeyDown,
    onDeactivateContainer,
  })

  const {
    anchorEl,
    onOpenMenu,
    onCloseMenu,
  } = useContextMenu({ container: timeCodeContainer.current })

  return {
    onContainerArrowsIncrementControl,
    unitMeasurementFormats: [ UNIT_MEASUREMENT.MILLISECOND, UNIT_MEASUREMENT.FRAME ],
    timeCodeContainer,
    timeCodeRefs,
    isActiveContainer,
    timeUnits,
    onChangeMode,
    timeMode,
    onChangeUnit,
    anchorEl,
    onOpenMenu,
    onCloseMenu,
    currentFps,
  }
}
