import { useTransformUIDropContainer } from 'hooks/ui/useTransformUIDropContainer'
import React, { FC } from 'react'
import { ITransformUIDropContainer } from 'types/userInterface'
import './TransformUIDropContainer.scss'

export const TransformUIDropContainer:FC<ITransformUIDropContainer> = ({ ...props }) => {
  const { styles, drop, isShowDropContainer } = useTransformUIDropContainer({ ...props })

  if (!isShowDropContainer) {
    return null
  }
  return <div style={styles} ref={ref => drop(ref)} className="transform-ui-drop-container" />
}
