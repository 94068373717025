/* eslint-disable no-console */
import { getVideoPosition } from 'Util/assets'
import { getAbsoluteAssetSize } from 'helpers/getAbsoluteAssetSize'
import { isAssetFitsPreviewFrame } from 'helpers/isAssetFitsPreviewFrame'
import { groupBy, isEmpty, isNil } from 'lodash'
import { FILE_TYPE, TRANSITIONS } from '~/enums'
import { calcProjectDuration } from '~/lib'
import { ImageAsset, TextAsset, VideoAsset } from '~/models/Asset'
import * as XTL from './XTL'
import { CLIP_FLAGS, TRACK_TYPE, TRANSITION_TRACK_TYPE, XTL_TRANSITION_TYPES } from './enums'


/**
 * |..l-pad..| clip |..r-pad..|
 * |          range           |
 *
 * @param {Assets.MediaAsset} clip
 * @param {number} totalDuration
 * @returns {{ left: number, right: number }}
 */
function getPadding(clip, totalDuration) {
  return {
    left: Math.max(0, clip.startTime),
    right: Math.max(0, totalDuration - clip.endTime),
  }
}
/**
 * @param {Assets.TransitionAsset[]} transitions
 * @param {Assets.MediaAsset[]} assets
 * @param {TRANSITIONS[]} types
 * @returns {Assets.TransitionAsset[]}
 */
const filterTransitions = ({ transitions, assets, types }) => transitions.reduce(
  (all, current) => {
    const { rightVideoAssetId, leftVideoAssetId } = current
    const assetsSkipCondition = assets
    && !assets.find(asset => asset.id === rightVideoAssetId || asset.id === leftVideoAssetId)
    if ((types && !types.includes(current.type))
      || assetsSkipCondition) {
      return all
    }
    all.push(current)
    return all
  },
  []
)

/**
* @param {Assets.MediaAsset[]} assets
* @returns {string}
*/
const prepareFileId = asset => {
  const { fileId, type } = asset
  switch (type) {
    case FILE_TYPE.DEVMEDIA:
      return `${FILE_TYPE.DEVMEDIA}_${fileId}`
    default:
      return fileId
  }
}

/**
 * @param {Assets.TransitionAsset[]} transitions
 * @param {number} totalDuration
 * @param {Assets.MediaAsset[]} assets
 * @returns {string}
 */
function composeDissolveRightVideoTracks(transitions, totalDuration, assets) {
  const dissolveTransitions = filterTransitions({
    transitions,
    assets,
    types: [ TRANSITIONS.DISSOLVE ],
  })
  return dissolveTransitions.map(transition => {
    const padding = getPadding(transition, totalDuration)
    const assetRef = assets.find(asset => asset.id === transition.rightVideoAssetId)
    if (assetRef === undefined) {
      return '' // in case of image on rightVideoAssetId it handled in pipOverlayTrack
    }
    const { mediaStart, mediaEnd } = assetRef
    const fileId = prepareFileId(assetRef)
    const options = {
      fileId,
      mediaStart,
      mediaEnd,
      padding,
      type: XTL_TRANSITION_TYPES.MIX,
    }
    return XTL.transitionTrack(transition, options)
  }).join('\n')
}

/**
 * @param {Assets.MediaAsset[]} assets
 * @param {number} totalDuration
 * @param {function({asset: Assets.MediaAsset, padding:number[]}):string} xtlTrack
 * @returns {string}
 */
function composeAudioMixTracks(assets, totalDuration) {
  // assume we have already sorted clips here
  return assets.map(asset => {
    const padding = getPadding(asset, totalDuration)
    return XTL.audioTrack({ asset, padding })
  }).join('\n')
}

/**
 * @param {Assets.MediaAsset} media
 * @param {TRACK_TYPE} trackType
 * @returns {string}
 */
function clipFlags(media, trackType) {
  return [
    ((media.muted || !media.hasAudio)
      && (trackType === TRACK_TYPE.AUDIO
        || trackType === TRACK_TYPE.VIDEO_AND_AUDIO))
      ? CLIP_FLAGS.AUDIO_SILENCE
      : null,
  ]
    .filter(e => e !== null)
    .join(',')
}

/**
 * @param {Number} totalDuration
 * @param {Assets.MeidaAsset[]} assets
 * @param {TRACK_TYPE} trackType
 * @param {Assets.TransitionAsset[] } transitions
 * @returns {string}
 */
function composeMediaTrack(totalDuration, format, assets, trackType, transitions) {
  let effects = []
  const assetsTransitions = filterTransitions({ transitions, assets })
  if (trackType === TRACK_TYPE.VIDEO) {
    effects = assetsTransitions.map(transition => {
      if (transition.type === TRANSITIONS.DISSOLVE) {
        const assetRef = assets.find(asset => asset.id === transition.leftVideoAssetId)
        if (assetRef === undefined) {
          return '' // skip effects on dissolve when leftVideoAssetId is not video
        }
      }
      return XTL.transitionEffectOnTrack(transition, TRANSITION_TRACK_TYPE.ORIGINAL)
    })
  } else if (trackType === TRACK_TYPE.AUDIO) {
    effects = assets.map(asset => XTL.volumeEffect(asset))
  }

  const mediaClips = []
  if (!assets.length) {
    mediaClips.push(XTL.blankClip(totalDuration, trackType))
  } else {
    let lastTime = 0
    for (const asset of assets) {
      const { mediaStart, mediaEnd } = asset
      const fileId = prepareFileId(asset)
      const clip = { fileId, mediaStart, mediaEnd }
      const flags = clipFlags(asset, trackType)
      if (lastTime !== asset.startTime) {
        let blankDuration = (asset.startTime - lastTime)
        const dissolveTransition = assetsTransitions.find(
          transition => (transition.type === TRANSITIONS.DISSOLVE
            && transition.rightVideoAssetId === asset.id)
        )
        if (dissolveTransition) {
          // this corection needs because assets overlapped http://18.184.210.86/issues/1175#note-17
          blankDuration += dissolveTransition.duration
          clip.mediaStart += dissolveTransition.duration
        }
        mediaClips.push(XTL.blankClip(blankDuration, trackType))
      }
      mediaClips.push(XTL.mediaClip(clip, flags))
      lastTime = asset.endTime
    }
    if (lastTime < totalDuration) {
      mediaClips.push(XTL.blankClip((totalDuration - lastTime), trackType))
    }
  }
  return XTL.mediaTrack(format, mediaClips.join('\n'), effects.join('\n'), trackType)
}

/**
 * @param {Number} totalDuration
 * @param {Assets.MeidaAsset[]} assets
 * @param { assetId, id, videoPosition } preparedImages
 * @param {Assets.TransitionAsset[] || null} transitions
 * @returns {string}
 */
const composeVideoMixTracks = (
  totalDuration,
  assets,
  preparedImages,
  transitions,
  previewParams,
  refAsset
) => assets.map(
  asset => {
    const assetsTransitions = filterTransitions({
      transitions,
      assets: [ asset ],
    })
    const assetEffects = []
    if (asset instanceof VideoAsset && asset.settings.isChromaKeyEnabled) {
      assetEffects.push(XTL.chromaKeyEffectOnTrack(asset))
    }
    const transitionEffects = assetsTransitions.map(transition => {
      const trackType = (transition.type === TRANSITIONS.DISSOLVE
        && asset.id === transition.rightVideoAssetId)
        ? TRANSITION_TRACK_TYPE.OVERLAY : TRANSITION_TRACK_TYPE.ORIGINAL
      return XTL.transitionEffectOnTrack(transition, trackType)
    })
    const effects = assetEffects.concat(transitionEffects).join('\n')
    const padding = getPadding(asset, totalDuration)
    let track = ''
    if (!isNil(preparedImages) && !isEmpty(preparedImages)
      && ((asset instanceof TextAsset) || (asset instanceof ImageAsset))) {
      const preparedImage = preparedImages.find(({ assetId }) => assetId === asset.id)
      const { videoPosition, id } = preparedImage
      track = XTL.pipOverlayTrack({ asset, padding, videoPosition, fileId: id, effects })
    } else if (asset instanceof VideoAsset) {
      const { fileId } = asset
      const { offset, rotation } = asset.settings
      const size = getAbsoluteAssetSize(asset, refAsset)
      const { width, height } = size
      const { x, y } = getVideoPosition({ offset, size, rotation, ...previewParams })
      const videoPosition = { x, y, width, height }
      track = XTL.pipOverlayTrack({ asset, padding, videoPosition, fileId, effects })
    }
    return track
  }
).join('\n')

/**
 * @param {TimelineAssetGroups} assets
 * @returns {string}
 */
const hasOutputAudioTrack = assets => {
  const { video, audio } = assets
  const va = video.filter(media => media.hasAudio && !media.muted)
  const activeAudio = audio.filter(media => !media.muted)
  return va.length || activeAudio.length
}

/**
 * @param {TimelineAssetGroups} assets
 * @returns {string}
 */
const hasOutputVideoTrack = assets => {
  const { video, image, text } = assets
  return video.length || image.length || text.length
}
/**
 * @param {TimelineAssetGroups} assets
 * @returns {string}
 */
function composeTranscodingTask(
  assets, { width, height, id: referenceFileId, fpsNum, fpsDenum }, format
) {
  const { video, audio } = assets
  // video asset transformed to audio if it invisible see handleClipsIntersection
  const refVideo = video.concat(audio).find(x => x.fileId === referenceFileId)
  const params = []
  if (refVideo) {
    params.push(XTL.referenceFileParam(prepareFileId(refVideo)))
  }
  if (!refVideo && hasOutputVideoTrack(assets)) {
    params.push(XTL.defaultVCodecParams({ width, height, fpsNum, fpsDenum }))
  }
  if ((!refVideo || !refVideo.hasAudio) && hasOutputAudioTrack(assets)) {
    params.push(XTL.defaultACodecParams(format))
  }
  return XTL.taskTranscoding(params.join('\n'))
}

function isMixVideoAsset(videoAsset, previewParams, refAsset) {
  return videoAsset.settings.isChromaKeyEnabled
    || !isAssetFitsPreviewFrame(videoAsset, previewParams, refAsset)
}

/**
 * @param {string} outputName
 * @param {TimelineAssetGroups} assets
 * @returns {string}
 */
export default function createEditingXTL(
  outputName,
  format,
  assets,
  layersIndexes,
  preparedImages,
  previewParams,
  refAsset,
  visibleLayerIds
) {
  const { video, image, text, transition, audio } = assets

  const totalDuration = calcProjectDuration([ video, image, text, audio ].flat())
  const { ordinaryVideoClips = [], mixVideoClips = [] } = groupBy(video,
    asset => isMixVideoAsset(asset, previewParams, refAsset) ? 'mixVideoClips' : 'ordinaryVideoClips')
  const visibleVideoClips = ordinaryVideoClips
    .filter(video => visibleLayerIds.includes(video.layerId))
  const mainVideoTrack = composeMediaTrack(
    totalDuration,
    format,
    visibleVideoClips,
    TRACK_TYPE.VIDEO,
    transition
  )
  const dissolveTransitionTracks = composeDissolveRightVideoTracks(
    transition,
    totalDuration,
    ordinaryVideoClips
  )
  const mixVideoAssets = image.concat(text, mixVideoClips).sort(
    (a, b) => layersIndexes.get(b.layerId) - layersIndexes.get(a.layerId)
  )
  const videoMixTracks = composeVideoMixTracks(
    totalDuration, mixVideoAssets, preparedImages, transition, previewParams, refAsset
  )
  let mainAudioTrack = ''
  let audioMixTracks = ''
  if (hasOutputAudioTrack(assets)) {
    mainAudioTrack = composeMediaTrack(totalDuration,
      format,
      ordinaryVideoClips,
      TRACK_TYPE.AUDIO,
      transition) // TODO: pass transitions when transition type for audio will appear
    const audioMixAssets = audio.concat(mixVideoClips.filter(media => media.hasAudio))
    const activeAudio = audioMixAssets.filter(media => !media.muted)
    audioMixTracks = composeAudioMixTracks(activeAudio, totalDuration)
  }
  const tracks = `
          ${mainVideoTrack}
          ${dissolveTransitionTracks}
          ${videoMixTracks}
          ${mainAudioTrack}
          ${audioMixTracks}
          `
  const transcodingTask = composeTranscodingTask(assets, refAsset, format)
  return XTL.timelines({
    outputName,
    format,
    transcodingTask,
    tracks,
  })
}
