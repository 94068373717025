import { getRelativeSize } from 'helpers/getRelativeSize'
import { getLayerRippleTime } from 'lib/getLayerRippleTime'
import { isEmpty, isEqual, isNumber } from 'lodash'
import { PlaceholderAsset, TextAsset, TransitionAsset, VideoAsset } from '~/models/Asset'
import * as Selectors from '~/selectors'
import * as ActionTypes from './ActionTypes'
import { cancelAssetSettingsForm, openAssetSettingForm } from './mainView'
import { getActionByAssetType } from '~/helpers/actions/getActionByAssetType'
import { getRelatedAssetsParams } from '~/helpers/assets/getRelatedAssets'
import { checkIsNewLayer } from '~/helpers/layers/checkIsNewLayer'
import { selectorPreviewTimelineIOPoints } from '~/selectors/preview'
import { ASSET_COPY_TYPE } from '~/config/constants/asset'
import { getCopiedAssetsTransitions } from './assetsPasteHelpers/getCopiedAssetsTransitions'
import { getAssetsByIOPoints } from '~/helpers/assets/getAssetsByIOPoints'

export const shiftItemsByAsset = (
  originalAsset, originalDelta, { direction } = { direction: 1 }
) => (dispatch, getState) => {
  const rightSideItems = Selectors.getRightSideAssetsExceptFadeoutTransitions(
    getState().layer,
    originalAsset
  )

  const asset = Selectors.selectAssetById(getState(), originalAsset.id)

  if (rightSideItems.length === 0
    || rightSideItems[0].startTime > (direction === 1 ? asset.endTime : originalAsset.endTime)) {
    return
  }

  let delta = originalDelta || rightSideItems[0].startTime - asset.endTime

  if (!originalDelta) {
    if (delta * direction > 0) {
      return
    }
    delta = Math.abs(delta)
  }

  dispatch({ type: ActionTypes.SHIFT_ITEMS_BY_ASSET, payload: { asset, delta: delta * direction } })
}

export const shiftItemsByTime = ({ layerId, time, delta, ignoredAssetsId }) => dispatch => {
  dispatch({ type: ActionTypes.SHIFT_ITEMS_BY_TIME,
    payload: {
      ignoredAssetsId,
      layerId,
      time,
      delta,
    } })
}

export const shiftItemsByAssetOnDrop = (
  { id, layerId, time, delta, ignoredAssetsIds }
) => (dispatch, getState) => {
  let rightSideItems = Selectors.getRightSideAssetsByTimeAndLayer(getState().layer, layerId, time)
  rightSideItems = rightSideItems.filter(item => item.id !== id)
  const transitions = Selectors.getTransitionAssets(getState())
  const assetLeftTransition = transitions.find(tr => tr.isAttachedTo(id, 'right'))
  const assetRightTransition = transitions.find(tr => tr.isAttachedTo(id, 'left'))

  if (rightSideItems.length === 0) {
    return
  }

  dispatch({ type: ActionTypes.SHIFT_ITEMS_BY_TIME,
    payload: {
      ignoredAssetsId: [ id, assetLeftTransition?.id,
        assetRightTransition?.id, ...(ignoredAssetsIds ?? []) ],
      layerId,
      time,
      delta,
    } })
}

/**
 * @param {string} id
 * @param {{ shiftKey: boolean, ctrlKey: boolean }} [modifiers]
 */
export const selectAsset = (id, modifiers = {}) => dispatch => {
  dispatch({
    type: ActionTypes.ASSET_SELECTED,
    payload: { id, modifiers },
  })
}

export const selectClipsAtCurrentPosition = () => (dispatch, getState) => {
  const state = getState()
  const sliderPosition = state.timeline.sliderTime
  return dispatch({
    type: ActionTypes.SELECT_CLIPS_AT_CURRENT_POSITION,
    payload: { sliderPosition },
  })
}

export const toggleAssetsSelected = assetsIds => dispatch => (dispatch({
  type: ActionTypes.TOGGLE_SELECTED_ASSETS,
  payload: { assetsIds },
}))

export const addAsset = (
  asset,
  ignoreSliderTime = false,
  isTransit = false,
  deltaOnDrop,
  selected = true
) => (dispatch, getState) => {
  const state = getState()
  if (asset instanceof VideoAsset && !Selectors.canAddVideoAssets(state)) {
    return
  }

  const hoveringInsertedLayerIds = Selectors.selectHoveringInsertedLayerIds(getState())
  const layers = Selectors.getLayers(state)
  const layer = Selectors.getLayerById(state, asset.layerId)
  const layerIndex = layers.findIndex(l => l.id === layer?.id)
  const layerAssets = Selectors.getLayerAssets(state, layer?.id)
  const assets = Selectors.getAssets(state)
  const isNewLayer = checkIsNewLayer({
    hoveringInsertedLayerIds,
    asset,
    layerAssets,
    layerIndex,
    layers,
  })

  dispatch({
    type: getActionByAssetType(asset),
    payload: {
      asset,
      savedParams: getRelatedAssetsParams(asset, assets),
      ignoreSliderTime,
      isNewLayer,
      newLayer: isNewLayer ? layer : undefined,
      newLayerIndex: isNewLayer ? layerIndex : undefined,
      isTransit,
      deltaOnDrop,
    },
  })

  if (!(asset instanceof PlaceholderAsset) && selected && !asset.selected) {
    dispatch(selectAsset(asset.id))
  }

  if (asset instanceof TextAsset) {
    dispatch(openAssetSettingForm(asset.id))
  }

  // dispatch(shiftItemsByAsset(asset))
}

export const assetsCopy = () => (dispatch, getState) => {
  const state = getState()
  const selectedAsset = Selectors.getSelectedAssets(state)
  const transitions = getCopiedAssetsTransitions(selectedAsset, state.layer.assets)
  dispatch({
    type: ActionTypes.ASSETS_COPY_TYPE,
    payload: { type: ASSET_COPY_TYPE.DEFAULT_SELECT },
  })
  return dispatch({
    type: ActionTypes.ASSETS_COPY,
    payload: { assets: [ ...selectedAsset, ...transitions ] },
  })
}

export const copyTimelineFragment = () => (dispatch, getState) => {
  const state = getState()
  const { markIn, markOut } = selectorPreviewTimelineIOPoints(state)
  const refVideo = Selectors.getReferenceVideoAsset(state)
  const { assets } = state.layer
  const oneOfIOPointExists = isNumber(markIn) || isNumber(markOut)

  const resultAssets = getAssetsByIOPoints(assets, refVideo, {
    generateNewId: false, markIn, markOut, skipEmptySpace: false,
  })

  if (oneOfIOPointExists) {
    dispatch({
      type: ActionTypes.ASSETS_COPY_TYPE,
      payload: { type: ASSET_COPY_TYPE.IO_POINTS_FRAGMENT },
    })
    const copyAssets = resultAssets.filter(a => !(a instanceof TransitionAsset))
    const transitions = getCopiedAssetsTransitions(copyAssets, state.layer.assets)
    dispatch({
      type: ActionTypes.ASSETS_COPY,
      payload: { assets: [ ...copyAssets, ...transitions ] },
    })
  }
}

export const clearAssetsSelection = (options = {}) => dispatch => {
  const { closeSettings = true } = options

  dispatch({
    type: ActionTypes.CLEAR_ASSETS_SELECTION,
    payload: { options },
  })

  if (closeSettings) {
    dispatch(cancelAssetSettingsForm())
  }
}

export const selectEmptyArea = ({ time, layerId }) => ({
  type: ActionTypes.EMPTY_LAYER_AREA_SELECTED,
  payload: { time, layerId },
})

// eslint-disable-next-line consistent-return
export const rippleTrim = ({ ripple, time, pregeneratedIds }) => (dispatch, getState) => {
  const state = getState()
  const isSliderTimeIntoTransition = Selectors.selectIsSliderTimeIntoTransition(state)
  const sliderTime = time || state.timeline.sliderTime
  const layers = Selectors.getLayers(state)
  const assets = Selectors.getAssets(state).filter(a => !(a instanceof PlaceholderAsset))

  if (assets.filter(a => a.startTime <= sliderTime && sliderTime <= a.endTime).length
    && (!isSliderTimeIntoTransition || time >= 0)) {
    const rippleTime = layers
      .reduce((rippleTime, l) => {
        const layerRippleTime = getLayerRippleTime(l.id, assets, sliderTime, ripple)
        const equalSliderTime = Math.floor(rippleTime / 10000) === Math.floor(sliderTime / 10000)

        if (layerRippleTime !== sliderTime && (equalSliderTime
          || (ripple === 'next' && layerRippleTime < rippleTime)
          || (ripple === 'prev' && rippleTime < layerRippleTime))
        ) {
          return layerRippleTime
        }

        return rippleTime
      }, sliderTime)

    const startTrim = ripple === 'next' ? sliderTime : rippleTime
    const endTrim = ripple === 'next' ? rippleTime : sliderTime
    const trimmedAssets = assets.filter(a => a.startTime <= startTrim && endTrim <= a.endTime
      && !(a instanceof TransitionAsset))
    const transitions = TransitionAsset.filter(assets)

    if (trimmedAssets.every(a => a.canBeTrimmed(
      startTrim,
      endTrim,
      transitions.some(t => t.isAttachedTo(a.id))
    )) && rippleTime !== sliderTime) {
      dispatch(clearAssetsSelection({
        all: false,
        placeholders: true,
      }))
      dispatch(cancelAssetSettingsForm())

      if (ripple === 'prev') {
        dispatch({
          type: ActionTypes.TIMELINE_REWINDED,
          payload: { time: rippleTime },
        })
      }

      return dispatch({
        type: ActionTypes.RIPPLE_TRIM,
        payload: {
          sliderTime,
          rippleTime,
          ripple,
          layers,
          assets,
          pregeneratedIds,
        },
      })
    }
  }
}

export const undoRippleTrim = (patchs, deleteAssetIds) => dispatch => {
  dispatch({
    type: ActionTypes.UNDO_RIPPLE_TRIM,
    payload: { patchs, deleteAssetIds },
  })
}

export const split = assetsForSplitted => (dispatch, getState) => {
  const state = getState()
  const { sliderTime } = state.timeline
  const assets = assetsForSplitted ?? Selectors.selectCurrentSplitAssets(state)
  if (isEmpty(assets)) return null
  const isSliderTimeIntoTransition = Selectors.selectIsSliderTimeIntoTransition(state)
  if (isSliderTimeIntoTransition) return null

  if (assets.length === 1) {
    return dispatch({
      type: ActionTypes.SPLIT,
      payload: { asset: assets[0], sliderTime },
    })
  }

  const assetIDsBeforeSplitting = Selectors.getAssets(state)?.map(asset => asset.id)
  const transitionsBeforeSplitting = TransitionAsset
    .filter(state.layer.assets).reduce((result, transition) => {
      // eslint-disable-next-line no-param-reassign
      result[transition.id] = {
        leftVideoAssetId: transition.leftVideoAssetId,
        rightVideoAssetId: transition.rightVideoAssetId,
      }
      return result
    }, {})

  return dispatch({
    type: ActionTypes.MULTIPLE_SPLIT,
    payload: { assets, assetIDsBeforeSplitting, transitionsBeforeSplitting, sliderTime },
  })
}

export const patchAsset = (
  asset, patch, options = { historyAction: true }
) => (dispatch, getState) => {
  if (!isEmpty(patch.settings?.size)) {
    const refAsset = Selectors.getReferenceVideoAsset(getState())

    // eslint-disable-next-line no-param-reassign
    patch.settings.relativeSize = getRelativeSize(refAsset, patch.settings.size)
  }

  dispatch({
    type: ActionTypes.ASSET_SETTINGS_FORM_UPDATED,
    payload: { id: asset.id, asset, patch, options },
  })
}

export const updateEditingAsset = (filesType, data) => (dispatch, getState) => {
  const state = getState()
  const backup = Selectors.getSourceFilesGroup(state, filesType).editingAssetBackup
  if (backup === null) {
    return
  }

  dispatch(patchAsset(Selectors.selectAssetById(getState(), backup.id), data))
}

export const updateAssetInPreview = (
  id, patch, options = { historyAction: true }
) => (dispatch, getState) => {
  const asset = Selectors.selectAssetById(getState(), id)
  if (!asset) return null

  const cancelUpdate = Object.keys(patch).every(key => isEqual(patch[key], asset[key]))

  if (cancelUpdate) return null

  if (!isEmpty(patch.settings?.size)) {
    const refAsset = Selectors.getReferenceVideoAsset(getState())

    // eslint-disable-next-line no-param-reassign
    patch.settings.relativeSize = getRelativeSize(refAsset, patch.settings.size)
  }

  return dispatch({
    type: ActionTypes.ASSET_PREVIEW_UPDATED,
    payload: { id, asset, patch, options },
  })
}

export const stopAssetPreviewUpdating = ({ asset, initProps }) => ({
  type: ActionTypes.STOP_ASSET_PREVIEW_UPDATING,
  payload: { asset, initProps },
})

export const correctAssetDuration = (asset, duration) => async dispatch => {
  await dispatch(updateAssetInPreview(asset.id, { duration }))
  await dispatch(shiftItemsByAsset(asset, null, { direction: -1 }))
}

export const addTempAssetToTimeline = asset => ({
  type: ActionTypes.TEMP_ASSET_ADDED_TO_TIMELINE,
  payload: asset,
})
