/* eslint-disable camelcase */
import cx from 'classnames'
import { debounce, noop } from 'lodash'
import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'

import SearchMenu from 'components/AssetPanels/Audio/SearchMenu'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import { SOURCE_FILE_TYPES } from 'enums'
import { useAction } from 'hooks'
import { getIsSourceFilesLoading, selectSourceFilesParams, selectTotalCount } from 'selectors'
import { selectElementDisplayType } from 'selectors/useInterface'
import { TranslationContext } from 'contexts/TranslationContext'
import * as Actions from '~/actions'
import HeadControlPanel from '~/components/HeadControlPanel'
import { DISPLAY_TYPE } from '~/config/constants/ui'


import Navbar from './Navbar'
import { useCategoryFetch } from './hooks/useCategoryFetch'

import styles from './list.module.scss'

export const HeaderContainer = ({ type, handleSearchMenuSelect = noop }) => {
  const { t } = useContext(TranslationContext)
  const { currentAudioType = 'music',
    audioFilter = {},
    search = '' } = useSelector(state => selectSourceFilesParams(state,
    SOURCE_FILE_TYPES.AUDIO))
  const audioCategories = [{ id: 'music', name: t('AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE') },
    { id: 'sound', name: t('AUDIO_LIST_CATEGORIES_SOUND_EFFECTS') },
    { id: 'mood', name: t('AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD') }]
    .filter(item => __CFG__.AUDIO_CATEGORIES.includes(item.id))

  const itemsLoading = useSelector(state => getIsSourceFilesLoading(state, type))
  const totalCount = useSelector(state => selectTotalCount(state, type))
  const { sourceList: sourceListDisplayType } = useSelector(selectElementDisplayType)

  const setSourceFilesParams = useAction(Actions.sourceFiles.setSourceFilesParams,
    SOURCE_FILE_TYPES.AUDIO)
  const categories = useCategoryFetch({ type: currentAudioType })

  const currentAudioFilter = audioFilter[currentAudioType] || {}
  function handleNavbarSelect(item) {
    setSourceFilesParams({ currentAudioType: item })
  }

  const handleSearchByTrack = value => {
    setSourceFilesParams({ search: value })
  }
  const handleSearchByTrackDebounced = useCallback(debounce(handleSearchByTrack, 300), [])

  // In terms of business logic mood = category
  const category = currentAudioType === 'mood'
    ? currentAudioFilter.mood_id || 'all'
    : currentAudioFilter.category_id || 'all'
  return (
    <>
      <HeadControlPanel
        type={SOURCE_FILE_TYPES.AUDIO}
        caption={t('AUDIO_CAPTION_AUDIO')}
      />
      <Navbar
        items={audioCategories}
        selected={currentAudioType}
        onSelect={handleNavbarSelect}
      />
      <div
        className={`${styles.container} ${sourceListDisplayType === DISPLAY_TYPE.MIN_WIDTH
          ? ''
          : styles.flexZero}`}
      >
        <SearchInput
          value={search}
          onChange={handleSearchByTrackDebounced}
          onSearch={handleSearchByTrack}
          className={cx('search_input', styles.musicSearch)}
          placeholder={currentAudioFilter.name ? `${t('AUDIO_INPUT_PLACEHOLDER_SEARCH_IN')} ${currentAudioFilter.name}`
            : t(currentAudioType === 'sound' ? 'AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS' : 'AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC')}
        />
        <SearchMenu
          items={categories}
          onSelect={handleSearchMenuSelect}
          category={category}
          search=""
          placeholder={t('AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE')}
        />
      </div>
      {search !== '' && !itemsLoading && !!totalCount && (
      <div className={styles.counter}>
        {t('AUDIO_TEXT_FOUND_ITEMS')}
        :
        {' '}
        {totalCount}
      </div>
      )}
    </>
  )
}
