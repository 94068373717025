import { createSelector } from 'reselect'

export const selectSplitSize = createSelector([
  state => state.userInterface.splitSize,
], splitSize => splitSize as { media: number })

export const selectIsResizingEditor = createSelector([
  state => state.userInterface.resizing,
], resizing => resizing as boolean)

export const selectElementDisplayType = createSelector([
  state => state.userInterface.elementDisplayType,
], elementDisplayType => elementDisplayType)
