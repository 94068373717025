import Timecode from 'smpte-timecode'
import { getFPSByRefVideo, secondsToTimelineTime } from '~/Util'
import * as Selectors from '~/selectors'
import { addSourceFileToTimePosition } from './addSourceFileToTimePosition'
import { setSliderTimeCenter } from './timeline'
import { setSourceFileTimeCodeFormat } from './setSourceFileTimeCodeFormat'


export const addSourceFileByFrameTimeCode = id => async (dispatch, getState) => {
  const state = getState()
  const file = Selectors.getSourceFileById(state, id)
  const fps = getFPSByRefVideo(file)
  const { frameCount } = new Timecode(file.startTimeCodeFrames || 0, fps, Boolean(file.dfFlag))
  const sec = frameCount / fps
  const timePosition = secondsToTimelineTime(sec, false)

  addSourceFileToTimePosition({ file, timePosition, dispatch, getState })
  dispatch(setSliderTimeCenter(secondsToTimelineTime(sec)))
  setSourceFileTimeCodeFormat(file, state, dispatch)
}
