export const SETTINGS_VIEW_MODE = {
  DEFAULT: 'default',
  NEW_PUBLISH: 'new_publish',
}

export const EDITOR_MAIN_SETTINGS_MENU_ITEMS = {
  RENDER_SETTINGS: 'render_settings',
  BEHAVIOUR_SETTINGS: 'behaviour_settings',
} as const

export const EDITOR_MAIN_SETTINGS_MENU = [
  {
    name: 'EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS',
    key: EDITOR_MAIN_SETTINGS_MENU_ITEMS.RENDER_SETTINGS,
    visible: [], // for profiles, [] - empty array for all profiles
  },
  {
    name: 'EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS',
    key: EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS,
    visible: [], // for profiles, [] - empty array for all profiles
  },
]
