import Timeline from 'components/Timeline/Timeline'
import { DISPLAY_TYPE } from 'config/constants/ui'
import { TIMELINE_LAYERS_DEFAULT_HEIGHT } from 'constant'
import React, { FC } from 'react'
import SplitPane from 'react-split-pane'
import { getWindowHeight } from 'Util'

interface IProps {
  timelineDisplayType: typeof DISPLAY_TYPE[keyof typeof DISPLAY_TYPE]
}

export const MainWrapper:FC<IProps> = ({ children, timelineDisplayType }) => {
  const windowHeight = getWindowHeight()
  switch (timelineDisplayType) {
    case DISPLAY_TYPE.FULL_WIDTH: {
      return (
        <SplitPane
          split="horizontal"
          size="50%"
          defaultSize="50%"
          maxSize={windowHeight - TIMELINE_LAYERS_DEFAULT_HEIGHT}
        >
          {children}
          <Timeline />
        </SplitPane>
      )
    }
    case DISPLAY_TYPE.HALF_WIDTH: {
      return <>{children}</>
    }
    default:
      return null
  }
}
