import Menu from '@material-ui/core/Menu'
import cx from 'classnames'
import MenuItem from 'components/base/MenuItem'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext, useState } from 'react'
import { ReactComponent as ArrowIcon } from '~/assets/settings/expand_more.svg'
import Divider from '~/components/base/Divider/Divider'
import { useLocalFileImport } from '~/hooks/useLocalFileImport'
import AmazonImport from './AmazonImport'
import DropboxImport from './DropboxImport'
import GoogleDriveImport from './GoogleDriveImport'
import UrlImport from './UrlImport'

export default function ImportTool() {
  const [ anchorEl, setAnchorEl ] = useState(null)
  const onOpen = React.useCallback(e => setAnchorEl(e.currentTarget), [])
  const onClose = React.useCallback(() => setAnchorEl(null), [])
  const { t } = useContext(TranslationContext)

  const {
    getRootProps,
    getInputProps,
  } = useLocalFileImport()

  return (
    <div
      className="head-control__import"
      draggable
      onDragStart={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.IMPORT_DROPDOWN}>
        <div
          className={cx('head-control__btn-menu', { 'head-control__btn-menu--active': !!anchorEl })}
          onClick={onOpen}
        >
          {t('HEAD_CONTROL_IMPORT_TEXT')}
          <ArrowIcon />
        </div>
        <Menu
          keepMounted
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{
            paper: 'head-control__dropdown-menu',
          }}
          open={!!anchorEl}
          onClose={onClose}
        >
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getRootProps()}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input {...getInputProps()} />
            <MenuItem onClick={onClose}>{t('HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA')}</MenuItem>
          </div>
          <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.IMPORT_FOLDER}>
            <MenuItem onClick={onClose}>{t('HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA')}</MenuItem>
          </If>
          <Divider />
          <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.IMPORT_AMAZON}>
            <AmazonImport>
              <MenuItem onClick={onClose}>{t('HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET')}</MenuItem>
            </AmazonImport>
          </If>
          <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.IMPORT_DROPBOX}>
            <DropboxImport>
              <MenuItem onClick={onClose}>{t('HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX')}</MenuItem>
            </DropboxImport>
          </If>
          <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.IMPORT_GDRIVE}>
            <GoogleDriveImport>
              <MenuItem onClick={onClose}>{t('HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE')}</MenuItem>
            </GoogleDriveImport>
          </If>
          <Divider />
          <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.IMPORT_URL}>
            <UrlImport>
              <MenuItem onClick={onClose}>{t('HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT')}</MenuItem>
            </UrlImport>
          </If>
        </Menu>
      </If>
    </div>
  )
}
