import React, { useContext } from 'react'
import { TranslationContext } from 'contexts/TranslationContext'
import { DummyHeader } from '../components/DummyHeader'

export const HeaderContainer = () => {
  const { t } = useContext(TranslationContext)
  return (
    <DummyHeader caption={t('TRANSITION_LIST_CAPTION_TRANSITION')} />
  )
}
