/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { SOURCE_FILE_TYPES } from 'enums'
import { useAction } from 'hooks'
import { selectSourceFilesParams } from 'selectors'
import { TranslationContext } from 'contexts/TranslationContext'
import * as Actions from '~/actions'
import LoadButton from '~/components/AssetPanels/Media/LoadButton'
import Scrollbars from '~/components/base/Scrollbars'
import List from '../BaseAssetsList'
import Item from './ListItem'
import { useCategoryFetch } from './hooks/useCategoryFetch'

import styles from './list.module.scss'
import { HeaderContainer } from './HeaderContainer'

export default function AudioAssetsList(props) {
  const { t } = useContext(TranslationContext)
  const { onScroll, type } = props
  const { currentAudioType = 'music',
    audioFilter = {} } = useSelector(state => selectSourceFilesParams(state,
    SOURCE_FILE_TYPES.AUDIO))

  const categories = useCategoryFetch({ type: currentAudioType })
  const setSourceFilesParams = useAction(Actions.sourceFiles.setSourceFilesParams,
    SOURCE_FILE_TYPES.AUDIO)

  function handleSearchMenuSelect(item) {
    const name = categories.find(category => category.id === item)?.name
    if (currentAudioType === 'mood') {
      setSourceFilesParams({ audioFilter: { ...audioFilter,
        [currentAudioType]: { mood_id: item === 'all' ? undefined : item,
          name } } })
    } else {
      setSourceFilesParams({ audioFilter: { ...audioFilter,
        [currentAudioType]: { category_id: item === 'all' ? undefined : item,
          name } } })
    }
  }

  function getCategory(asset) {
    const category = currentAudioType === 'mood' ? categories.find(category => category.id === asset.moodId)
      : categories.find(category => category.id === asset.categoryId)
    return category
  }

  return (
    <Scrollbars
      renderViewClassName="media-scrollbar"
      // eslint-disable-next-line react/destructuring-assignment
      onScrollFrame={__CFG__.SOURCE_FILES_MANAGEMENT.LOAD_MEDIA_ONSCROLL
        ? onScroll
        : () => {}}
    >
      <List
        {...props}
        fullHeightScrollbar
        className={styles.root}
        classes={{ list: styles.list }}
        header={<HeaderContainer type={type} handleSearchMenuSelect={handleSearchMenuSelect} />}
        ItemComponent={
          props => (
            <Item
              getCategory={getCategory}
              onGenreClick={handleSearchMenuSelect}
              {...props}
            />
          )
        }
        placeholder={(
          <div className={styles.placeholder}>
            <div className={styles.header}>{t('AUDIO_TEXT_NO_RESULTS_FOUND')}</div>
            <div className={styles.desc}>
              {t('AUDIO_TEXT_CANNOT_FIND_RESULTS')}
            </div>
          </div>
        )}
        after={__CFG__.SOURCE_FILES_MANAGEMENT.LOAD_MEDIA_ONSCROLL
          ? null
          : <LoadButton type={type} />}
      />
    </Scrollbars>
  )
}
