import { TIME_CODE_MODE, UNIT_MEASUREMENT } from 'config/constants/timecode'
import { SEPARATOR } from 'enums'
import { ActiveTimeUnits, TimeCodeModeType } from 'types/timecode'

export const timeUnitsToStringValueTime = (timeUnits: ActiveTimeUnits, timeMode: TimeCodeModeType) => (
  timeUnits.reduce((prevString, unit, index) => {
    let resultString = prevString
    if (index) {
      switch (unit.id) {
        case UNIT_MEASUREMENT.MILLISECOND:
          resultString += SEPARATOR.DOT
          break
        case UNIT_MEASUREMENT.FRAME:
          if (timeMode === TIME_CODE_MODE.FRAME_DF) {
            resultString += SEPARATOR.SEMICOLON
          } else {
            resultString += SEPARATOR.COLON
          }
          break
        default:
          resultString += SEPARATOR.COLON
          break
      }
    }
    resultString += unit.value
    return resultString
  }, '')
)
