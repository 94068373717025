import React, { FC } from 'react'
import { MenuItemType } from 'types/editorSettings'
import { EDITOR_MAIN_SETTINGS_MENU_ITEMS } from 'config/constants/mainView'
import { RenderSettings } from './settings/RenderSettings'
import { BehaviourSettings } from './settings/BehaviourSettings'

interface IProps {
  activeMenuItem: MenuItemType
}

export const EditorSettingsParams:FC<IProps> = ({ activeMenuItem }) => {
  switch (activeMenuItem) {
    case EDITOR_MAIN_SETTINGS_MENU_ITEMS.RENDER_SETTINGS:
      return <RenderSettings />
    case EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS:
      return <BehaviourSettings />
    default:
      return <div />
  }
}
