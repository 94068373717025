import produce from 'immer'
import { arrayMoveImmutable } from 'array-move'
import { isArray } from 'lodash'
import * as ActionTypes from '~/actions/ActionTypes'
import Layer from '~/models/Layer'
import { TransitionAsset } from '~/models/Asset'


/**
 * @typedef {object} TimelineState
 * @property {number} sliderTime
 * @property {InstanceType<typeof Layer>[]} layers
 * @property {number} scale
 * @property {number} duration
 * @property {number} rullerOffset
 * @property {boolean} latestTimelineRewindTime
 * @property {number} isTimelineRewinded
 * @property {boolean} isSliderOnBufferingPos
 * @property {number} scrollLeft
 * @property {string} draggingAssetId
 * @property {string[]} hoveringInsertedLayerIds
 * @property {string[]} playingAssetIds
 * @property {string[]} transitionAssetIds
 * @property {string[]} composedAssetIds
 *
 */

/**
 * @type {TimelineState}
 */
const initialState = {
  sliderTime: 0,
  layers: [],
  prevScale: 55,
  scale: 55,
  duration: 0,
  rullerOffset: 0,
  latestTimelineRewindTime: null,
  isTimelineRewinded: 0,
  isSliderOnBufferingPos: false,
  isMovingSlider: false,
  scrollLeft: 0,
  draggingAssetId: undefined,
  hoveringInsertedLayerIds: [],
  playingAssetIds: [],
  backgroundAssetIds: [],
  transitionAssetIds: [],
  composedAssetIds: [],
}

const timeline = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.LOAD_PROJECT: {
      const { layers, scale, scrollLeft, sliderTime } = action.payload
      state.layers = layers.map(l => new Layer(l))
      state.sliderTime = sliderTime
      state.scrollLeft = scrollLeft
      state.scale = scale
      break
    }

    case ActionTypes.SET_IS_SLIDER_ON_BUFFERING_POS: {
      state.isSliderOnBufferingPos = action.payload
      break
    }
    case ActionTypes.SET_IS_MOVING_SLIDER: {
      const { isMovingSlider } = action.payload
      state.isMovingSlider = isMovingSlider
      break
    }
    case ActionTypes.SET_TIMELINE_ASSET_IDS: {
      const {
        playingAssetIds,
        backgroundAssetIds,
        composedAssetIds,
        transitionAssetIds,
      } = action.payload
      state.playingAssetIds = playingAssetIds
      state.backgroundAssetIds = backgroundAssetIds
      state.transitionAssetIds = transitionAssetIds
      state.composedAssetIds = composedAssetIds
      break
    }
    case ActionTypes.UPDATE_SLIDER: {
      const { time } = action.payload
      // react-player emits time with microseconds precision (1e6).
      // In project time is 100-nanoseconds precision (1e7).
      // So that, there is some inevitable error between react-player time and timeline time.
      // Let's set acceptable error to 1mks (10x100ns=1mks), accordingly to react-player precision.
      if (Math.abs(time - state.sliderTime) >= 10) {
        state.isTimelineRewinded = 0
        setSliderTime(state, time)
      }
      break
    }
    case ActionTypes.TIMELINE_REWINDED: {
      const { time } = action.payload
      setSliderTime(state, time)
      state.latestTimelineRewindTime = time
      state.isSliderOnBufferingPos = true
      // NOTE: use increment for useDiff() - fix multiple rewinds before playback
      state.isTimelineRewinded += 1
      break
    }

    case ActionTypes.TIMELINE_CLEARED:
      state.layers = [ new Layer() ]
      break

    case ActionTypes.TIMELINE_DRAG_LAYER:
      state.draggingLayerId = action.payload
      break

    case ActionTypes.TIMELINE_DRAG_END_LAYER:
      state.draggingLayerId = undefined
      break

    case ActionTypes.TIMELINE_DRAG_ASSET:
      state.draggingAssetId = action.payload
      break

    case ActionTypes.TIMELINE_DRAG_END_ASSET:
      state.draggingAssetId = undefined
      break
    case ActionTypes.TIMELINE_SET_SLIDER_TIME: {
      const { time } = action.payload
      setSliderTime(state, time)
      setLatestTimelineRewindTime(state, time)
    }
      break
    case ActionTypes.ASSET_ADDED_TO_TIMELINE: {
      const { asset, ignoreSliderTime } = action.payload
      if (asset instanceof TransitionAsset) {
        break
      }
      if (!ignoreSliderTime) {
        setSliderTime(state, asset.startTime)
        setLatestTimelineRewindTime(state, asset.startTime)
      }

      deleteLayer(state, { ids: state.hoveringInsertedLayerIds
        .filter(id => id !== asset.layerId) })
      clearHoveringLayerId(state, asset.layerId)
      break
    }
    case ActionTypes.ASSET_MOVED_ON_TIMELINE: {
      const { layerId } = action.payload
      deleteLayer(state, { ids: state.hoveringInsertedLayerIds.filter(id => id !== layerId) })
      clearHoveringLayerId(state, layerId)
      break
    }

    case ActionTypes.MULTIPLE_ASSETS_MOVED_ON_TIMELINE: {
      const { layerId } = action.payload
      deleteLayer(state, { ids: state.hoveringInsertedLayerIds.filter(id => id !== layerId) })
      clearHoveringLayerId(state, layerId)
      break
    }

    case ActionTypes.UNDO_MULTIPLE_MOVE_ASSETS: {
      const { serviceData } = action.payload
      rebuildLayers(state, serviceData)
      break
    }

    case ActionTypes.DELETE_ASSETS: {
      const assets = action.payload
      if (assets?.length) {
        clearHoveringLayerId(state, assets.map(({ layerId }) => layerId))
      }
      break
    }
    case ActionTypes.UNDO_DELETE_IO_POINTS_TIMELINE_FRAGMENT: {
      const { deletedLayerIds, layersMap } = action.payload
      const newLayers = Array.from(new Set(deletedLayerIds)).map(layerId => {
        const newLayer = new Layer()
        newLayer.id = layerId
        return newLayer
      })
      state.layers.push(...newLayers)
      state.layers = state.layers.sort((a, b) => layersMap[a.id] - layersMap[b.id])
    } break
    case ActionTypes.INSERT_LAYER:
      insertLayer(state, action.payload)
      break
    case ActionTypes.INSERT_LAYERS:
      insertLayers(state, action.payload)
      break
    case ActionTypes.LAYER_REMOVED:
      deleteLayer(state, action.payload)
      break
    case ActionTypes.FORCE_DELETE_LAYER:
      deleteLayer(state, action.payload)
      break
    case ActionTypes.SET_TIMELINE_SCALE:
      state.prevScale = state.scale
      state.scale = action.payload.scale
      break
    case ActionTypes.SET_TIMELINE_DURATION:
      state.duration = action.payload.duration
      break
    case ActionTypes.SET_TIMELINE_RULLER_OFFSET:
      state.rullerOffset = action.payload.offset
      break
    case ActionTypes.LAYER_MUTED:
      muteLayer(state, action.payload)
      break
    case ActionTypes.LAYER_TOGGLE_VISIBLE:
      toggleVisibleLayer(state, action.payload)
      state.hasChanges = true
      break
    case ActionTypes.LAYER_REMOVE_ALL:
      state.layers = []
      break
    case ActionTypes.TIMELINE_SCROLL_LEFT:
      state.scrollLeft = action.payload.scrollLeft
      break
    case ActionTypes.LAYER_CHANGE_POSITION: {
      const { id, index } = action.payload
      const currentIndex = state.layers.findIndex(layer => layer.id === id)
      state.layers = arrayMoveImmutable(
        state.layers,
        currentIndex,
        Math.min(Math.max(index, 0), state.layers.length - 1)
      )
      break
    }
    case ActionTypes.SET_HLS_PLAYER_DATA: {
      const { src } = action.payload
      if (!state.hls) state.hls = { src: null }
      state.hls.src = src
      break
    }
  }
}

export default produce(timeline, initialState)

// ---

function setSliderTime(state, time) {
  state.sliderTime = Math.max(0, time)
}

function setLatestTimelineRewindTime(state, time) {
  state.latestTimelineRewindTime = Math.max(0, time)
}

function insertLayer(state, { index, layer = new Layer(), isHovering = false }) {
  // workaround for inconsistency between projectData and AssetCreationAction
  if (state.layers.find(item => item.id === layer.id)) {
    return
  }

  if (isHovering) {
    if (state.draggingAssetId) {
      state.layers.splice(index, 0, layer)
      state.hoveringInsertedLayerIds = [ ...state.hoveringInsertedLayerIds, layer.id ]
    }
  } else {
    state.layers.splice(index, 0, layer)
  }
}

function insertLayers(state, { index, layers, insertBefore }) {
  if (isArray(layers)) {
    if (insertBefore) {
      layers.forEach((layer, ind) => state.layers.splice(index + ind, 0, layer))
    } else {
      layers.forEach((layer, ind) => state.layers.splice(index + ind + 1, 0, layer))
    }
  }
}

function deleteLayer(state, { ids }) {
  if (state.layers.length === 1) {
    return
  }
  state.layers = state.layers.filter(layer => !ids.includes(layer.id))
  clearHoveringLayerId(state, ids)
}

function muteLayer(state, { id, mute }) {
  const layer = state.layers.find(el => el.id === id)
  layer.muted = mute
}

function toggleVisibleLayer(state, { layerId }) {
  const layer = state.layers.find(el => el.id === layerId)
  layer.visible = !layer.visible
}

function clearHoveringLayerId(state, ids) {
  state.hoveringInsertedLayerIds = state.hoveringInsertedLayerIds
    .filter(layerId => ![].concat(ids).includes(layerId))
}

function rebuildLayers(state, serviceData) {
  state.layers = serviceData.layerIds.map(id => {
    const layer = new Layer()
    layer.id = id
    return layer
  })
}
