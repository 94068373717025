import Timecode, { FRAMERATE } from 'smpte-timecode'

type GetTimeCode = { frameCount: number, fps: number, isDF: boolean, negative: boolean }

export const getFrameStep = ({ frameCount, fps, isDF, negative }: GetTimeCode) => {
  const timecode = new Timecode(frameCount, fps as FRAMERATE, isDF)
  if ((negative && timecode.frameCount) || !negative) {
    timecode.add(1, negative)
  }
  return timecode.frameCount / fps
}
