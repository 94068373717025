import Divider from 'components/base/Divider/Divider'
import React, { useCallback, useEffect } from 'react'
import { useModalDialog } from 'hooks'
import { ActivationBannerModal } from '../activation-banner-modal/activation-banner-modal'
import { ActivationBarComponent } from '../activation-bar-component/activation-bar-component'
import { ActivationErrorModal } from '../activation-error-modal/ActivationErrorModal'
import { ActivationVariantsComponent } from '../activation-variants-component/activation-variants-component'
import PROFILE_CONFIG from '../config'
import { useActivationSystem } from '../use-activation-system'
import { AddLicenseModal } from '../add-license-modal/AddLicenseModal'
import './styles.scss'

export const ActivationComponent = () => {
  const {
    keys,
    openRegPage,
    url,
    activateKey,
    activeKey,
    userEmail,
    error,
    skipError,
    isLoading,
  } = useActivationSystem()

  const addLicenseModal = useModalDialog()
  useEffect(() => {
    const key = localStorage.getItem(PROFILE_CONFIG.LOCAL_STORAGE_KEY_VAR)
    if (PROFILE_CONFIG.KEY_REQUIRED && (((!key || key === PROFILE_CONFIG.FREE_KEY) && !error))) {
      addLicenseModal.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ addLicenseModal, error ])


  const handleCloseActivationErrorModal = useCallback(
    () => {
      skipError()
      if (PROFILE_CONFIG.KEY_REQUIRED) {
        addLicenseModal.open()
      }
    },
    [ addLicenseModal, skipError ]
  )
  return (
    <>
      {PROFILE_CONFIG.AUTHORIZATION_REQUIRED && (!activeKey || isLoading) ? (
        <div className="activation-component">
          <ActivationBarComponent />
          <Divider className="activation-divider" orientation="vertical" />
          <ActivationVariantsComponent
            isLoading={isLoading}
            userEmail={userEmail}
            onSelectKey={activateKey}
            keys={keys}
            url={url}
            onBrowserOpen={openRegPage}
          />
        </div>
      ) : null}
      <ActivationErrorModal onClose={handleCloseActivationErrorModal} error={error} />
      {activeKey === PROFILE_CONFIG.FREE_KEY && <ActivationBannerModal />}

      {PROFILE_CONFIG.KEY_REQUIRED ? (
        <AddLicenseModal modal={addLicenseModal} blockClose />
      ) : null}
    </>
  )
}
