import React, { FC } from 'react'
import MediaSettingsContainer from 'components/AssetPanels/Media/Settings/SettingsContainer'
import { SaveSettingsContainer } from 'components/SaveSettings/SaveSettingsContainer'
import { CustomizationHotkeysContainer } from 'components/CustomizationHotkeys/CustomizationHotkeysContainer'
import { CustomizationViewContainer } from 'views/CustomizationView/CustomizationViewContainer'
import { ImportFromUrlContainer } from 'components/ImportFromUrl/ImportFromUrlContainer'
import { NabletHldContainer } from 'components/NabletHld/NabletHldContainer'
import { EditorSettingsContainer } from 'components/EditorSettings/EditorSettingsContainer'

export const ModalView: FC = () => (
  <>
    <MediaSettingsContainer />
    <SaveSettingsContainer />
    <CustomizationHotkeysContainer />
    <CustomizationViewContainer />
    <ImportFromUrlContainer />
    <NabletHldContainer />
    <EditorSettingsContainer />
  </>
)
