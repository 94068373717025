export enum RenderSettingsEnum {
  skipEmptySpace = 'skipEmptySpace',
}

export enum BehaviourSettingsEnum {
  setTimecodeFormatBasedOnMedia = 'setTimecodeFormatBasedOnMedia'
}

export const RENDER_SETTINGS_FORM_STATE = {
  skipEmptySpace: __CFG__.RENDER_SETTINGS.SKIP_EMPTY_SPACE || 0,
} as const

export const BEHAVIOUR_SETTINGS_FORM_STATE = {
  setTimecodeFormatBasedOnMedia: __CFG__.BEHAVIOUR_SETTINGS.SET_TIMECODE_FORMAT_BASED_ON_MEDIA || 0,
} as const
