import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import * as Actions from 'actions'
import { HOTKEYS } from 'enums'
import { useHotkeys } from 'react-hotkeys-hook'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { TranslationContext } from 'contexts/TranslationContext'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import { MAX_ZOOM_VALUE, MIN_ZOOM_VALUE, STEP_ZOOM_VALUE } from 'constant'
import './ZoomSlider.scss'

function ZoomSlider(props) {
  const { scale, setScale } = props
  const [ value, setValue ] = useState(Math.log(scale))
  const [ isMount, setIsMount ] = useState(false)
  const { t } = useContext(TranslationContext)
  const { getTooltip } = useHotkeyTooltip()
  const interval = useRef()
  const refSlider = useRef(null)

  useEffect(() => {
    if (isMount) {
      setScale(Math.exp(value))
    } else {
      setIsMount(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value, setScale ])

  useEffect(() => {
    setValue(Math.log(scale))
  }, [ scale, setScale ])

  const handleChange = useCallback((e, newValue) => {
    clearInterval(interval.current)
    interval.current = setTimeout(() => {
      setValue(newValue)
    })
  }, [])

  const onZoomOut = useCallback(() => {
    if (value > MIN_ZOOM_VALUE) {
      setValue(value - STEP_ZOOM_VALUE)
    }
  }, [ value ])

  const onZoomIn = useCallback(() => {
    if (value < MAX_ZOOM_VALUE) {
      setValue(value + STEP_ZOOM_VALUE)
    }
  }, [ value ])

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ZOOM_IN], onZoomIn)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ZOOM_OUT], onZoomOut)

  useEffect(() => {
    const slider = refSlider.current
    const onWheel = e => {
      e.deltaY > 0 ? onZoomIn() : onZoomOut()
    }
    slider.addEventListener('wheel', onWheel)
    return () => {
      slider.removeEventListener('wheel', onWheel)
    }
  }, [ onZoomIn, onZoomOut, value, refSlider ])

  return (
    <div className="zoom-slider">
      <div className="zoom-slider__wrap">
        <div
          className="zoom-slider__btn"
          onClick={onZoomOut}
          title={getTooltip(t('ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP'), HOTKEYS.ZOOM_OUT)}
          data-lang-id="ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP"
        >
          <RemoveIcon />
        </div>
        <div className="zoom-slider__inner" ref={refSlider}>
          <Slider
            step={STEP_ZOOM_VALUE}
            min={MIN_ZOOM_VALUE}
            max={MAX_ZOOM_VALUE}
            value={value}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
          />
        </div>
        <div
          className="zoom-slider__btn"
          onClick={onZoomIn}
          title={getTooltip(t('ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP'), HOTKEYS.ZOOM_IN)}
          data-lang-id="ZOOM_SLIDER_BTN_INCREASE_ZOOM"
        >
          <AddIcon />
        </div>
      </div>
      <If condition={__CFG__.TIMELINE.ZOOM_MENU}>
        <div className="zoom-slider__control">
          <span className="zoom-slider__dots" />
        </div>
      </If>
    </div>
  )
}

ZoomSlider.propTypes = {
  setScale: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  scale: state.timeline.scale,
})

const mapDispatchToProps = dispatch => ({
  setScale: scale => dispatch(Actions.timeline.setScale(scale)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoomSlider)
