import React, { FC, memo, useContext } from 'react'
import { Box } from '@material-ui/core'
import Button from 'components/base/Button'
import { FormContext } from 'contexts/FormContext'

interface IProps {
  t(str: string): string
  onClose():void
}

export const Footer:FC<IProps> = memo(({ t, onClose }) => {
  const { onButtonFormSubmit } = useContext(FormContext)
  return (
    <Box display="flex" justifyContent="flex-end" className="editor-settings__footer">
      <Button
        type="submit"
        className="btn--border"
        primary
        onClick={onClose}
        autoFocus
      >
        {t('EDITOR_MAIN_SETTINGS_BTN_CANCEL')}
      </Button>
      <Button
        type="submit"
        className="save-as-dialog__button"
        primary
        onClick={e => {
          onButtonFormSubmit(e)
          onClose()
        }}
        autoFocus
      >
        {t('EDITOR_MAIN_SETTINGS_BTN_SAVE')}
      </Button>
    </Box>
  )
})
