import * as Actions from 'actions'
import { throttle } from 'lodash'
import React, { memo } from 'react'
import { connect, useSelector } from 'react-redux'
import { getReferenceVideoAsset } from 'selectors'
import { SEPARATOR } from 'enums'
import { rullerHeight, timebarColor, timebarTextColor, timebarTextFont } from 'styles/variables.scss'
import { AppRoutes } from '~/AppRoutes'
import {
  getWindowWidth,
  pixel2Time,
  time2Pixel,
  TIMELINE_RANGE,
  n,
  timelineTimeToSeconds,
  secondsToTimelineTime,
  getFPSByRefVideo,
  nnn,
  DEFAULT_FPS
} from '~/Util'
import { TIME_CODE_MODE, TIME_CODE_CONTAINER_LOCATION } from '~/config/constants/timecode'
import { TimelineScrollPositionContext } from './ScrollPositionContext'
import './TimeLineRuller.scss'
import { getObjectTimeCode, isDropFrameTimeCode, frameNumberToSeconds } from '~/Util/timecode'

const defaultTicksDistance = 128
const defaultTicksRange = 15e7
class TimelineRuller extends React.Component {

  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()

    this.textWidth = 0
    this.textHeight = 0
    this.longTickHeight = 18
    this.shortTickHeight = 8
    this.initialStepTable = []

    // 5ms, 7ms, 9ms, 11ms, 500ms, 1s, 5s, 15s, 30s, 1m, 5m
    this.initialStepTable = [
      5e4, 7e4, 9e4, 11e4, 5e5, 1e6, 5e6, 7e6, 1e7, 5e7, 15e7, 30e7, 60e7, 5 * 60e7,
    ]
    // TODO: replace it with useLocation()
    if (window.location.pathname === AppRoutes.NewProject) {
      // this.initialTimelinePixWidth = 2625;
      const { setScale, setTimelineDuration } = this.props
      const sectionCount = getWindowWidth() / defaultTicksDistance
      this.initialDuration = sectionCount * defaultTicksRange
      const defaultScale = TIMELINE_RANGE / this.initialDuration
      setScale(defaultScale)
      setTimelineDuration(this.initialDuration)
    }

    // let index = 3;
    // let base = 1e4;
    // while (base < 1e5) {
    //   index += 2;
    //   this.initialStepTable.push(index * base);
    //   if (index >= 10) {
    //     base *= 10;
    //     index = 0;
    //   }
    // }

    // // adding 1s, 3s, 5s, 10s
    // this.initialStepTable = [...this.initialStepTable, 1e7, 3e7, 5e7, 10e7];
    // // adding 15s, 20s, 30s, 40s, 50s
    // this.initialStepTable = [...this.initialStepTable, 15e7, 20e7, 30e7, 40e7, 50e7];
    // // adding 1m, 3m, 5m
    // this.initialStepTable = [...this.initialStepTable, 1 * 60e7, 3 * 60e7, 5 * 60e7];

    // 10 * 60e7, // 10m
    // 15 * 60e7, // 15m
    // 20 * 60e7, // 20m
    // 30 * 60e7, // 30m
    // 40 * 60e7, // 40m
    // 60 * 60e7, // 60m
    // 2 * 60 * 60e7, // 2h
    // 3 * 60 * 60e7, // 3h
    // 4 * 60 * 60e7, // 4h
    // 5 * 60 * 60e7, // 5h
    // 10 * 60 * 60e7, // 10h

    this.updatedTimeArray = []
    this.lastZoomSliderStep = 0

    this.startTime = 0
    // const { sliderTime } = this.props;
    // this.sliderPosRatio = (timelineDuration - sliderTime) / (sliderTime - this.startTime);

    this.onWindowScroll = throttle(event => this.onScroll(event), 50)
    this.onWindowResize = throttle(event => this.onUpdateSize(event), 50)

    this.frameStep = 1
    this.titleFrameStep = 1
    this.minTickInterval = 4
  }

  render() {
    return (
      <div className="timeline-ruller">
        <canvas className="timeline-canvas" width={getWindowWidth()} height={rullerHeight} ref={this.canvasRef} />
      </div>
    )
  }

  componentDidMount() {
    const { setRullerOffset, setTimelineDuration, scale } = this.props
    const root = document.getElementById('root-app-solveig')

    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('resize', this.onRebuildRuller)
    root.addEventListener('scroll', this.onWindowScroll)
    this.initMinTextWidth()

    this.initTimeArray()
    this.initZoomSliderLastStepIndex()
    this.drawRuller()

    this.initialOffsetLeft = this.canvasRef.current.offsetLeft
    setRullerOffset(this.initialOffsetLeft)
    this.initialVisibleTimeRange = pixel2Time(
      getWindowWidth() - this.initialOffsetLeft, scale
    )
    const duration = this.startTime + this.initialVisibleTimeRange
    setTimelineDuration(duration)
  }

  componentDidUpdate(prevProps) {
    const { timelineScrollLeft, scale, setTimelineDuration, setRullerOffset } = this.props
    this.startTime = pixel2Time(timelineScrollLeft, scale)
    this.drawRuller()
    setRullerOffset(this.initialOffsetLeft)

    if (prevProps.scale !== scale || prevProps.timelineScrollLeft !== timelineScrollLeft) {
      this.initialVisibleTimeRange = pixel2Time(
        getWindowWidth() - this.initialOffsetLeft, scale
      )
      const duration = this.startTime + this.initialVisibleTimeRange
      setTimelineDuration(duration)

      // if (duration > this.initialDuration) {
      // } else {
      //   setTimelineDuration(this.initialDuration)
      // }
    }

    // const { sliderTime, scale } = this.props;
    // if (sliderTime !== prevProps.sliderTime) {
    //   const range = this.getVisibleTimelineRange();
    //   this.sliderPosRatio = (range - sliderTime) / sliderTime;
    //   const rigthRange = range - sliderTime;
    //   const leftRange = rigthRange / this.sliderPosRatio;
    //   this.startTime = range - rigthRange - leftRange;
    // }
    // if (scale !== prevProps.scale) {
    //   const range = this.getVisibleTimelineRange();
    //   const rigthRange = range - sliderTime;
    //   const leftRange = rigthRange / this.sliderPosRatio;
    //   this.startTime = range - rigthRange - leftRange;
    // }
  }

  componentWillUnmount() {
    const root = document.getElementById('root-app-solveig')
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('resize', this.onRebuildRuller)
    root.removeEventListener('scroll', this.onWindowScroll)
  }

  onRebuildRuller = () => {
    const { rebuildTimeLineRuller } = this.props
    rebuildTimeLineRuller()
  }

  onUpdateSize = () => {
    // TODO: make drawRuller as a handler if nothing else here.
    this.drawRuller()
  }

  onScroll = event => {
    const { scale } = this.props
    this.startTime = pixel2Time(event.target.scrollLeft, scale)
    this.drawRuller()
  }

  getInitialZoomState() {
    const step = this.getCurrentTimeLineStep()
    const zoomindex = this.lastZoomSliderStep - this.getStepIndex(step)
    return { min: 1, max: this.lastZoomSliderStep, value: zoomindex }
  }

  getStepByZoomIndex(zoomindex) {
    const index = this.lastZoomSliderStep - zoomindex
    return this.updatedTimeArray[index]
  }

  getStepIndex(step) {
    let index = 0
    for (let i = 0; i < this.updatedTimeArray.length; i++) {
      if (this.updatedTimeArray[i] > step) {
        index = i - 1
        break
      }
    }
    return index
  }

  getVisibleTimelineRange() {
    // (this.initialTimelinePixWidth) - TIMELINE_RANGE
    // canvas width          -   x
    const canvasWidth = this.canvasRef.current.width
    const { scale } = this.props
    return ((TIMELINE_RANGE * canvasWidth) / getWindowWidth()) / scale
  }

  getInitialTimelineStep() {
    const numOfEntries = getWindowWidth() / this.textWidth
    const aproxTimelineStep = TIMELINE_RANGE / numOfEntries
    const step = this.getTemplateTimeLineTimeStap(aproxTimelineStep)
    return step
  }

  getApproximateTimeLineStep() {
    const { scale } = this.props
    const fullW = scale * getWindowWidth()
    const numOfEntries = fullW / this.textWidth
    return TIMELINE_RANGE / numOfEntries
  }

  getTemplateTimeLineTimeStap(tStep) {
    const xs = this.updatedTimeArray
    if (xs.length === 0) {
      return 0
    }

    return xs.find(x => tStep <= x) || xs[xs.length - 1]
  }

  getCurrentTimeLineStep() {
    const { fps, timeMode } = this.props
    const aproxStep = this.getApproximateTimeLineStep()
    const corrected100nsStep = this.getTemplateTimeLineTimeStap(aproxStep)
    const {
      frameCount,
    } = getObjectTimeCode(timelineTimeToSeconds(corrected100nsStep), fps, timeMode)
    return secondsToTimelineTime(frameNumberToSeconds(frameCount, fps))
  }

  initMinTextWidth() {
    const ctx = this.canvasRef.current.getContext('2d')
    ctx.font = timebarTextFont
    const textSize = ctx.measureText('00:00:00,000')
    this.textWidth = Math.ceil(textSize.width) * 2
    this.textHeight = Math.ceil(textSize.actualBoundingBoxAscent) + 1
  }

  initTimeArray() {
    this.updatedTimeArray = this.initialStepTable.slice()
  }

  initZoomSliderLastStepIndex() {
    this.lastZoomSliderStep = this.updatedTimeArray.length
    const step = this.getCurrentTimeLineStep()
    for (let i = 0; i < this.updatedTimeArray.length; i++) {
      if (this.updatedTimeArray[i] <= step) {
        this.lastZoomSliderStep = i
      }
    }
    this.lastZoomSliderStep += 1
  }


  getTickX(frame) {
    const { fps, timelineScrollLeft, scale } = this.props
    return time2Pixel(secondsToTimelineTime(frame / fps), scale) - timelineScrollLeft
  }

  getFrameCount(unitSec) {
    const { fps, timeMode } = this.props
    const { frameCount } = getObjectTimeCode(timelineTimeToSeconds(unitSec), fps, timeMode)
    return frameCount
  }

  setFrameStep(frame) {
    const { fps } = this.props
    const x = this.getTickX(frame)
    let nextX = this.getTickX(frame + 1)

    if (fps === DEFAULT_FPS) {
      this.minTickInterval = 6
      this.frameStep = 1
    }

    let isSetStepTitle = false
    this.titleFrameStep = Math.round(fps)

    // for 25 fps
    const frameInterval = [ 1, 5, 15, 30, 60, 300, 900 ]
    const timeInterval = [ 1, 60, 3600 ] // 1 - one second, 60 - seconds, 3600 - seconds
    let tPoint = 0
    let fPoint = 0

    const countFrameIntervals = frameInterval.length - 1
    // if less than tick interval
    while (nextX - x < this.minTickInterval) {
      if (fps === DEFAULT_FPS) {
        this.frameStep = 5 * frameInterval[fPoint]
        fPoint += 1
      } else {
        this.frameStep *= 2
      }
      nextX = this.getTickX(frame + this.frameStep)
      isSetStepTitle = true
    }


    // if titles overlap and minTickInterval value has not yet been reached
    if (!isSetStepTitle) {
      if (x + this.textWidth < this.getTickX(frame + 5) + 5) {
        this.titleFrameStep = 5
      } else if (this.titleFrameStep !== DEFAULT_FPS
        && x + this.textWidth < this.getTickX(frame + 15)) {
        this.titleFrameStep = 15
      }
    }


    // title counter for 25 fps
    if (fps === DEFAULT_FPS) {
      tPoint = 0
      fPoint = 0
      while (x + this.textWidth > this.getTickX(frame + this.titleFrameStep)) {
        this.titleFrameStep = fps * timeInterval[tPoint] * frameInterval[fPoint]
        fPoint += 1
        if (fPoint > countFrameIntervals) {
          fPoint = 0
          tPoint += 1
        }
      }
    }
  }


  drawRuller() {
    this.frameStep = 1
    this.titleFrameStep = 1
    this.minTickInterval = 4

    const cs = getComputedStyle(this.canvasRef.current)
    const width = getWindowWidth() // parseInt(cs.getPropertyValue('width'), 10);
    const height = parseInt(cs.getPropertyValue('height'))
    this.canvasRef.current.width = width
    this.canvasRef.current.height = height

    const { scale, fps, timeMode } = this.props

    const ctx = this.canvasRef.current.getContext('2d')
    ctx.translate(0.5, -0.5)
    ctx.clearRect(0, 0, width, height)

    ctx.lineWidth = 1
    ctx.lineCap = 'square'
    ctx.beginPath()
    ctx.moveTo(0, height)
    ctx.lineTo(width, height)
    ctx.strokeStyle = timebarColor
    ctx.stroke()
    let frame = this.getFrameCount(this.startTime)
    const stopFrame = this.getFrameCount(pixel2Time(width + 1, scale) + this.startTime)

    this.setFrameStep(frame)
    // console.log(`scroll left ${this.scrollLeft}, draw till ${stopPosX}`);
    while (frame <= stopFrame) {
      let timeCodeData = {}
      if (frame % this.frameStep === 0) {
        const x = this.getTickX(frame)
        ctx.lineWidth = 1
        ctx.lineCap = 'square'
        ctx.beginPath()
        ctx.moveTo(x, height)

        if (frame % this.titleFrameStep === 0) {
          const isDF = isDropFrameTimeCode(timeMode, fps)
          timeCodeData = getObjectTimeCode(frameNumberToSeconds(frame, fps), fps, timeMode)
          const {
            hours,
            milliseconds,
            seconds,
            minutes,
            frames,
          } = timeCodeData

          ctx.lineTo(x, height - this.longTickHeight)
          ctx.fillStyle = timebarTextColor
          ctx.font = timebarTextFont
          const timeStr = timeMode === TIME_CODE_MODE.MILLISECOND
            ? `${n(hours)}:${n(minutes)}:${n(seconds)}${SEPARATOR.DOT}${nnn(milliseconds)}`
            : `${n(hours)}:${n(minutes)}:${n(seconds)}${isDF
              ? SEPARATOR.SEMICOLON
              : SEPARATOR.COLON
            }${n(frames)}`
          ctx.fillText(timeStr, x + 2, (height - this.longTickHeight) + this.textHeight / 2)
        } else {
          ctx.lineTo(x, height - this.shortTickHeight)
        }
        ctx.strokeStyle = timebarColor
        ctx.stroke()
      }
      frame += 1
    }
  }

}

const mapStateToProps = state => ({
  scale: state.timeline.scale,
  // sliderTime: state.timeline.sliderTime,
  // range: state.timeline.range,
})

const mapDispatchToProps = dispatch => ({
  setScale: scale => dispatch(Actions.timeline.setScale(scale)),
  setTimelineDuration: duration => dispatch(Actions.timeline.setDuration(duration)),
  setRullerOffset: offset => dispatch(Actions.timeline.setRullerOffset(offset)),
  rebuildTimeLineRuller: () => dispatch(Actions.timeline.rebuildTimeLineRuller()),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(props => {
  const { scrollLeft } = React.useContext(TimelineScrollPositionContext)
  const refVideo = useSelector(getReferenceVideoAsset)
  const timeMode = useSelector(state => state.playback.timeCodeMode)

  return (
    <TimelineRuller
      {...props}
      timelineScrollLeft={scrollLeft}
      fps={getFPSByRefVideo(refVideo)}
      timeMode={timeMode[TIME_CODE_CONTAINER_LOCATION.TIMELINE]}
    />
  )
}))
