export const UNIT_MEASUREMENT = {
  HOUR: 'hours',
  MINUTE: 'minutes',
  SECOND: 'seconds',
  MILLISECOND: 'milliseconds',
  FRAME: 'frames',
  FRAME_DF: 'frames_df',
  FRAME_NDF: 'frames_ndf',
} as const

export const UNIT_MEASUREMENT_TITLE = {
  [UNIT_MEASUREMENT.MILLISECOND]: 'ms',
  [UNIT_MEASUREMENT.FRAME]: 'frame',
  [UNIT_MEASUREMENT.HOUR]: 'hh',
  [UNIT_MEASUREMENT.MINUTE]: 'mm',
  [UNIT_MEASUREMENT.SECOND]: 'ss',
} as const

export const TIME_CODE_STATE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
} as const

export const TIME_CODE_CONTAINER_LOCATION = {
  TIMELINE: 'TIMELINE_TIME_CODE',
  SOURCE_MEDIA: 'SOURCE_MEDIA_TIME_CODE',
} as const

export const MAX_UNIT_INDEX = 3
export const DEFAULT_TIME_CODE_VALUES = {
  [UNIT_MEASUREMENT.HOUR]: '00',
  [UNIT_MEASUREMENT.MINUTE]: '00',
  [UNIT_MEASUREMENT.SECOND]: '00',
  [UNIT_MEASUREMENT.MILLISECOND]: '000',
  [UNIT_MEASUREMENT.FRAME]: '00',
}

export const PERMITTED_TIME_CODE_HOTKEYS = {
  ESC: 'Escape',
  ARROWRIGHT: 'ArrowRight',
  ARROWLEFT: 'ArrowLeft',
  ARROWUP: 'ArrowUp',
  ARROWDOWN: 'ArrowDown',
  KEY_C: 'c',
  KEY_V: 'v',
  ENTER: 'Enter',
}

export const TIME_CODE_DIGITS = {
  FIRST: 'DIGIT-FIRST',
  SECOND: 'DIGIT-SECOND',
  THIRD: 'DIGIT-THIRD',
} as const

export const TIME_CODE_DIGITS_SEQUENCE = [
  TIME_CODE_DIGITS.FIRST,
  TIME_CODE_DIGITS.SECOND,
  TIME_CODE_DIGITS.THIRD,
]

export const INCREMENT_VALUE_BY_DIGIT_TYPE = {
  [TIME_CODE_DIGITS.FIRST]: 1,
  [TIME_CODE_DIGITS.SECOND]: 10,
  [TIME_CODE_DIGITS.THIRD]: 100,
}

export const TIME_CODE_MODE = {
  MILLISECOND: UNIT_MEASUREMENT.MILLISECOND,
  FRAME_DF: UNIT_MEASUREMENT.FRAME_DF,
  FRAME_NDF: UNIT_MEASUREMENT.FRAME_NDF,
} as const

export const TIME_CODE_UNIT_PARAMS = {
  [UNIT_MEASUREMENT.HOUR]: {
    MIN: 0,
    MAX: 99,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.MINUTE]: {
    MIN: 0,
    MAX: 59,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.SECOND]: {
    MIN: 0,
    MAX: 59,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.MILLISECOND]: {
    MIN: 0,
    MAX: 999,
    MASK: '000',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.THIRD),
    INPUT_ELEMS: 3,
  },
  [UNIT_MEASUREMENT.FRAME]: {
    MIN: 0,
    MAX: 99,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.FRAME_DF]: {
    MIN: 0,
    MAX: 99,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.FRAME_NDF]: {
    MIN: 0,
    MAX: 99,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
}

export const INITIAL_TIME_CODE_DATA = [
  {
    value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.HOUR],
    active: false,
    editableDigit: null,
    nextDigit: null,
    inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.HOUR].INPUT_ELEMS - 1,
    id: UNIT_MEASUREMENT.HOUR,
  },
  {
    value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.MINUTE],
    active: false,
    editableDigit: null,
    nextDigit: null,
    inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.MINUTE].INPUT_ELEMS - 1,
    id: UNIT_MEASUREMENT.MINUTE,
  },
  {
    value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.SECOND],
    active: false,
    editableDigit: null,
    nextDigit: null,
    inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.SECOND].INPUT_ELEMS - 1,
    id: UNIT_MEASUREMENT.SECOND,
  },
]

export const INITIAL_TIME_CODE_DATA_BY_MODE = {
  [TIME_CODE_MODE.MILLISECOND]: [
    ...INITIAL_TIME_CODE_DATA,
    {
      value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.MILLISECOND],
      active: false,
      editableDigit: null,
      nextDigit: null,
      inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.MILLISECOND].INPUT_ELEMS - 1,
      id: UNIT_MEASUREMENT.MILLISECOND,
    },
  ],
  [TIME_CODE_MODE.FRAME_DF]: [
    ...INITIAL_TIME_CODE_DATA,
    {
      value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.FRAME],
      active: false,
      editableDigit: null,
      nextDigit: null,
      inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.FRAME].INPUT_ELEMS - 1,
      id: UNIT_MEASUREMENT.FRAME,
    },
  ],
  [TIME_CODE_MODE.FRAME_NDF]: [
    ...INITIAL_TIME_CODE_DATA,
    {
      value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.FRAME],
      active: false,
      editableDigit: null,
      nextDigit: null,
      inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.FRAME].INPUT_ELEMS - 1,
      id: UNIT_MEASUREMENT.FRAME,
    },
  ],
}

export const TIME_CODE_DATASET_ID = {
  CONTAINER: 'data-time-code-container',
  INPUT: 'data-time-code-input',
  HOURS: 'data-time-code-hours',
  MINUTES: 'data-time-code-minutes',
  SECONDS: 'data-time-code-seconds',
  MILLISECONDS: 'data-time-code-milliseconds',
  FRAMES: 'data-time-code-frames',
  SEPARATOR: 'data-time-code-separator',
  MEASUREMENT: 'data-time-code-measurement',
  MENU: 'data-time-code-menu',
  CHEVRON: 'data-time-code-chevron',
} as const


export const TIME_CODE_DATASET_VALUES = Object.values(TIME_CODE_DATASET_ID)
export const TIME_CODE_TIME_UNITS = [
  TIME_CODE_DATASET_ID.HOURS,
  TIME_CODE_DATASET_ID.MINUTES,
  TIME_CODE_DATASET_ID.SECONDS,
  TIME_CODE_DATASET_ID.MILLISECONDS,
  TIME_CODE_DATASET_ID.FRAMES,
]

export const TIME_CODE_MODE_VALUES = [
  {
    getValue: () => 'Timecode',
    isVisible: () => true,
    title: 'HH:MM:SS.MSEC',
    mode: TIME_CODE_MODE.MILLISECOND,
    checkRegExp: /^\d{2}:\d{2}:\d{2}.\d{3}$/,
  },
  {
    getValue: (fps: number) => `Timecode + frames (DF) ${fps.toFixed(2)} fps`,
    isVisible: (fps: number) => Number(fps.toFixed(2)) === 29.97,
    title: 'HH:MM:SS;FRAME',
    mode: TIME_CODE_MODE.FRAME_DF,
    checkRegExp: /^\d{2}:\d{2}:\d{2};\d{2}$/,
  },
  {
    getValue: (fps: number) => (
      Number(fps.toFixed(2)) === 29.97
        ? `Timecode + frames (NDF) ${fps.toFixed(2)} fps`
        : 'Timecode + frames'
    ),
    isVisible: () => true,
    title: 'HH:MM:SS:FRAME',
    mode: TIME_CODE_MODE.FRAME_NDF,
    checkRegExp: /^\d{2}:\d{2}:\d{2}:\d{2}$/,
  },
]
