/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { recording as recordingSelectors } from 'selectors'
// import { ReactComponent as DoubleRewindIcon } from '~/assets/video-player/ic_double_rewind.svg'
import { HOTKEYS, PLAYER_TYPE } from 'enums'
import { useHotkeys } from 'react-hotkeys-hook'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { TranslationContext } from 'contexts/TranslationContext'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import { TIME_CODE_CONTAINER_LOCATION } from 'config/constants/timecode'
import { MARK_TYPE } from 'config/constants/preview'
import { DEFAULT_FPS, timelineTimeToSeconds } from '~/Util'
import * as Actions from '~/actions'
import { ReactComponent as PlayIcon } from '~/assets/video-player/ic_play.svg'
import { ReactComponent as FrameStepIcon } from '~/assets/video-player/ic_rewind.svg'
import { ReactComponent as StopIcon } from '~/assets/video-player/ic_stop.svg'
import { ReactComponent as MarkInIcon } from '~/assets/preview/mark_in.svg'
import { ReactComponent as MarkOutIcon } from '~/assets/preview/mark_out.svg'
import RawButton from '~/components/base/RawButton'
import Slider from '~/components/base/Slider'
import * as PT from '~/PropTypes'
import { useAction } from '~/hooks'

import './PreviewToolbar.scss'
import { getProjectParams, useFrameStepHandlers, useTimelineRewindHandler } from './TimelinePreview/lib'
import { TimeCode } from '~/components/Common/TimeCode'
import { ClipCreatorBar } from './ClipCreator/ClipCreatorBar'
import { useClipCreator } from '~/hooks/preview/clipCreator/useClipCreator'
import { PreviewPlayerToolbar } from '~/components/PreviewPlayerToolbar'
import { selectorSplitPlayers } from '~/selectors/preview'
import { usePreviewSliderProgress } from '~/hooks/preview/usePreviewSliderProgress'

function PreviewToolbar(props) {
  const {
    playing,
    fps,
    onPlayPause,
    onRewindTimeline,
    onPrevFrame,
    onNextFrame,
    duration,
    progress,
    isMedia,
    previewPlayerType,
    previewPlayerVideoElem,
    asset,
    isLoading,
  } = props

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const project = useSelector(getProjectParams)
  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const mediaRecording = useSelector(recordingSelectors.isRecordingStarted)
  const isSaving = useSelector(recordingSelectors.isRecordingSaving)
  const recordingCountdownStarted = useSelector(recordingSelectors.selectShowCountdown)
  const isSplittedPreviewPlayers = useSelector(selectorSplitPlayers)

  const { t } = useContext(TranslationContext)
  const { getTooltip } = useHotkeyTooltip()

  const onAddToTimeline = useAction(Actions.timeline.addSourceFileAtSliderPosition, selectedClipId)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ADD_TO_TIMELINE], onAddToTimeline)


  const disabledCtrls = mediaRecording || isSaving
  // TODO: Refactor this selector for separate of sliderTime and move this into the TimelinePreview component
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const _duration = timelineTimeToSeconds(project.duration)
  const _progress = timelineTimeToSeconds(project.progress)

  const _onRewindTimeline = useTimelineRewindHandler()
  const {
    onPrevFrame: _onPrevFrame,
    onNextFrame: _onNextFrame,
  } = useFrameStepHandlers(fps, previewPlayerType)
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PREV_FRAME], onPrevFrame || _onPrevFrame)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.NEXT_FRAME], onNextFrame || _onNextFrame)

  const previewType = isMedia ? PLAYER_TYPE.MEDIA : PLAYER_TYPE.TIMELINE

  const {
    onRewind,
    onTogglePlayPause,
    clipDuration,
    previewProgress,
    currentFps,
    handlePrevFrame,
    handleNextFrame,
    startTime,
  } = usePreviewSliderProgress({
    isMedia,
    mediaAsset: asset,
    onPlayPause,
    fps,
    onRewindTimeline: isMedia ? onRewindTimeline : _onRewindTimeline,
    previewDration: isMedia ? duration : _duration,
    progress: isMedia ? progress : _progress,
    onPrevFrame: isMedia ? onPrevFrame : _onPrevFrame,
    onNextFrame: isMedia ? onNextFrame : _onNextFrame,
    preview: previewType,
  })

  const {
    onSetMarker,
    getTimePointToPercent,
    onClearInOutPoints,
    contextMenuContainer,
    onOpenMenu,
    onCloseMenu,
    anchorEl,
    anchorPosition,
    onClickMarkIcon,
    showInOutPoints,
    clipMarkers,
    onSetDragging,
    isDragging,
    isResize,
    onClickMarker,
    ioPointsBarRef,
    sliderProgress,
    rightTimeCodeDuration,
  } = useClipCreator({
    preview: previewType,
    progress: previewProgress,
    previewPlayerVideoElem,
    duration: clipDuration,
    asset,
    currentFps,
    onRewind,
    isMedia,
    onTogglePlayPause,
  })

  return (
    <div className={cx('preview-player-toolbar', { fullScreen: document.fullscreenElement })}>
      <div onContextMenu={onOpenMenu} className={cx('preview-player-toolbar__timeline', { 'preview-player-toolbar--disabled': disabledCtrls })}>
        <ClipCreatorBar
          duration={clipDuration}
          clipMarkers={clipMarkers}
          getTimePointToPercent={getTimePointToPercent}
          onMarkIn={onSetMarker(previewType, MARK_TYPE.IN)}
          onMarkOut={onSetMarker(previewType, MARK_TYPE.OUT)}
          onClearInOutPoints={onClearInOutPoints(previewType)}
          onCloseMenu={onCloseMenu}
          anchorEl={anchorEl}
          anchorPosition={anchorPosition}
          onClickMarkIcon={onClickMarkIcon}
          showInOutPoints={showInOutPoints}
          onSetDragging={onSetDragging}
          isMedia={isMedia}
          isDragging={isDragging}
          isResize={isResize}
          isLoading={isLoading}
          isSplittedPreviewPlayers={isSplittedPreviewPlayers}
          ref={ioPointsBarRef}
        />
        <Slider
          className="preview-player-toolbar__slider"
          value={sliderProgress}
          onChange={onRewind}
          max={clipDuration}
          step={0.01}
          ref={contextMenuContainer}
          onOpenMenu={onOpenMenu}
          isPreview
        />
      </div>
      <div className="preview-player-toolbar__buttons">
        <div className="preview-player-toolbar__left">
          <TimeCode
            progress={sliderProgress}
            containerLocation={isMedia
              ? TIME_CODE_CONTAINER_LOCATION.SOURCE_MEDIA
              : TIME_CODE_CONTAINER_LOCATION.TIMELINE}
            onRewind={onRewind}
            fps={fps}
            previewPlayerType={previewPlayerType}
            startTime={startTime}
            isMedia={isMedia}
          />
        </div>

        <div className="preview-player-toolbar__center">
          {/* <If condition={__CFG__.PREVIEW.KEY_FRAME_STEPPER}>
            <div className="preview-player-toolbar__item">
              <RawButton disabled={disabledCtrls}>
                <DoubleRewindIcon />
              </RawButton>
            </div>
          </If> */}
          <div
            className={cx('preview-player-toolbar__item')}
            title={getTooltip(t('PREVIEW_BTN_MARK_IN_TOOLTIP'), HOTKEYS.MARK_IN)}
          >
            <RawButton onClick={onClickMarker(previewType, MARK_TYPE.IN)} dataLangId="PREVIEW_BTN_MARK_IN">
              <MarkInIcon />
            </RawButton>
          </div>

          <div
            className={cx('preview-player-toolbar__item')}
            title={getTooltip(t('PREVIEW_BTN_MARK_OUT_TOOLTIP'), HOTKEYS.MARK_OUT)}
          >
            <RawButton onClick={onClickMarker(previewType, MARK_TYPE.OUT)} dataLangId="PREVIEW_BTN_MARK_OUT">
              <MarkOutIcon />
            </RawButton>
          </div>

          <div className="preview-player-toolbar__item" title={getTooltip(t('PREVIEW_BTN_PREV_TOOLTIP'), HOTKEYS.PREV_FRAME)}>
            <RawButton onClick={handlePrevFrame} disabled={disabledCtrls} dataLangId="PREVIEW_BTN_PREV">
              <FrameStepIcon />
            </RawButton>
          </div>

          <div className="preview-player-toolbar__item" title={getTooltip(!playing ? t('PREVIEW_BTN_PLAY_TOOLTIP') : t('PREVIEW_BTN_STOP_TOOLTIP'), HOTKEYS.PLAY_PAUSE)}>
            <RawButton onClick={onTogglePlayPause} disabled={recordingCountdownStarted || isSaving} dataLangId={!playing ? 'PREVIEW_BTN_PLAY' : 'PREVIEW_BTN_STOP'}>
              {playing ? <StopIcon /> : <PlayIcon />}
            </RawButton>
          </div>

          <div className="preview-player-toolbar__item" title={getTooltip(t('PREVIEW_BTN_NEXT_TOOLTIP'), HOTKEYS.NEXT_FRAME)}>
            <RawButton onClick={handleNextFrame} disabled={disabledCtrls} dataLangId="PREVIEW_BTN_NEXT">
              <FrameStepIcon className="revert-icon" />
            </RawButton>
          </div>

          {/* <If condition={__CFG__.PREVIEW.KEY_FRAME_STEPPER}>
            <div className="preview-player-toolbar__item">
              <RawButton disabled={disabledCtrls}>
                <DoubleRewindIcon className="revert-icon" />
              </RawButton>
            </div>
          </If> */}
        </div>
        <Box className="preview-player-toolbar__right" gridGap={20}>
          <If condition={!isSplittedPreviewPlayers}>
            <PreviewPlayerToolbar />
          </If>
          <If condition={!document.fullscreenElement}>
            <TimeCode
              progress={rightTimeCodeDuration}
              containerLocation={isMedia
                ? TIME_CODE_CONTAINER_LOCATION.SOURCE_MEDIA
                : TIME_CODE_CONTAINER_LOCATION.TIMELINE}
              onRewind={onRewind}
              fps={fps}
              previewPlayerType={previewPlayerType}
              readonly
            />
          </If>
        </Box>
      </div>
    </div>
  )
}

PreviewToolbar.defaultProps = {
  fps: DEFAULT_FPS,
  progress: 0,
  duration: 0,
  onRewindTimeline: null,
  onPrevFrame: null,
  onNextFrame: null,
  isMedia: false,
  previewPlayerType: '',
  previewPlayerVideoElem: { current: null },
  asset: null,
  isLoading: false,
}

PreviewToolbar.propTypes = {
  playing: PropTypes.bool.isRequired,
  fps: PropTypes.number,
  progress: PropTypes.number,
  duration: PropTypes.number,

  onRewindTimeline: PropTypes.func,
  onPrevFrame: PropTypes.func,
  onPlayPause: PropTypes.func.isRequired,
  onNextFrame: PropTypes.func,
  isMedia: PropTypes.bool,
  previewPlayerType: PropTypes.string,
  previewPlayerVideoElem: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  asset: PropTypes.oneOfType([
    PT.VideoAsset, PT.ImageAsset, PT.AudioAsset, PT.TextAsset,
  ]),
  isLoading: PropTypes.bool,
}

export default React.memo(PreviewToolbar)
