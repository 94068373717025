import React from 'react'
import { MovableModalContainer } from 'components/MovableModal'
import { useSelector } from 'react-redux'
import { selectShowEditorSettings } from 'selectors/mainView'
import { EditorSettingsComponent } from './EditorSettingsComponent'

export const EditorSettingsContainer = () => {
  const visible = useSelector(selectShowEditorSettings)
  return (
    <MovableModalContainer
      visible={visible}
      restorePosition={false}
      width={750}
      height={400}
      background="black"
      withBackground
    >
      <EditorSettingsComponent />
    </MovableModalContainer>
  )
}
