import { setEdition as setEditionAction } from 'actions/app'
import { updateUserName } from 'actions/user'
import { useAction } from 'hooks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectInstallVersion } from 'selectors/app'
import { patchUser } from 'ServerAPI'
import { v4 as uuidv4 } from 'uuid'
import { ActivationError, ConnectionActivationError, MultipleUsageActivationError } from './ActivationError'
import {
  activateKey as apiActivateKey,
  checkActivation,
  getKeys, getToken
} from './api'
import PROFILE_CONFIG from './config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let nw: any

export const useActivationSystem = () => {
  const {
    FREE_KEY,
    LOCAL_STORAGE_ACTIVATION_KEY_DATE_VAR,
    LOCAL_STORAGE_EDITION_VAR,
    LOCAL_STORAGE_FAILED_CHECK_DATE_VAR,
    LOCAL_STORAGE_KEY_VAR,
    LOCAL_STORAGE_LAST_CHECK_KEY_DATE_VAR,
    LOCAL_STORAGE_TOKEN_VAR,
    LONG_KEY_CHECK_PERIOD,
    PERIOD_WITH_FAILED_AND_WITH_ERROR,
    SHORT_KEY_CHECK_PERIOD,
  } = PROFILE_CONFIG
  const setUserName = useAction(updateUserName)
  const setEdition = useAction(setEditionAction)
  const installVersion = useSelector(selectInstallVersion)
  const [ userEmail, setUserEmail ] = useState<string | undefined>(undefined)
  const [ uuid ] = useState(() => uuidv4())
  const [ keys, setKeys ] = useState<Array<{ key: string; edition: string }> | undefined>(undefined)
  const [ error, setError ] = useState<ActivationError | undefined>(undefined)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ durationWithFailedCheckMs, setDurationWithFailedCheckMs ] = useState(0)
  const [ activeKey, setActiveKey ] = useState<string | null>(
    () => localStorage.getItem(LOCAL_STORAGE_KEY_VAR)
  )
  const url = PROFILE_CONFIG.AUTHORIZATION_REQUIRED ? `${PROFILE_CONFIG.BASE_AUTH_URL}/login_form?instanceId=${uuid}
&productName=${PROFILE_CONFIG.PRODUCT_NAME}&productVersion=${installVersion}` : ''

  const requestKeys = async () => {
    const { keys, user } = await getKeys()
    setKeys(keys.map(({ key, data }) => ({ key, edition: data })).concat({ key: FREE_KEY, edition: FREE_KEY }))
    setUserEmail(user)
    if (!keys.length) {
      setKey(FREE_KEY)
    }
    setUserName(user)
    patchUser({ name: user })
  }

  const requestToken = async () => {
    try {
      const response = await getToken(uuid)
      if (response.token) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN_VAR, response.token)
        requestKeys()
      } else {
        throw new Error('Token has not recieved')
      }
    } catch (e) {
      requestToken()
    }
  }

  const openRegPage = () => {
    nw.Shell.openExternal(url)

    requestToken()
  }

  const setKey = (key: string, edition: string = key) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_VAR, key)
    localStorage.setItem(LOCAL_STORAGE_ACTIVATION_KEY_DATE_VAR, new Date().toISOString())
    localStorage.setItem(LOCAL_STORAGE_EDITION_VAR, edition)
    setActiveKey(key)
    setEdition(edition)
  }

  // Select a key from the keys list
  const activateKey = async (key: string) => {
    if (key === FREE_KEY) {
      setKey(key, FREE_KEY)
    } else {
      try {
        setIsLoading(true)
        const { edition } = await apiActivateKey(installVersion, key)
        setKey(key, edition)
      } catch (e) {
        if (e instanceof MultipleUsageActivationError) {
          e.canRetry = true
          e.retry = () => activateKey(key)
        }
        if (e instanceof ActivationError) {
          setError(e)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  const clearKey = () => {
    setActiveKey(null)
    if (PROFILE_CONFIG.AUTHORIZATION_REQUIRED) {
      const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_VAR)
      localStorage.clear()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      localStorage.setItem(LOCAL_STORAGE_TOKEN_VAR, token!)
      requestKeys()
    }
  }

  useEffect(() => {
    if (durationWithFailedCheckMs > PERIOD_WITH_FAILED_AND_WITH_ERROR) {
      setError(new ActivationError('', 'Check your internet connection or key activation'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ durationWithFailedCheckMs ])

  useEffect(() => {
    const activationDateStr = localStorage.getItem(LOCAL_STORAGE_ACTIVATION_KEY_DATE_VAR)
    const lastCheckDateStr = localStorage.getItem(LOCAL_STORAGE_LAST_CHECK_KEY_DATE_VAR)
    const failedCheckDateStr = localStorage.getItem(LOCAL_STORAGE_FAILED_CHECK_DATE_VAR)
    const key = localStorage.getItem(LOCAL_STORAGE_KEY_VAR)

    const now = new Date()
    const activationDate = activationDateStr ? new Date(activationDateStr) : null
    const failedCheckDate = failedCheckDateStr ? new Date(failedCheckDateStr) : null
    const failedCheckDiff = failedCheckDate ? +now - +failedCheckDate : 0

    const maxPeriodFailedCheck = (PERIOD_WITH_FAILED_AND_WITH_ERROR + LONG_KEY_CHECK_PERIOD)
    if (failedCheckDiff && failedCheckDiff > maxPeriodFailedCheck) {
      clearKey()
    }

    if (key && key !== FREE_KEY && activationDate) {
      const lastCheckDate = lastCheckDateStr ? new Date(lastCheckDateStr) : null

      const activationDiff = +now - +activationDate
      const checkDiff = lastCheckDate ? +now - +lastCheckDate : LONG_KEY_CHECK_PERIOD // any big diff
      setIsLoading(true)
      // before 2 weeks: every 3 days
      // after 2 weeks: every 2 weeks or every 3 days if check failed
      if (((activationDiff < LONG_KEY_CHECK_PERIOD || !!failedCheckDate) && checkDiff > SHORT_KEY_CHECK_PERIOD)
      || (activationDiff >= LONG_KEY_CHECK_PERIOD && checkDiff >= LONG_KEY_CHECK_PERIOD)) {
        setTimeout(periodicalCheck, 2000) // token exists after some delay
      } else {
        setTimeout(permanentCheck, 2000) // token exists after some delay
        setDurationWithFailedCheckMs(failedCheckDiff)
      }
    }

    // checks every startup
    async function permanentCheck() {
      try {
        if (!key) {
          throw new ActivationError('', 'Key is not defined')
        }
        await checkActivation(installVersion, key)
      } catch (e) {
        if (e instanceof ActivationError) {
          clearKey()
          setError(e)
        }
      } finally {
        setIsLoading(false)
      }
    }

    // cheks by alghoritm
    async function periodicalCheck() {
      try {
        if (!key) {
          throw new Error('Active key is not defined')
        }
        localStorage.setItem(LOCAL_STORAGE_LAST_CHECK_KEY_DATE_VAR, new Date().toISOString())
        const { edition } = await apiActivateKey(installVersion, key)
        setKey(key, edition)
        localStorage.removeItem(LOCAL_STORAGE_FAILED_CHECK_DATE_VAR)
      } catch (e) {
        if (e instanceof ActivationError) {
          if (!(e instanceof ConnectionActivationError)) {
            clearKey()
            setError(e)
          } else {
            // Important to write only if failedCheckDate is not defined to prevent rewriting by next checking
            if (!failedCheckDate) {
              localStorage.setItem(LOCAL_STORAGE_FAILED_CHECK_DATE_VAR, new Date().toISOString())
            }
            setDurationWithFailedCheckMs(failedCheckDiff)
          }
        }
      } finally {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ installVersion ])

  const skipError = () => {
    setError(undefined)
  }

  return { uuid, openRegPage, url, keys, activateKey, activeKey, userEmail, error, skipError, isLoading }
}
