import List from 'components/AssetPanels/BaseAssetsList'
import React, { useContext } from 'react'
import { TranslationContext } from 'contexts/TranslationContext'
import LoadButton from '../Media/LoadButton'
import Item from './ListItem'
import styles from './list.module.scss'
import { HeaderContainer } from './HeaderContainer'


export default function ImageAssetsList(props) {
  const { type } = props
  const { t } = useContext(TranslationContext)

  /* const imageTabs = [
    { id: 'search', name: t('IMAGES_TAB_SEARCH') },
    { id: 'ai', name: t('IMAGES_TAB_AI_GENERATED') },
  ] */

  return (
    <List
      {...props}
      header={<HeaderContainer type={type} />}
      ItemComponent={props => <Item {...props} />}
      placeholder={(
        <div className={styles.placeholder}>
          <div className={styles.header}>{t('IMAGES_TEXT_NO_RESULTS_FOUND')}</div>
          <div className={styles.desc}>
            {t('IMAGES_TEXT_CANNOT_FIND_RESULTS')}
          </div>
        </div>
      )}
      after={
        __CFG__.SOURCE_FILES_MANAGEMENT.LOAD_MEDIA_ONSCROLL ? null : (
          <LoadButton type={type} />
        )
      }
    />
  )
}
