import { INCREMENT_VALUE_BY_DIGIT_TYPE, TIME_CODE_DIGITS } from 'config/constants/timecode'
import { TimeCodeDigitsType } from 'types/timecode'

export const getIncrementValue = (digitType: TimeCodeDigitsType | null) => {
  switch (digitType) {
    case TIME_CODE_DIGITS.SECOND:
      return INCREMENT_VALUE_BY_DIGIT_TYPE[TIME_CODE_DIGITS.SECOND]
    case TIME_CODE_DIGITS.THIRD:
      return INCREMENT_VALUE_BY_DIGIT_TYPE[TIME_CODE_DIGITS.THIRD]
    default:
      return INCREMENT_VALUE_BY_DIGIT_TYPE[TIME_CODE_DIGITS.FIRST]
  }
}
