import React, { ChangeEvent, FormEvent, ReactNode, SyntheticEvent, useEffect, useReducer } from 'react'
import { FormContext } from 'contexts/FormContext'
import { useDispatch } from 'react-redux'

type InitialState<T> = {
  [K in keyof T]: T[K]
}

type FormInputData<T> = {
  [K in keyof T]?: T[K]
}

type Props<T> = {
  children: ReactNode | ReactNode[]
  initialState: InitialState<T>
  action: (formInput: InitialState<T>) => ({
    type: string,
    payload: InitialState<T>
  })
}

const SET_DATA_ACTION = 'set_data'
const RESET_ACTION = 'reset'

type FormActionType = typeof SET_DATA_ACTION | typeof RESET_ACTION

export const FormContextProvider = <T, >({ children, initialState, action }: Props<T>) => {
  const dispatch = useDispatch()
  const [ formState, formDispatch ] = useReducer(
    (state: InitialState<T>, action: { type: FormActionType, payload?: FormInputData<T> }) => {
      switch (action.type) {
        case SET_DATA_ACTION:
          return { ...state, ...action.payload }
        case RESET_ACTION:
          return { ...initialState }
        default:
          return state
      }
    }, { ...initialState }
  )

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement
    const value = input.value || Number(input.checked) || 0
    formDispatch({
      type: SET_DATA_ACTION,
      payload: { [input.name]: value } as unknown as FormInputData<T>,
    })
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(action(formState))
  }

  const onButtonFormSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    dispatch(action(formState))
  }

  const onReset = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    form.reset()
    formDispatch({ type: RESET_ACTION })
  }

  useEffect(() => {
    formDispatch({ type: RESET_ACTION })
  }, [ initialState ])

  return (
    <FormContext.Provider
      value={{
        onSubmit,
        onReset,
        handleInput,
        onButtonFormSubmit,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}
