import { ReactComponent as EmptyFolder } from 'assets/media/empty_folder.svg'
import List from 'components/AssetPanels/BaseAssetsList'
import { AddFolderButton } from 'components/AssetPanels/components/AddFolderButton/AddFolderButton'
import React from 'react'
import { useSelector } from 'react-redux'
import HeaderContainer from './HeaderContainer'
import Item from './ListItem'
import LoadButton from './LoadButton'

import Uploader from './Uploader'
import styles from './list.module.scss'

function UploaderWrapper({ type, visible }) {
  return (
    <If condition={visible}>
      <Uploader type={type} />
      <div className="assets-list__uploader-placeholder" />
    </If>
  )
}

function UploaderWrapperPlaceholder({ type, visible }) {
  return (
    <Choose>
      <When condition={visible}>
        <Uploader type={type} />
        <div className="assets-list__uploader-placeholder" />
      </When>
      <Otherwise>
        <div className={styles.placeholder}>
          <EmptyFolder className={styles.icon} />
          The folder is empty
        </div>
      </Otherwise>
    </Choose>
  )
}

export default function MediaAssetsList(props) {
  const { type } = props
  const customMediaTabContent = useSelector(
    state => state.user.customMediaTabContent
  )
  const uploaderVisible = __CFG__.IMPORT_MEDIA && !customMediaTabContent
  const enableAddFolder = __CFG__.SOURCE_FILES_MANAGEMENT.MOVE_TO_FOLDERS && !customMediaTabContent
  return (
    <List
      {...props}
      header={<HeaderContainer type={type} />}
      before={<UploaderWrapper type={type} visible={uploaderVisible} />}
      placeholder={
        <UploaderWrapperPlaceholder type={type} visible={uploaderVisible} />
      }
      ItemComponent={props => <Item {...props} />}
      after={
        __CFG__.SOURCE_FILES_MANAGEMENT.LOAD_MEDIA_ONSCROLL ? null : (
          <LoadButton type={type} />
        )
      }
      float={enableAddFolder && <AddFolderButton />}
    />
  )
}
