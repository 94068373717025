import React, { ChangeEvent, FormEvent, SyntheticEvent } from 'react'

interface IContext {
  onSubmit(e: FormEvent<HTMLFormElement>): void
  onButtonFormSubmit(e: SyntheticEvent<HTMLButtonElement>): void
  onReset(e: FormEvent<HTMLFormElement>): void
  handleInput(e: ChangeEvent<HTMLInputElement>): void
}

export const FormContext = React.createContext<IContext>({} as IContext)
