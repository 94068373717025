import { ConfirmationDialog } from 'components/base/ConfirmationDialog/ConfirmationDialog'
import { TranslationContext } from 'contexts/TranslationContext'
import { useModalDialog } from 'hooks'
import React, { useContext, useEffect } from 'react'
import { ActivationError } from '../ActivationError'

type props = {
  error?: ActivationError;
  onClose: () => void;
}

export const ActivationErrorModal = ({ error, onClose = () => {} }: props) => {
  const modal = useModalDialog()

  useEffect(() => {
    if (error) {
      modal.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ error ])

  const handleClose = () => {
    modal.cancel()
    onClose()
  }

  const handleConfirm = () => {
    if (error?.retry) {
      handleClose()
      error.retry()
    } else {
      handleClose()
    }
  }

  const { t } = useContext(TranslationContext)

  return modal.active && error ? (
    <ConfirmationDialog
      onCancel={handleClose}
      onConfirm={handleConfirm}
      title={error.title}
      description={error.message}
      confirmBtnText={error.canRetry
        ? t('DESKTOP_ACTIVATION_FEATURE_BTN_RETRY')
        : t('DESKTOP_ACTIVATION_FEATURE_BTN_OK')}
      closeBtnText=""
      dataLangConfirmId={error.canRetry
        ? 'DESKTOP_ACTIVATION_FEATURE_BTN_RETRY'
        : 'DESKTOP_ACTIVATION_FEATURE_BTN_OK'}
      buttonConfirmTitle={error.canRetry
        ? t('DESKTOP_ACTIVATION_FEATURE_BTN_RETRY_TOOLTIP')
        : t('DESKTOP_ACTIVATION_FEATURE_BTN_OK_TOOLTIP')}
    />
  ) : null
}
