import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useAction } from 'hooks'
import { MAX_ZOOM_VALUE, MIN_ZOOM_VALUE, STEP_ZOOM_VALUE, LAYER_CONTROL_WIDTH } from 'constant'
import {
  LAYERS_MAX_X_SCROLL_SPEED,
  LAYERS_MIN_X_SCROLL_SPEED,
  LAYERS_Y_SCROLL_SPEED,
  SCROLLBAR_RIGHT_X_REDUCING_DELTA
} from 'config/constants/layer'
import Scrollbars from 'react-custom-scrollbars-2'
import { setScale } from 'actions/timeline'

export const useScrollBarControl = (scrollbarRef: React.MutableRefObject<Scrollbars>, scale: number) => {
  const scrollTopInterval = useRef<number | undefined>()
  const scrollLeftInterval = useRef<number | undefined>()
  const onSetScale = useAction(setScale)

  const { top, bottom, left, right, width, height } = useMemo(
    () => scrollbarRef.current?.container.getBoundingClientRect() ?? {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ scrollbarRef.current ]
  )

  const onScrollTimelineY = useCallback((event: MouseEvent) => {
    clearInterval(scrollTopInterval.current)
    scrollTopInterval.current = undefined

    const maxScroll = Math.ceil(scrollbarRef.current.getScrollHeight() - height)
    if (bottom <= event.clientY && !scrollTopInterval.current) {
      scrollTopInterval.current = setInterval(() => {
        const newData = scrollbarRef.current.getScrollTop() + LAYERS_Y_SCROLL_SPEED
        scrollbarRef.current.scrollTop(newData > maxScroll ? maxScroll : newData)
      })
    }

    if (top >= event.clientY && !scrollTopInterval.current) {
      scrollTopInterval.current = setInterval(() => {
        const newData = scrollbarRef.current.getScrollTop() - LAYERS_Y_SCROLL_SPEED
        scrollbarRef.current.scrollTop(newData < 0 ? 0 : newData)
      })
    }
  }, [ bottom, top, height, scrollbarRef ])

  const onScrollTimelineX = useCallback((event: MouseEvent) => {
    clearInterval(scrollLeftInterval.current)
    scrollLeftInterval.current = undefined

    const maxLeftScroll = scrollbarRef.current.getScrollWidth() - width
    const correctlyRightX = right - SCROLLBAR_RIGHT_X_REDUCING_DELTA

    if (!scrollLeftInterval.current) {
      // move the mouse to the right
      if (correctlyRightX <= (event.clientX + 5)) {
        scrollLeftInterval.current = setInterval(() => {
          const scrollSpeed = (event.clientX + 5) - correctlyRightX > 10
            ? LAYERS_MAX_X_SCROLL_SPEED : LAYERS_MIN_X_SCROLL_SPEED

          const newData = scrollbarRef.current.getScrollLeft() + scrollSpeed
          scrollbarRef.current.scrollLeft(newData > maxLeftScroll ? maxLeftScroll : newData)
        })
      }

      // move the mouse to the left
      const leftXStartScroll = left + LAYER_CONTROL_WIDTH
      if (event.clientX <= leftXStartScroll) {
        scrollLeftInterval.current = setInterval(() => {
          const scrollSpeed = leftXStartScroll - event.clientX > 10
            ? LAYERS_MAX_X_SCROLL_SPEED : LAYERS_MIN_X_SCROLL_SPEED

          const newData = scrollbarRef.current.getScrollLeft() - scrollSpeed
          scrollbarRef.current.scrollLeft(newData < 0 ? 0 : newData)
        })
      }
    }
  }, [ right, width, scrollbarRef, left ])


  useEffect(() => {
    const scrollbar = scrollbarRef.current.container
    const onWheel = (e: WheelEvent) => {
      if (e.altKey) {
        const normalizeScale = Math.log(scale)
        let newScale = normalizeScale
        if (e.deltaY > 0) {
          newScale = (normalizeScale + STEP_ZOOM_VALUE) > MAX_ZOOM_VALUE
            ? MAX_ZOOM_VALUE
            : normalizeScale + STEP_ZOOM_VALUE
        } else {
          newScale = (normalizeScale - STEP_ZOOM_VALUE) < MIN_ZOOM_VALUE
            ? MIN_ZOOM_VALUE
            : normalizeScale - STEP_ZOOM_VALUE
        }
        onSetScale(Math.exp(newScale))
      } else {
        const scrollLeft = scrollbarRef.current.getScrollLeft()
        scrollbarRef.current.scrollLeft(scrollLeft + e.deltaY)
      }
    }
    scrollbar.addEventListener('wheel', onWheel)
    return () => {
      scrollbar.removeEventListener('wheel', onWheel)
    }
  }, [ scrollbarRef, scale, onSetScale ])

  return {
    onScrollTimelineY,
    onScrollTimelineX,
    scrollTopInterval,
    scrollLeftInterval,
  }
}
