import React, { useCallback, useContext, useEffect, useState } from 'react'
// eslint-disable-next-line no-unused-vars
import { InputProps } from '@material-ui/core/Input'
import Input from 'components/base/Input'
import { TranslationContext } from 'contexts/TranslationContext'
import PropTypes from 'prop-types'
import { ReactComponent as ClearIcon } from '~/assets/ic_clear_search.svg'
import { ReactComponent as SearchIcon } from '~/assets/media/ic_search.svg'
import './SearchInput.scss'


const SearchInput = React.forwardRef(
  /**
  *
  * @param {InputProps & InferProps<(typeof SearchInput)} param0
  * @param {*} ref
  * @returns
  */
  ({ value, onSearch, className, placeholder, autoFocus, onChange, disableIcon, ...props },
    ref) => {
    const [ searchValue, setSearchValue ] = useState('')

    const onInputChange = useCallback(e => {
      setSearchValue(e.target.value)
      onChange(e.target.value)
    }, [ onChange ])

    const onClearInput = useCallback(() => {
      setSearchValue('')
      onSearch('')
    }, [ onSearch ])

    const onSubmit = e => {
      e.preventDefault()
      onSearch(searchValue)
    }

    const { t } = useContext(TranslationContext)

    useEffect(() => setSearchValue(value), [ value ])

    return (
      <form
        onSubmit={onSubmit}
        className={`${className} search-input`}
        draggable
        onDragStart={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Input
          startAdornment={!disableIcon ? <SearchIcon className="search-input__search-icon" /> : null}
          value={searchValue}
          onChange={onInputChange}
          placeholder={placeholder || t('SEARCH_INPUT_PLACEHOLDER_SEARCH')}
          autoFocus={autoFocus}
          ref={ref}
          {...props}
        />

        {searchValue.length > 0 && (
        <ClearIcon className="search-input__search-clear" onClick={onClearInput} />
        )}
      </form>
    )
  }
)

SearchInput.defaultProps = {
  className: '',
  value: '',
  placeholder: '',
  autoFocus: false,
  onChange: () => {},
  onSearch: () => {},
  disableIcon: false,
}

SearchInput.propTypes = {
  onSearch: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  disableIcon: PropTypes.bool,
}

export { SearchInput }
