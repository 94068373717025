import { TIME_CODE_MODE } from 'config/constants/timecode'
import Timecode, { FRAMERATE } from 'smpte-timecode'
import { TimeCodeModeType } from 'types/timecode'
import { DEFAULT_FPS, secondsToTimelineTime, timeToFractions } from 'Util'

export const frameNumberToSeconds = (frames: number, fps: number) => frames / fps

/*
 * These calculations are necessary because of the loss of precision
 * when multiplying floating point numbers in JavaScript.
 */
export const secondsToFrameNumber = (sec: number, fps: number) => Math.round(sec * fps)

export const isDropFrameTimeCode = (mode: TimeCodeModeType, fps: number) => (
  (mode === TIME_CODE_MODE.FRAME_DF) && (fps !== DEFAULT_FPS)
)

export const getObjectTimeCode = (progress: number, fps: number, timeMode: TimeCodeModeType) => {
  const isDF = isDropFrameTimeCode(timeMode, fps)
  const isFrameMode = timeMode === TIME_CODE_MODE.FRAME_DF || timeMode === TIME_CODE_MODE.FRAME_NDF
  const frameCount = secondsToFrameNumber(progress, fps)
  const { hours, minutes, seconds, frames } = new Timecode(frameCount, fps as FRAMERATE, isDF)
  const time = timeToFractions(secondsToTimelineTime(progress))

  return {
    hours: isFrameMode ? hours : time.hours,
    minutes: isFrameMode ? minutes : time.minutes,
    seconds: isFrameMode ? seconds : time.seconds,
    frames,
    milliseconds: time.milliseconds,
    frameCount,
    dropFrame: isDF,
  }
}
