import React, { FC } from 'react'
import { PreviewPlayerContextProvider } from 'providers/PreviewPlayerContextProveder'
import PreviewContainer from 'components/Preview/PreviewContainer'
import { SPLIT_RESIZER } from 'types/common'
import { DISPLAY_TYPE } from 'config/constants/ui'
import { PreviewWrapper } from '../wrappers/PreviewWrapper'

interface IProps {
  PreviewPlayersBottomComponent(): JSX.Element | null
  onSetResizing(resizing: boolean, resizer?: SPLIT_RESIZER): void
  previewRef: React.MutableRefObject<HTMLDivElement>
  timelineDisplayType: typeof DISPLAY_TYPE[keyof typeof DISPLAY_TYPE]
}

export const PreviewPlayersContainer: FC<IProps> = ({
  PreviewPlayersBottomComponent,
  timelineDisplayType,
  onSetResizing,
  previewRef,
}) => (
  <PreviewPlayerContextProvider>
    <PreviewWrapper onSetResizing={onSetResizing} timelineDisplayType={timelineDisplayType}>
      <PreviewContainer onSetResizing={onSetResizing} previewRef={previewRef} />
      <PreviewPlayersBottomComponent />
    </PreviewWrapper>
  </PreviewPlayerContextProvider>
)
