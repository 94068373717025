import React, { FC, MouseEvent } from 'react'
import Menu from '@material-ui/core/Menu'
import cs from 'classnames'
import MenuItem from 'components/base/MenuItem'
import { ActiveTimeUnits, TimeCodeModeType, UnitMeasurementFormatType } from 'types/timecode'
import { TIME_CODE_DATASET_ID, TIME_CODE_MODE_VALUES } from 'config/constants/timecode'

type IProps = {
  unitMeasurementFormats: UnitMeasurementFormatType[]
  unit: ActiveTimeUnits[number],
  isActiveContainer: boolean
  timeCodeContainer: React.MutableRefObject<HTMLDivElement | null>
  onChangeMode(mode: TimeCodeModeType):void
  onCloseMenu(e: MouseEvent): void
  anchorEl: HTMLDivElement | null
  timeMode: TimeCodeModeType,
  fps: number,
 }

export const TimeCodeContextMenu:FC<IProps> = ({
  unitMeasurementFormats,
  unit,
  isActiveContainer,
  timeCodeContainer,
  onChangeMode,
  onCloseMenu,
  anchorEl,
  timeMode,
  fps,
}) => {
  const handleTimeCodeMode = (mode: TimeCodeModeType) => (e: MouseEvent) => {
    onCloseMenu(e)
    onChangeMode(mode)
  }

  if (!unitMeasurementFormats.includes(unit.id as UnitMeasurementFormatType)) return null
  return (
    <div
      className={cs('time-code__menu', { active: !!anchorEl })}
      data-id={TIME_CODE_DATASET_ID.MEASUREMENT}
    >
      {!isActiveContainer && (
        <Menu
          keepMounted
          getContentAnchorEl={null}
          anchorEl={timeCodeContainer.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          classes={{ paper: 'time-code__dropdown-menu' }}
          open={!!anchorEl}
          onClose={onCloseMenu}
        >
          {TIME_CODE_MODE_VALUES.map(data => (
            <div
              className={cs('time-code__dropdown-menu-item', {
                hidden: !data.isVisible(fps),
              })}
              key={data.mode}
            >
              <div className={cs('time-code__dropdown-menu-checked',
                { active: data.mode === timeMode })}
              />
              <MenuItem onClick={handleTimeCodeMode(data.mode)}>{data.getValue(fps)}</MenuItem>
              <div className="time-code__dropdown-menu-title">{data.title}</div>
            </div>
          ))}
        </Menu>
      )}
    </div>
  )
}
