import React, { FC, memo } from 'react'
import classNames from 'classnames'
import { useTimeCode } from 'hooks/timecode/useTimeCode'
import { TimeCodeContainerLocationType } from 'types/timecode'
import { PlayerType } from 'types/common'
import { EMPTY_STRING } from 'constant'
import { TIME_CODE_DATASET_ID } from 'config/constants/timecode'
import { UnitTime } from './UnitTime'
import { TimeControl } from './TimeControl'
import { TimeCodeSeparator } from './TimeCodeSeparator'
import { TimeCodeContextMenu } from './TimeCodeContextMenu'
import './TimeCode.scss'

interface IProps {
  progress: number
  containerLocation: TimeCodeContainerLocationType // <Component>, example <Preview>
  onRewind?(sec: number): void
  onMoveSlider?(unitSec: number): void
  fps?: number
  previewPlayerType?: PlayerType,
  readonly?: boolean,
  isTimelineTime?: boolean
  startTime: number
  isMedia: boolean
}

export const TimeCode: FC<IProps> = memo(({
  progress,
  containerLocation,
  onRewind,
  onMoveSlider,
  fps,
  previewPlayerType,
  readonly,
  isTimelineTime,
  startTime,
  isMedia,
}) => {
  const {
    onContainerArrowsIncrementControl,
    unitMeasurementFormats,
    timeCodeContainer,
    timeCodeRefs,
    isActiveContainer,
    timeUnits,
    onChangeMode,
    timeMode,
    onChangeUnit,
    onOpenMenu,
    onCloseMenu,
    anchorEl,
    currentFps,
  } = useTimeCode({
    progress: isMedia ? progress + startTime : progress,
    containerLocation,
    onRewind,
    onMoveSlider,
    fps,
    readonly,
    isTimelineTime,
    startTime,
  })

  return (
    <div
      className={classNames('time-code', {
        'is-timeline': isTimelineTime,
      })}
    >
      <div
        className={classNames('time-code__container', {
          active: isActiveContainer,
        })}
        ref={timeCodeContainer}
        data-id={TIME_CODE_DATASET_ID.CONTAINER}
        onContextMenu={onOpenMenu}
      >
        {timeUnits.map((unit, index) => (
          <div
            className="time-code__box"
            data-id={`data-time-code-${unit.id}`}
            key={`${unit.id}-${containerLocation}-${previewPlayerType ?? EMPTY_STRING}`}
          >
            <UnitTime
              unit={unit}
              timeCodeRefs={timeCodeRefs}
              isActiveContainer={isActiveContainer}
              onChangeUnit={onChangeUnit(unit)}
              isLastUnit={index === (timeUnits.length - 1)}
            />
            <TimeCodeSeparator
              timeMode={timeMode}
              unit={unit}
              unitMeasurementFormats={unitMeasurementFormats}
            />
            <TimeCodeContextMenu
              unit={unit}
              unitMeasurementFormats={unitMeasurementFormats}
              isActiveContainer={isActiveContainer}
              timeCodeContainer={timeCodeContainer}
              onChangeMode={onChangeMode}
              onCloseMenu={onCloseMenu}
              anchorEl={anchorEl}
              timeMode={timeMode}
              fps={currentFps}
            />
          </div>
        ))}
      </div>
      <TimeControl
        onContainerArrowsIncrementControl={onContainerArrowsIncrementControl}
        isActiveContainer={isActiveContainer}
      />
    </div>
  )
})
