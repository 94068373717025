import * as Selectors from '~/selectors'
import { addSourceFileToTimePosition } from './addSourceFileToTimePosition'
import { setSourceFileTimeCodeFormat } from './setSourceFileTimeCodeFormat'

export const addSourceFileAtSliderPosition = id => (dispatch, getState) => {
  const state = getState()
  const { sliderTime } = state.timeline
  const file = Selectors.getSourceFileById(state, id)
  addSourceFileToTimePosition({ file, dispatch, getState, timePosition: sliderTime })
  setSourceFileTimeCodeFormat(file, state, dispatch)
}
