import React, { useContext } from 'react'
import { DummyHeader } from '../components/DummyHeader'
import { TranslationContext } from '~/contexts/TranslationContext'

export const HeaderContainer = () => {
  const { t } = useContext(TranslationContext)
  return (
    <DummyHeader caption={t('TEXT_HEADER_TEXT_CAPTION_TEXT')} />
  )
}
