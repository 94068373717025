import { flatMap, isArray, partition, sortBy } from 'lodash'
import { FlatMapCache, createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'
import { getWindowWidth, pixel2Time } from '~/Util'
import { onlyUnique } from '~/Util/arrays'
import { MEDIA_TYPE, SOURCE_FILE_TYPES, TRANSITIONS } from '~/enums'
import { getGreaterEndTime } from '~/helpers/getGreaterEndTime'
import { buildFlatTimeline, calcProjectDuration, layerIndexes } from '~/lib'
import Asset, { AudioAsset, ImageAsset, MediaRecordingAsset, PlaceholderAsset, TextAsset, TransitionAsset, VideoAsset } from '~/models/Asset'
import { Folder } from '~/models/Folder'
import * as layer from './layer'
import * as mainView from './mainView'
import * as recording from './recording'
import * as sourceFiles from './sourceFiles'
import { selectReference } from './select-reference'
import * as nabletHld from './nabletHld'
// ---

function isMedia(asset) {
  return (asset instanceof VideoAsset)
  || (asset instanceof AudioAsset)
}

function isNotTransition(asset) {
  return !(asset instanceof TransitionAsset)
}

function filterNotMediaRecordingAssets(assets) {
  return assets.filter(asset => !(asset instanceof MediaRecordingAsset))
}

function isOverlay(asset) {
  return (asset instanceof ImageAsset)
  || (asset instanceof TextAsset)
}

function filterMediaAssets(assets) {
  return assets.filter(isMedia)
}
function getOutputFormat(assets) {
  const fileTypes = new Set(VideoAsset.filter(assets).map(asset => asset.filetype))
  const [ firstType ] = fileTypes
  return (fileTypes.size === 1
    && (firstType === MEDIA_TYPE.MXF || firstType === MEDIA_TYPE.TS)) ? firstType : MEDIA_TYPE.MP4
}

function filterOverlayAssets(assets) {
  return assets.filter(isOverlay)
}

export function filterVideoAssets(assets) {
  return assets.filter(asset => asset instanceof VideoAsset)
}

function filterExistingAssets(assets) {
  // exclude transition with attached asset with mediaDeleted property
  // exclude assets with mediaDeleted
  return assets.filter(asset => {
    if (asset instanceof TransitionAsset) {
      const leftAsset = assets.find(a => a.id === asset.leftVideoAssetId)
      const rightAsset = assets.find(a => a.id === asset.rightAttachedAsset)
      if ((leftAsset && leftAsset.mediaDeleted)
      || (rightAsset && rightAsset.mediaDeleted)) {
        return false
      }
    }
    return !asset.mediaDeleted
  })
}

// ---

/**
 * @param {object} state
 * @param {string} type
 * @returns {SourceFilesGroup}
 */
export const getSourceFilesGroup = (state, type) => {
  const group = state.sourceFiles[type]
  if (group === undefined) {
    throw new Error(`Unknown source files type: ${type}`)
  }
  return group
}

/**
 * @param {object} state
 * @param {string} type
 * @returns {Asset[]}
 */
export const getSourceFiles = (state, type) => getSourceFilesGroup(state, type).items

/**
 * @param {object} state
 * @param {string} type
 * @returns {boolean}
 */
export const getIsSourceFilesLoading = (state, type) => getSourceFilesGroup(state, type).loading

/**
 * playingAsset is used if it missing in sourceFiles.
 * e.g. User turn on audio asset, switches category in Audio Library,
 * but asset still has to to play
 * @param {object} state
 * @param {string | null} id
 * @returns {Asset|undefined}
 */
export const getSourceFileById = (state, id) => {
  const assetInSourceFiles = flatMap(Object.values(state.sourceFiles), x => x.items)
    .find(x => x?.id === id)
  const { playingAsset } = state.playback
  return assetInSourceFiles || playingAsset
}

/**
 * @param {object} state
 * @returns {TimelineLayer[]}
 */
export const getLayers = state => state.timeline.layers

/**
 * @param {object} state
 * @returns {TimelineLayer[]}
 */
export const getVisibleLayers = state => state.timeline.layers.filter(layer => layer.visible)

/**
 * @param {object} state
 * @returns {string[]}
 */
export const getVisibleLayerIds = state => state.timeline.layers
  .filter(layer => layer.visible)
  .map(layer => layer.id)

/**
 * @param {object} state
 * @param {string} id
 * @returns {Layer|undefined}
 */
export const getLayerById = (state, id) => getLayers(state).find(x => x.id === id)

/**
 * @param {object} state
 * @param {string} id
 * @returns {number|undefined}
 */
export const getLayerIndexById = (state, id) => getLayers(state).findIndex(x => x.id === id)

/**
 * @param {object} state
 * @returns {Asset[]}
 */
export const getAssets = createSelector(
  state => state.layer,
  layer => layer.assets.slice().sort((a, b) => a.startTime - b.startTime)
)

export const getMediaAssets = createSelector(
  [ getAssets ],
  assets => filterMediaAssets(assets)
)

export const getExistingAssets = createSelector(
  [ getAssets ],
  assets => filterExistingAssets(assets)
)

export const getPrefferedOutputFormat = createSelector(
  [ getAssets ],
  assets => getOutputFormat(assets)
)

export const selectAssetById = (state, assetId) => {
  if (isArray(assetId)) {
    return getAssets(state).filter(a => assetId.includes(a.id))
  }
  return getAssets(state).find(a => a.id === assetId)
}

export const getSelectedAsset = state => getAssets(state)
  .find(a => a.selected && !(a instanceof PlaceholderAsset))

export const getSelectedAssets = createSelector(
  [ getAssets ],
  assets => assets.filter(a => a.selected && !(a instanceof PlaceholderAsset))
)

export const getSelectedAssetsByIds = (state, ids) => getAssets(state)
  .filter(a => ids?.includes(a.id) ?? [])

export const getDnDPatchAssets = state => state.layer.dndPatchAssets

export const selectCurrentSplitAsset = createSelector(
  [ getAssets, state => state.timeline.sliderTime ],
  (assets, sliderTime) => assets.find(item => sliderTime > item.startTime
    && sliderTime < item.endTime
    && item.selected
    // ignore placeholder asset
    && !(item instanceof PlaceholderAsset))
)

export const selectCurrentSplitAssets = createSelector(
  [ getAssets, state => state.timeline.sliderTime ],
  (assets, sliderTime) => assets.filter(item => sliderTime > item.startTime
    && sliderTime < item.endTime
    && item.selected
    // ignore placeholder asset
    && !(item instanceof PlaceholderAsset))
)

export const selectCurrentSplittedParts = createSelector(
  [ getAssets, state => state.timeline.sliderTime ],
  (assets, sliderTime) => {
    const splittedParts = assets.filter(item => (sliderTime === item.endTime
    || sliderTime === item.startTime)
    && item.selected
    // ignore placeholder asset
    && !(item instanceof PlaceholderAsset))

    return splittedParts.length === 2 ? splittedParts.sort((a, b) => a.startTime - b.startTime)
      : undefined
  }
)

/*
 * Using regular `createSelector` here doesn't make any sense,
 * because cache will be dropped every time layerId changes.
 * It can be "smart"-cached with `createCachedSelector` –
 * but then we'll need to manually remove old caches when layers are removed, to don't keep them forever.
 * Probably it's not worth that.
 */
export const getLayerAssets = (state, layerId) => Asset.getLayerAssets(getAssets(state), layerId)

/**
 * It's important that layers here are sorted by their order in timeline.
 * It's not the same as just get all assets and group them by `layerId` field.
 *
 * @param {RootState} state
 * @return {Map<number, Asset[]>}
 */
export const getAssetsByLayers = createSelector(
  [ getAssets, getLayers ],
  (assets, layers) => layers.reduce(
    (m, layer) => {
      m.set(layer.id, Asset.getLayerAssets(assets, layer.id))
      return m
    },
    new Map()
  )
)

/**
 * It's important that layers here are sorted by their order in timeline.
 * It's not the same as just get all assets and group them by `layerId` field.
 *
 * @param {RootState} state
 * @return {Map<number, Asset[]>}
 */
export const getAssetsByVisibleLayers = createSelector(
  [ getAssets, getVisibleLayers ],
  (assets, layers) => layers.reduce(
    (m, layer) => {
      m.set(layer.id, Asset.getLayerAssets(assets, layer.id))
      return m
    },
    new Map()
  )
)

/**
 * @type {(state: RootState) => TransitionAsset[]}
 */
export const getTransitionAssets = createSelector(
  [ getAssets ],
  assets => TransitionAsset.filter(assets)
)

export const getPlaceholderAssets = createSelector(
  [ getAssets ],
  assets => PlaceholderAsset.filter(assets)
)

export const getTransitionsOfMovedAsset = (state, assetId) => partition(
  TransitionAsset.filter(state.assets).filter(tr => tr.isAttachedTo(assetId)),
  x => !x.isTwoSideTransition
)

export const getTransitionOfAssetId = (state, assetId) => TransitionAsset
  .filter(state.assets).find(tr => tr.isAttachedTo(assetId))

export const getNonRecordingAssets = createSelector([ getAssets ], filterNotMediaRecordingAssets)

export const selectLatestEndTime = createSelector([ getNonRecordingAssets ], getGreaterEndTime)

export const getRightSideAssetsBySliderTimeAndLayer = layerId => createSelector([
  getLayerAssets,
  state => state.timeline.sliderTime ],
(assets, sliderTime) => {
  const filteredAssets = assets.filter(
    asset => (asset.startTime - sliderTime >= 0) && (layerId === asset.layerId)
  )
  return sortBy(filteredAssets, x => x.startTime)
})

export const getRightSideAssetsBySliderTimeAndLayerByEndTime = createSelector([
  getLayerAssets,
  state => state.timeline.sliderTime ],
(assets, sliderTime) => {
  const filteredAssets = assets.filter(asset => asset.endTime - sliderTime >= 0)
  return sortBy(filteredAssets, x => x.startTime)
})

export const getRightSideAssetsByTimeAndLayer = (state, layerId, time) => {
  const layerAssets = Asset.getLayerAssets(state.assets, layerId)
  const rightSideItems = layerAssets.filter(el => el.startTime >= time)

  return sortBy(rightSideItems, x => x.startTime)
}

/**
 * @param {object} state
 * @param {string} id
 * @param {boolean} named
 * @returns {Asset[]}
 */
export const getTransitionsByAssetId = (state, id, { named = false } = {}) => {
  const transitions = getTransitionAssets(state)
  const left = transitions.find(tr => tr.isAttachedTo(id, 'right'))
  const right = transitions.find(tr => tr.isAttachedTo(id, 'left'))
  if (named) {
    return { left, right }
  }
  return [ left, right ].filter(x => x !== undefined)
}

/**
 * @param {object} state
 * @param {string} assetId
 * @returns {Asset[]}
 */
export const getRightSideAssets = (state, assetId) => {
  const asset = state.assets.find(el => el.id === assetId)
  if (!asset) return []
  const layerAssets = Asset.getLayerAssets(state.assets, asset.layerId)
  const rightSideItems = layerAssets.filter(el => +el.startTime.toFixed(2)
    > +asset.startTime.toFixed(2)
    && el.id !== asset.id)

  return sortBy(rightSideItems, x => x.startTime)
}

/**
 * Get right assets on the same layer only except fadeout transition
 *
 * @param {object} state
 * @param {string} id
 * @returns {Asset[]}
 */
export const getRightSideAssetsExceptFadeoutTransitions = (state, asset) => getRightSideAssets(
  state,
  asset.id
).filter(a => !((a.type === TRANSITIONS.FADEOUT && a.leftVideoAssetId === asset.id)
  || (a.type === TRANSITIONS.FADEIN && a.rightVideoAssetId === asset.id)))

/**
 * @param {object} state
 * @returns {number[]}
 */
export const selectPlayingAssetIds = createSelector(
  [ state => state.timeline.playingAssetIds ],
  playingAssetIds => playingAssetIds
)

/**
 * @param {object} state
 * @returns {number[]}
 */
export const selectBackgroundAssetIds = createSelector(
  [ state => state.timeline.backgroundAssetIds ],
  backgroundAssetIds => backgroundAssetIds
)

/**
 * @param {object} state
 * @returns {number[]}
 */
export const selectComposedAssetIds = createSelector(
  [ state => state.timeline.composedAssetIds ],
  composedAssetIds => composedAssetIds
)

/**
 * @param {object} state
 * @returns {number[]}
 */
export const selectTransitionAssetIds = createSelector(
  [ state => state.timeline.transitionAssetIds ],
  transitionAssetIds => transitionAssetIds
)

/**
 * @param {object} state
 * @returns {Asset[]}
 */
export const selectBackgroundAssets = createSelector(
  [ getAssets,
    selectBackgroundAssetIds ],
  (allAssets, backgroundAssetIds) => backgroundAssetIds
    .map(id => allAssets.find(a => a.id === id))
    .filter(a => !!a)
)

/**
 * @param {object} state
 * @returns {Asset[]}
 */
export const selectComposedAssets = createSelector(
  [ getAssets,
    selectComposedAssetIds ],
  (allAssets, composedAssetIds) => composedAssetIds
    .map(id => allAssets.find(a => a.id === id))
    .filter(a => !!a)
)

/**
 * @param {object} state
 * @returns {Asset[]}
 */
export const selectExistingTransitionAssets = createSelector(
  [ getExistingAssets,
    selectTransitionAssetIds ],
  (allAssets, transitionAssetIds) => transitionAssetIds
    .map(id => allAssets.find(a => a.id === id))
    .filter(a => !!a)
)

/**
 * @param {object} state
 * @param {typeof Asset} [klass]
 * @returns {Asset[]}
 */
export const getPlayingAssets = createCachedSelector(
  [
    getExistingAssets,
    selectPlayingAssetIds,
    (_, klass) => klass,
  ],
  (allAssets, playingAssetIds, klass) => {
    if (klass !== undefined && !(klass.prototype instanceof Asset)) {
      throw new Error(`'klass' parameter must be a descendant of ${Asset.name} class`)
    }

    const assets = playingAssetIds.map(id => allAssets.find(a => a.id === id))
      .filter(a => !!a)

    return klass === undefined ? assets : assets.filter(asset => asset instanceof klass)
  }
)({
  keySelector: (_, klass) => klass,
  cacheObject: new FlatMapCache(),
})

/**
 * @param {object} state
 * @return {(Assets.VideoAsset|Assets.AudioAsset)[]}
 */
export const getPlayingMediaAssets = createSelector(
  [ getPlayingAssets ],
  assets => filterMediaAssets(assets)
)

/**
 * @param {object} state
 * @return {(Assets.VideoAsset|Assets.AudioAsset)[]}
 */
export const selectComposedMediaAssets = createSelector(
  [ selectComposedAssets ],
  assets => filterMediaAssets(assets)
)

export const selectExistingOverlayAssets = createSelector(
  [ getExistingAssets ],
  assets => filterOverlayAssets(assets)
)

function filterByTransitionAttachedAssets(allAssets, transitions, filter) {
  return transitions.filter(transition => {
    const leftAttachedAsset = allAssets.find(a => a.id === transition.leftVideoAssetId)
    const rightAttachedAsset = allAssets.find(a => a.id === transition.rightVideoAssetId)
    return (!leftAttachedAsset || filter(leftAttachedAsset))
      && (!rightAttachedAsset || filter(rightAttachedAsset))
  })
}

/**
 * @param {object} state
 * @return {Assets.TransitionAsset[]}
 */
export const selectExistingMediaTransitionAssets = createSelector(
  [ getAssets, selectExistingTransitionAssets ],
  (allAssets, transitions) => filterByTransitionAttachedAssets(
    allAssets, transitions, isNotTransition
  )
)

/**
 * @param {object} state
 * @return {Assets.TransitionAsset[]}
 */
export const selectExistingOverlayTransitionAssets = createSelector(
  [ getAssets, selectExistingTransitionAssets ],
  (allAssets, transitions) => filterByTransitionAttachedAssets(
    allAssets, transitions, isNotTransition
  )
)

/**
 * @param {object} state
 * @return {(Assets.ImageAsset|Assets.TextAsset)[]}
 */
export const getPlayingOverlayAssets = createSelector(
  [ getPlayingAssets ],
  assets => filterOverlayAssets(assets)
)

/**
 * @param {object} state
 * @return {Assets.VideoAsset[]}
 */
export const getPlayingVideoAssets = createSelector(
  [ getPlayingAssets ],
  assets => filterVideoAssets(assets)
)

/**
 * @param {object} state
 * @return {Assets.VideoAsset}
 */
export const selectCurrentPreviewAsset = createSelector(
  [ getPlayingMediaAssets ],
  currentPreviewAssets => currentPreviewAssets[0]
)

/**
 * @param {object} state
 * @return {Assets.VideoAsset}
 */
export const getFirstPlayingVideoAsset = createSelector(
  [ getPlayingAssets ],
  assets => assets.find(a => a instanceof VideoAsset)
)

export const selectPreviewParams = createSelector([ state => state.preview ],
  preview => preview)


export const getReferenceVideoAsset = selectReference

export const getBuildFlatTimelineData = createSelector(
  [ getExistingAssets, getLayers, selectPreviewParams, getReferenceVideoAsset ],
  (existAssets, layers, previewParams, refAsset) => ({
    existAssets, layers, previewParams, refAsset,
  })
)

/**
 * @param {object} state
 * @returns {TimelineAssetGroups}
 */
export const getFlatTimeline = createSelector(
  [ getBuildFlatTimelineData ],
  data => {
    const { existAssets, layers, previewParams, refAsset } = data
    return buildFlatTimeline(existAssets, layers, previewParams, refAsset)
  }
)


/**
 * @param {object} state
 * @returns {VideoAsset[]}
 */
export const selectFlattenedVideoAssets = createSelector(
  [ getExistingAssets, getFlatTimeline ],
  // map flattened-modified assets to normal assets (with actual startTime at timeline)
  (allAssets, flattedGroups) => flattedGroups.video.map(
    asset => allAssets.find(a => a.id === asset.id)
  )
)

/**
 * @param {object} state
 * @returns {number}
 */
export const getProjectDuration = createSelector(
  [ getAssets ],
  assets => calcProjectDuration(assets)
)

/**
 * @param {object} state
 * @returns {Map<string, number>}
 */
export const getLayersIndexes = createSelector(
  [ state => state.timeline.layers ],
  layers => layerIndexes(layers)
)

// @link http://18.184.210.86/issues/347#note-8
export const canAddVideoAssets = createSelector(
  [ getAssets ],
  assets => (
    __CFG__.MAX_VIDEO_ASSETS_COUNT === -1
    || VideoAsset.filter(assets).length < __CFG__.MAX_VIDEO_ASSETS_COUNT
  )
)

/**
 * @param {object} state
 * @return {number}
 */
export const getTimeline = createSelector(
  [ state => state.timeline ],
  timeline => timeline
)

/**
 * @return {boolean}
 */
export const getIsMovingSlider = createSelector(
  [ getTimeline ],
  timeline => timeline.isMovingSlider
)

/**
 * @param {object} state
 * @param {string} type
 * @returns {boolean}
 */
export const selectIsSourceFilesLoaded = state => Object.values(SOURCE_FILE_TYPES)
  .filter(t => t !== SOURCE_FILE_TYPES.DESIGN && t !== SOURCE_FILE_TYPES.FILTERS)
  .some(type => getSourceFilesGroup(state, type).loaded)

/**
* @return {number}
*/
export const selectTimelineScale = createSelector(
  [ state => state.timeline ],
  timeline => timeline.scale
)

/**
* @return {number}
*/
export const selectTimelineScrollLeft = createSelector(
  [ state => state.timeline ],
  timeline => timeline.scrollLeft
)

/**
* @return {number}
*/
export const selectPlayerVolume = createSelector(
  [ state => state.mainView ],
  mainView => mainView.playerVolume
)

/**
* @return {string}
*/
export const selectDraggingAssetId = createSelector(
  [ state => state.timeline ],
  timeline => timeline.draggingAssetId
)

export const selectHoveringInsertedLayerIds = createSelector(
  [ state => state.timeline ],
  timeline => timeline.hoveringInsertedLayerIds
)

export const selectSliderOverlayAssets = createSelector(
  state => getPlayingAssets(state, TextAsset),
  state => getPlayingAssets(state, ImageAsset),
  getLayersIndexes, (textAssets, imageAssets, layersIndexes) => textAssets
    .concat(imageAssets).sort(
      (a, b) => layersIndexes.get(b.layerId) - layersIndexes.get(a.layerId)
    )
)

// NOTE: show all text and images assets to generate base64 images for rendering
export const selectAllExistingOverlayAssets = createSelector(
  getExistingAssets, assets => assets
    .filter(asset => asset instanceof TextAsset || asset instanceof ImageAsset)
)

export const selectIsViewLoading = createSelector([ state => state.mainView ],
  mainView => mainView.isLoading)

export const selectIsOffline = createSelector([ state => state.mainView ],
  mainView => mainView.isOffline)

/**
 * @param {object} state
 * @returns {boolean}
*/
export const selectScrollStartTime = createSelector([
  selectTimelineScrollLeft,
  selectTimelineScale,
], (scrollLeft, scale) => pixel2Time(scrollLeft, scale))

/**
 * @param {object} state
 * @returns {boolean}
*/
export const selectScrollEndTime = createSelector([
  selectTimelineScrollLeft,
  selectTimelineScale,
], (scrollLeft, scale) => pixel2Time(scrollLeft + getWindowWidth(), scale))

export const selectImages = createSelector([ state => state.text.images ], imagesSet => imagesSet)

/**
 * @param {object} state
 * @returns {string[]}
 */
export const selectUniqueTimelineFileExtenstions = createSelector(
  [ getAssets ],
  assets => assets.map(({ filetype }) => filetype).filter(onlyUnique)
)

export const selectDraggingLayerId = createSelector(
  state => state.timeline, timeline => timeline.draggingLayerId
)

/**
 * @typedef {object} SourceFilesRequestParameters
 * @property {number } [start]
 * @property {number} [count]
 * @property {string} [filter]
 * @property {string} [folder]
 * @property {string} [sort]
 * @property {string} [search]
 * @property {string} [order]
*/

/**
 * @param {RootState} state
 * @param {string} type
 * @returns {SourceFilesRequestParameters}
 */
export const selectSourceFilesParams = (state, type) => getSourceFilesGroup(state, type).params

export const selectSourceFilesUiParams = (state, type) => getSourceFilesGroup(state, type).uiParams

export const selectSourceFilesNavHistory = (state, type) => (
  getSourceFilesGroup(state, type).directoryPathHistory
)

export const selectSourceFilesNavHistoryIndex = (state, type) => (
  getSourceFilesGroup(state, type).directoryPathCurrentIndex
)

export const selectSourceFilesPath = (state, type) => {
  const group = getSourceFilesGroup(state, type)
  return group.directoryPathHistory[group.directoryPathCurrentIndex] || []
}

export const selectCurrentFolderId = (state, type) => {
  const currentPath = selectSourceFilesPath(state, type)
  return currentPath[currentPath.length - 1]?.id
}

export const selectImportFolderId = (state, type) => {
  const currentFolderId = selectCurrentFolderId(state, type)
  return state.user.importsDefaultFolderId || currentFolderId
}

export const selectSourceFilesPathArrowsStatus = (state, type) => {
  const group = getSourceFilesGroup(state, type)
  const { directoryPathCurrentIndex, directoryPathHistory } = group
  const isForwardDisabled = directoryPathCurrentIndex === directoryPathHistory.length - 1
  const isBackwardDisabled = directoryPathCurrentIndex === 0
  return { isForwardDisabled, isBackwardDisabled }
}

export const selectAreThereFolders = (state, { type, selectedItems }) => {
  const group = getSourceFilesGroup(state, type)
  return group.items.find(item => item instanceof Folder && !selectedItems.includes(item.id))
}

export const selectTotalCount = (state, type) => getSourceFilesGroup(state, type).totalCount

export const selectSelectedClipIsBuffering = (state, assetId) => {
  const { selectedClipId, isBuffering } = state.playback
  return selectedClipId === assetId ? isBuffering : false
}

export const selectIsSliderTimeIntoTransition = createSelector(
  [ state => state.layer, state => state.timeline ],
  (layer, timeline) => {
    const { sliderTime } = timeline
    const transitions = TransitionAsset.filter(layer.assets)
    return !!transitions.filter(t => t.startTime <= sliderTime && t.endTime >= sliderTime).length
  }
)

export const isSliderIntersectSelectedAssets = createSelector(
  [ state => state.layer, state => state.timeline ],
  (layer, timeline) => {
    const { sliderTime } = timeline
    const videoAssets = layer.assets.filter(asset => !(asset instanceof TransitionAsset)
    || !(asset instanceof PlaceholderAsset))
    return !!videoAssets.find(a => a.startTime <= sliderTime && a.endTime >= sliderTime)
  }
)

export const selectSliderTime = createSelector(
  [ state => state.timeline.sliderTime ],
  sliderTime => sliderTime
)

export const selectTimelineDuration = createSelector(
  [ state => state.timeline.duration ],
  duration => duration
)

export { layer, mainView, recording, sourceFiles, nabletHld }
