import React, { FC, memo } from 'react'
import { Box } from '@material-ui/core'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'

interface IProps {
  onClose():void
  t(str: string): string
}

export const Header: FC<IProps> = memo(({ onClose, t }) => (
  <Box className="editor-settings__head" paddingBottom="5px">
    <div className="editor-settings__close" onClick={onClose}>
      <CloseIcon />
    </div>
    <div className="editor-settings__head-title">{t('EDITOR_MENU_MAIN_SETTINGS')}</div>
  </Box>
))
