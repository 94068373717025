import React, { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectEditorSettings } from 'selectors/mainView'
import { Box, Checkbox, FormControlLabel } from '@material-ui/core'
import { FormContext } from 'contexts/FormContext'
import { BehaviourSettingsEnum } from 'config/constants/editorSettings'
import { EDITOR_MAIN_SETTINGS_MENU_ITEMS } from 'config/constants/mainView'
import { TranslationContext } from 'contexts/TranslationContext'
import { EditorSettingsChapterType } from '../types/main'

export const BehaviourSettings = memo(() => {
  const { handleInput } = useContext(FormContext)
  const settings = useSelector(selectEditorSettings) as EditorSettingsChapterType
  const { BEHAVIOUR_SETTINGS } = EDITOR_MAIN_SETTINGS_MENU_ITEMS
  const { t } = useContext(TranslationContext)

  return (
    <Box width="100%">
      <form className="behaviour-settings">
        <FormControlLabel
          control={(
            <Checkbox
              onChange={handleInput}
              color="primary"
              name={BehaviourSettingsEnum.setTimecodeFormatBasedOnMedia}
              defaultChecked={Boolean(settings[
                BEHAVIOUR_SETTINGS
              ]?.[BehaviourSettingsEnum.setTimecodeFormatBasedOnMedia])}
            />
          )}
          label={t('BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA')}
        />
      </form>
    </Box>
  )
})
