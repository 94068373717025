import React, { useRef } from 'react'
import { LEFT_MENU_WIDTH, MEDIA_BLOCK_MIN_WIDTH, MEDIA_BLOCK_MIN_WIDTH_OPTION, MEDIA_RECORDING_BLOCK_MIN_WIDTH, TIMELINE_MIN_WIDTH } from 'constant'
import SplitPane from 'react-split-pane'
import { SPLIT_RESIZER } from 'types/common'
import { useSelector } from 'react-redux'
import { selectMediaRecordingSettingsState } from 'selectors/recording'
import { selectElementDisplayType, selectSplitSize } from 'selectors/useInterface'
import WebEditorMenu from 'components/Menu/Menu'
import { useEditorSplitSize } from 'hooks/editor/useEditorSplitSize'
import { useAction } from 'hooks'
import { setResizing } from 'actions/userInterface'
import Timeline from 'components/Timeline/Timeline'
import AmazonImport from 'components/AmazonImport/AmazonImport'
import { DISPLAY_TYPE } from 'config/constants/ui'
import { getWindowWidth } from '~/Util'
import { MenuPanel } from './MenuPanel'
import { MainWrapper } from '../wrappers/MainWrapper'
import { PreviewPlayersContainer } from './PreviewPlayersContainer'

const EmptyComponent = () => <></>

export const MainContainer = () => {
  const mediaRecordingOpened = useSelector(selectMediaRecordingSettingsState)
  let { media: mediaColumnSize } = useSelector(selectSplitSize)
  const { menuItem } = useSelector(state => state.mainView)
  const isShowAmazonImport = useSelector(state => state.mainView.showAmazonImport)
  const isShowSaveSettings = useSelector(state => state.mainView.showSaveSettings)
  const isShowAssetSettings = useSelector(state => state.mainView.showAssetSettings)
  const {
    timeline: timelineDisplayType,
    sourceList: sourceListDisplayType,
  } = useSelector(selectElementDisplayType)

  const previewRef = useRef(null)
  const windowWidth = getWindowWidth()
  const { onChangeSplitVertical } = useEditorSplitSize({ previewRef, windowWidth })
  const onSetResizing = useAction(setResizing)

  const smallLeftSide = !(isShowSaveSettings || isShowAssetSettings || menuItem)
  let mediaRecordingBlock = MEDIA_RECORDING_BLOCK_MIN_WIDTH
  let mediaBlockMinWidth = MEDIA_BLOCK_MIN_WIDTH

  if (sourceListDisplayType !== DISPLAY_TYPE.MIN_WIDTH) {
    mediaBlockMinWidth = MEDIA_BLOCK_MIN_WIDTH_OPTION
    mediaRecordingBlock = MEDIA_BLOCK_MIN_WIDTH_OPTION
    if (mediaColumnSize && (mediaColumnSize <= mediaBlockMinWidth)) {
      mediaColumnSize = mediaBlockMinWidth + LEFT_MENU_WIDTH
    }
  }

  return (
    <MainWrapper timelineDisplayType={timelineDisplayType}>
      <SplitPane
        split="vertical"
        pane2Style={{ minWidth: TIMELINE_MIN_WIDTH }}
        size={mediaRecordingOpened
          && (mediaColumnSize <= mediaRecordingBlock + LEFT_MENU_WIDTH)
          ? mediaRecordingBlock + LEFT_MENU_WIDTH
          : mediaColumnSize || mediaBlockMinWidth + LEFT_MENU_WIDTH}
        defaultSize={smallLeftSide
          ? LEFT_MENU_WIDTH
          : mediaBlockMinWidth + LEFT_MENU_WIDTH}
        minSize={smallLeftSide
          ? LEFT_MENU_WIDTH
          : mediaBlockMinWidth + LEFT_MENU_WIDTH}
        maxSize={windowWidth - TIMELINE_MIN_WIDTH}
        step={1}
        onChange={onChangeSplitVertical}
        onDragStarted={() => onSetResizing(true, SPLIT_RESIZER.MAIN)}
        onDragFinished={() => onSetResizing(false)}
      >
        <Choose>
          <When condition={isShowAmazonImport}>
            <AmazonImport />
          </When>
          <Otherwise>
            <SplitPane split="vertical" minSize={LEFT_MENU_WIDTH} maxSize={LEFT_MENU_WIDTH} resizerStyle={{ display: 'none' }}>
              <WebEditorMenu />
              <MenuPanel />
            </SplitPane>
          </Otherwise>
        </Choose>
        <PreviewPlayersContainer
          PreviewPlayersBottomComponent={timelineDisplayType === DISPLAY_TYPE.FULL_WIDTH
            ? EmptyComponent
            : Timeline}
          onSetResizing={onSetResizing}
          timelineDisplayType={timelineDisplayType}
          previewRef={previewRef}
        />
      </SplitPane>
    </MainWrapper>
  )
}
