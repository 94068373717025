import { DEFAULT_IMAGE_DURATION } from 'constant'
import { PLAYBACK_STATE } from 'enums'
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getFPSByRefVideo,
  RangeTools,
  secondsToTimelineTime,
  timelineTimeToSeconds
} from 'Util'
import * as Assets from 'models/Asset'
import { getReferenceVideoAsset, selectAssetById } from 'selectors'
import { isNumber, noop } from 'lodash'
import {
  selectInOutPointsTimeLineAssetID,
  selectorActivePreview,
  selectorPreviewClipCreator,
  selectSourcePlayerProgress
} from 'selectors/preview'
import { useAction } from 'hooks/utils'
import { setSourcePlayerProgress } from 'actions/preview'
import { getFrameStep } from 'helpers/getFrameStep'
import { selectMemoSliderTime } from 'selectors/playback'
import { setMemoSliderTime, setTimelineSegmentPlaybackState } from 'actions/playback'
import { PlayerType } from 'types/common'
import { TIME_CODE_MODE } from 'config/constants/timecode'
import Timecode, { FRAMERATE } from 'smpte-timecode'
import { getObjectTimeCode, isDropFrameTimeCode } from 'Util/timecode'

const FAKE_PROGRESS_INTERVAL = 5
const PROGRESS_INCREMENT = 100000

type UsePreviewSliderProgressType = {
  isMedia: boolean,
  mediaAsset: Assets.MediaAsset,
  onPlayPause(e: MouseEvent): void,
  onRewindTimeline(sec: number): void
  previewDration: number
  progress: number,
  fps: number,
  onPrevFrame():void,
  onNextFrame():void,
  preview: PlayerType,
}

export const usePreviewSliderProgress = ({
  isMedia,
  onPlayPause,
  mediaAsset,
  onRewindTimeline,
  previewDration,
  progress,
  fps,
  onPrevFrame,
  onNextFrame,
  preview,
}: UsePreviewSliderProgressType) => {
  // fakeProgress - media player progress (slider progress) for ImageAsset || TextAsset
  const [ fakeProgress, setFakeProgress ] = useState(0)
  const isProcessing = useRef(false)
  const progressInterval = useRef<NodeJS.Timeout | null>(null)
  const mediaPlaybackState = useSelector((state: RootState) => state.playback.clipPlaybackState)
  const refVideo = useSelector(getReferenceVideoAsset)
  const sourcePlayerProgress = useSelector(selectSourcePlayerProgress)
  const inOutPointsTimeLineAssetId = useSelector(selectInOutPointsTimeLineAssetID)
  const inOutPointsTimeLineAsset = useSelector(
    state => selectAssetById(state, inOutPointsTimeLineAssetId)
  ) as Assets.MediaAsset
  const { timeline: memoTimeline, media: memoMedia } = useSelector(selectMemoSliderTime)
  const { timeline, media } = useSelector(selectorPreviewClipCreator)
  const activePreview = useSelector(selectorActivePreview)

  const { markIn, markOut } = isMedia ? media : timeline
  const memoSliderTime = isMedia ? memoMedia : memoTimeline

  const setMediaPlayerProgress = useAction(setSourcePlayerProgress)
  const onSetMemoSliderTime = useAction(setMemoSliderTime)
  const onSetTimelineSegmentPlaybackState = useAction(setTimelineSegmentPlaybackState)

  const asset = inOutPointsTimeLineAsset ?? mediaAsset
  const isStaticAsset = asset instanceof Assets.ImageAsset || asset instanceof Assets.TextAsset
  const currentFps = fps || getFPSByRefVideo(refVideo)
  const isDF = isDropFrameTimeCode(TIME_CODE_MODE.FRAME_DF, currentFps)
  const timeCode = new Timecode(
    asset?.startTimeCodeFrames || 0, currentFps as FRAMERATE, isDF && Boolean(asset?.dfFlag)
  )

  const clipDuration = isMedia && isStaticAsset
    ? timelineTimeToSeconds(asset?.mediaFileDuration ?? DEFAULT_IMAGE_DURATION)
    : previewDration
  const previewProgress = isMedia && isStaticAsset
    ? timelineTimeToSeconds(fakeProgress)
    : progress


  const onChangeFakeProgress = useCallback((sec: number) => {
    setFakeProgress(secondsToTimelineTime(sec))
    setMediaPlayerProgress(sec)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setFakeProgress, fakeProgress ])

  const onStopFakeProgress = useCallback((e: MouseEvent) => {
    if (isMedia && progressInterval.current) {
      clearInterval(progressInterval.current)
      progressInterval.current = null
      onPlayPause(e)
      setMediaPlayerProgress(timelineTimeToSeconds(fakeProgress))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onPlayPause, progressInterval, fakeProgress ])

  const onStartFakeProgress = useCallback((e: MouseEvent) => {
    if (mediaPlaybackState !== PLAYBACK_STATE.PLAY) {
      onPlayPause(e)
    }
    if (!progressInterval.current) {
      const duration = secondsToTimelineTime(clipDuration)
      progressInterval.current = setInterval(() => {
        setFakeProgress(prev => {
          if (
            progressInterval.current
            && !isProcessing.current
            && duration === prev
          ) {
            isProcessing.current = true
            return 0
          }
          if (duration > prev) {
            isProcessing.current = true
            return (prev + PROGRESS_INCREMENT)
          }
          if (progressInterval.current) {
            isProcessing.current = false
            onStopFakeProgress(e)
            setMediaPlayerProgress(timelineTimeToSeconds(prev))
          }
          return prev
        })
      }, FAKE_PROGRESS_INTERVAL)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clipDuration, onPlayPause, onStopFakeProgress, setFakeProgress, progressInterval, isProcessing, mediaPlaybackState,
  ])

  const onPlayPauseFakeProgress = useCallback((e: MouseEvent) => {
    if (isMedia) {
      mediaPlaybackState !== PLAYBACK_STATE.PLAY
        ? onStartFakeProgress(e)
        : onStopFakeProgress(e)
    }
  }, [ mediaPlaybackState, onStartFakeProgress, onStopFakeProgress, isMedia ])

  const onRewind = useCallback((sec: number, e) => {
    e?.preventDefault()
    if (isStaticAsset && isMedia) {
      onChangeFakeProgress(sec)
    } else {
      onRewindTimeline(sec)
    }
    if (isNumber(memoSliderTime) && (sec < (markIn ?? 0) || sec > (markOut ?? 0))) {
      onSetMemoSliderTime(null, activePreview)
      if (!isMedia) {
        onSetTimelineSegmentPlaybackState({ status: PLAYBACK_STATE.STOP })
      }
    }
  }, [
    onRewindTimeline,
    isStaticAsset,
    onChangeFakeProgress,
    isMedia,
    memoSliderTime,
    activePreview,
    markIn,
    markOut,
    onSetMemoSliderTime,
    onSetTimelineSegmentPlaybackState,
  ])

  const onTogglePlayPause = useCallback((e: MouseEvent) => {
    if (isStaticAsset) {
      isMedia ? onPlayPauseFakeProgress(e) : onPlayPause(e)
    } else {
      onPlayPause(e)
    }
  }, [ onPlayPauseFakeProgress, onPlayPause, isStaticAsset, isMedia ])

  const handlePrevFrame = () => {
    if (isMedia && isStaticAsset) {
      const { frameCount, dropFrame } = getObjectTimeCode(previewProgress, fps, TIME_CODE_MODE.FRAME_NDF)
      const stepSec = getFrameStep({ frameCount, fps, isDF: dropFrame, negative: true })
      const time = RangeTools.fitTo([ 0, clipDuration ], stepSec)
      setFakeProgress(secondsToTimelineTime(time))
    }
    onPrevFrame()
  }

  const handleNextFrame = () => {
    if (isMedia && isStaticAsset) {
      const { frameCount, dropFrame } = getObjectTimeCode(previewProgress, fps, TIME_CODE_MODE.FRAME_NDF)
      const stepSec = getFrameStep({ frameCount, fps, isDF: dropFrame, negative: false })
      const time = RangeTools.fitTo([ 0, clipDuration ], stepSec)
      setFakeProgress(secondsToTimelineTime(time))
    }
    onNextFrame()
  }


  useEffect(() => {
    if (isMedia && isStaticAsset && mediaPlaybackState === PLAYBACK_STATE.PLAY && !progressInterval.current) {
      if (sourcePlayerProgress === 0) setFakeProgress(0)
      onStartFakeProgress({ preventDefault: noop } as MouseEvent)
    }
    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current)
        progressInterval.current = null
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ asset?.id, isStaticAsset, mediaPlaybackState, sourcePlayerProgress, progressInterval ])

  return {
    onPlayPauseFakeProgress,
    onChangeFakeProgress,
    onRewind,
    onTogglePlayPause,
    clipDuration,
    previewProgress,
    currentFps,
    handlePrevFrame,
    handleNextFrame,
    startTime: timeCode.frameCount / currentFps,
  }
}
