export const UI_ELEMENT = {
  TIMELINE: 'timeline',
  SOURCE_LIST: 'sourceList',
} as const

export const DISPLAY_TYPE = {
  FULL_WIDTH: 'full-width',
  HALF_WIDTH: 'half-width',
  MIN_WIDTH: 'min-width',
} as const

export const DROP_CONTAINER = {
  HEADER_MENU: 'HEADER_MENU',
  LEFT_MENU: 'LEFT_MENU',
  FOOTER: 'FOOTER',
  TIMELINE_ON_RIGHT: 'TIMELINE_ON_RIGHT',
} as const
