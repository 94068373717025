import { useCallback, useRef } from 'react'
import { ContextStateType } from 'contexts/ProjectContext'
import { getWindowHeight } from 'Util'

type UseProjectContextMenu = {
    handleContextMenu(e: MouseEvent | KeyboardEvent | null, data?: ContextStateType): void
}

export const useProjectContextMenu = ({ handleContextMenu }: UseProjectContextMenu) => {
  const refProject = useRef<HTMLDivElement | null>(null)
  const menuWidth = 80
  const menuHeight = 120

  const openContextMenu = useCallback((e: MouseEvent, projectId: string) => {
    e.preventDefault()
    e.stopPropagation()
    if (refProject.current) {
      const { top, left, right } = refProject.current.getBoundingClientRect()
      const windowHeight = getWindowHeight()

      let contextMenuTop = e.clientY - top
      let contextMenuLeft = e.clientX - left

      if ((contextMenuLeft - (menuWidth / 2)) < 0) {
        contextMenuLeft = 0
      } else if ((contextMenuLeft + (menuWidth / 2)) > right) {
        contextMenuLeft = right - menuWidth - left
      } else {
        contextMenuLeft -= (menuWidth / 2)
      }

      if (contextMenuTop < 0) {
        contextMenuTop = 0
      } else if ((e.clientY + menuHeight) > windowHeight) {
        contextMenuTop -= menuHeight
      }
      handleContextMenu(e, {
        projectId,
        visible: true,
        top: contextMenuTop,
        left: contextMenuLeft,
      })
    }
  }, [ handleContextMenu ])

  return { openContextMenu, refProject }
}
