import { SearchInput } from 'components/base/SearchInput/SearchInput'
import React, { useCallback, useContext, useMemo } from 'react'

import { setSourceFilesParams as setSourceFilesParamsAction } from 'actions/sourceFiles'
import { useSelector } from 'react-redux'

import classNames from 'classnames'
import { DropdownMenu } from 'components/base/DropdownMenu/DropdownMenu'
import Input from 'components/base/Input'
import { IMAGES_SOURCES, SOURCE_FILE_TYPES } from 'enums'
import { useAction } from 'hooks'
import { INITIAL_CATEGORY } from 'reducers/sourceFiles'
import {
  getIsSourceFilesLoading,
  selectSourceFilesParams,
  selectTotalCount
} from 'selectors'
import { TranslationContext } from 'contexts/TranslationContext'
import Header from './Header'

import { ReactComponent as NativeImagesIcon } from '~/assets/icons/native-images.svg'
import { ReactComponent as PexelsIcon } from '~/assets/icons/pexels.svg'
import { ReactComponent as UnsplashIcon } from '~/assets/icons/unsplash.svg'

import SearchMenu from '../Audio/SearchMenu'
import { useCategoryFetch } from './hooks/useCategoryFetch'
import styles from './list.module.scss'
import { selectElementDisplayType } from '~/selectors/useInterface'
import { DISPLAY_TYPE } from '~/config/constants/ui'

const sourceIcons = {
  [IMAGES_SOURCES.Native]: <NativeImagesIcon />,
  [IMAGES_SOURCES.Pexels]: <PexelsIcon />,
  [IMAGES_SOURCES.Unsplash]: <UnsplashIcon />,
}

const useSourceItems = () => {
  const { t } = useContext(TranslationContext)

  const sourceTexts = {
    [IMAGES_SOURCES.Native]: t('IMAGES_TEXT_NATIVE_IMAGES'),
    [IMAGES_SOURCES.Pexels]: t('IMAGES_TEXT_PEXELS'),
    [IMAGES_SOURCES.Unsplash]: t('IMAGES_TEXT_UNSPLASH'),
  }

  function getSourceItem(type) {
    return {
      id: type,
      text: (
        <>
          {sourceIcons[type]}
          {' '}
          <span className={styles.dropdownItemText}>{sourceTexts[type]}</span>
        </>
      ),
    }
  }

  const allSourceItems = [
    {
      ...getSourceItem(IMAGES_SOURCES.Native),
    },
    {
      ...getSourceItem(IMAGES_SOURCES.Pexels),
    },
    {
      ...getSourceItem(IMAGES_SOURCES.Unsplash),
    },
  ]

  return { allSourceItems, sourceTexts }
}

export const HeaderContainer = ({ type }) => {
  const { t } = useContext(TranslationContext)

  // const [ currentTab, setCurrentTab ] = useState(imageTabs[0].id)

  const {
    search = '',
    category,
    source,
    imageLibSources,
  } = useSelector(state => selectSourceFilesParams(state, SOURCE_FILE_TYPES.IMAGES))

  const { allSourceItems, sourceTexts } = useSourceItems()
  const sourceItems = useMemo(
    () => allSourceItems.filter(s => imageLibSources?.includes(s.id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ imageLibSources ]
  )

  const { id: categoryId, name } = category

  const itemsLoading = useSelector(state => getIsSourceFilesLoading(state, type))
  const totalCount = useSelector(state => selectTotalCount(state, type))
  const { sourceList: sourceListDisplayType } = useSelector(selectElementDisplayType)

  const categories = useCategoryFetch(source)

  const setSourceFilesParams = useAction(setSourceFilesParamsAction, type)

  const handleSearch = useCallback(
    search => {
      setSourceFilesParams({ search })
    },
    [ setSourceFilesParams ]
  )

  function handleSearchMenuSelect(categoryId, name) {
    setSourceFilesParams({ category: { id: categoryId, name } })
  }

  /* function handleTabChange(item) {
    setCurrentTab(item)
  } */

  function handleClickSource(source) {
    setSourceFilesParams({ source, category: INITIAL_CATEGORY })
  }
  return (
    <>
      <Header caption={t('IMAGES_HEADER_CAPTION')} type={type} />
      <If condition={__CFG__.IMAGES_AI_SOURCE}>
        {/* <Navbar
          items={imageTabs}
          selected={currentTab}
          onSelect={handleTabChange}
        /> */}
      </If>
      <div className={sourceListDisplayType === DISPLAY_TYPE.MIN_WIDTH
        ? styles.container
        : styles.rowContainer}
      >
        <div className={styles.multiFilter}>
          <SearchInput
            value={search}
            onSearch={handleSearch}
            className={classNames('search_input', styles.imagesSearch, {
              [styles.imagesSearchCombo]: sourceItems.length > 1,
            })}
            placeholder={`${t('IMAGES_PLACEHOLDER_SEARCH_IN')} ${name}`}
          />
        </div>
        <div className={`${styles.imgSelectors} ${sourceListDisplayType === DISPLAY_TYPE.MIN_WIDTH
          ? styles.imgSelectorsColumn
          : styles.imgSelectorsRow}`}
        >
          <If condition={sourceItems.length > 1}>
            <DropdownMenu
              emitFromContent
              dropdownClassName={styles.dropdownContainer}
              className={styles.dropdownSource}
              menuItems={sourceItems}
              onClick={handleClickSource}
            >
              <Input
                startAdornment={sourceIcons[source]}
                className={styles.sourceInput}
                value={sourceTexts[source]}
                readOnly
              />
            </DropdownMenu>
          </If>
          <SearchMenu
            items={categories}
            includesAll
            onSelect={handleSearchMenuSelect}
            category={categoryId}
            search=""
            placeholder={t('IMAGES_PLACEHOLDER_SELECT_COLLECTION')}
            className={styles.inputSearch}
          />
        </div>
      </div>
      {search !== '' && !itemsLoading && !!totalCount && (
        <div className={styles.counter}>
          Found items:
          {totalCount}
        </div>
      )}
    </>
  )
}
