import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { TimelineScrollPositionContext } from 'components/Timeline/ScrollPositionContext'
import { TimelineTopOffsetContext } from 'components/Timeline/TimelineTopOffsetContext'
import { ItemType } from 'components/CustomDragLayerContext'
import { getAssets } from 'selectors'
import { LAYER_HEIGHT_WITH_BORDER } from 'constant'
import { DRAGNDROP_TYPE } from 'enums'
import { getLayerIndexFromY } from 'Util/timeline-coords'

const NEW_LAYER_HOVER_OFFSET = 20

type Props = {
  clientOffset: {
    x: number,
    y: number,
  },
  itemType: ItemType
}

function useOverNewLayer({ clientOffset, itemType }: Props) {
  const { scrollTop } = useContext(TimelineScrollPositionContext)
  const topOffset = useContext(TimelineTopOffsetContext)
  const layersLength = useSelector((state: RootState) => state.timeline.layers.length)
  const assetsLength = useSelector(getAssets).length

  const incompatibleType = [
    DRAGNDROP_TYPE.TRANSITION_ITEM,
    DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET,
    DRAGNDROP_TYPE.NATIVE_FILE,
    DRAGNDROP_TYPE.SOURCE_LIST_HEADER,
    DRAGNDROP_TYPE.TIMELINE_PANEL,
  ].includes(itemType as Extract<ItemType, 'transition-item' | 'transition-asset' | '__NATIVE_FILE__'>)
  if (!clientOffset || incompatibleType) return { overNewLayerIndex: null }

  const { y: clientY } = clientOffset

  const layerIndex = getLayerIndexFromY({
    y: clientY + scrollTop,
    topOffset,
    layersLength,
  })

  let overNewLayerIndex = getOverNewLayerBoxIndex({ clientY,
    timelineScrollTop: scrollTop,
    topOffset,
    layerIndex })

  if (overNewLayerIndex === layersLength || assetsLength === 0) {
    overNewLayerIndex = null
  }

  return { overNewLayerIndex,
    layerIndex,
    layersLength,
    scrollTop }
}

function getOverNewLayerBoxIndex({ clientY, timelineScrollTop, topOffset, layerIndex }: {
  clientY: number, timelineScrollTop: number, topOffset: number, layerIndex: number
}) {
  let overNewLayerBoxIndex = null

  if ((clientY + timelineScrollTop - topOffset) > ((layerIndex + 1) * LAYER_HEIGHT_WITH_BORDER
    - NEW_LAYER_HOVER_OFFSET)) {
    overNewLayerBoxIndex = layerIndex + 1
  }

  if ((clientY + timelineScrollTop - topOffset) < (layerIndex * LAYER_HEIGHT_WITH_BORDER
    + NEW_LAYER_HOVER_OFFSET)) {
    overNewLayerBoxIndex = layerIndex
  }

  return overNewLayerBoxIndex
}

export default useOverNewLayer
