import React, { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectEditorSettings } from 'selectors/mainView'
import { Box, Checkbox, FormControlLabel } from '@material-ui/core'
import { FormContext } from 'contexts/FormContext'
import { RenderSettingsEnum } from 'config/constants/editorSettings'
import { EDITOR_MAIN_SETTINGS_MENU_ITEMS } from 'config/constants/mainView'
import { TranslationContext } from 'contexts/TranslationContext'
import { EditorSettingsChapterType } from '../types/main'

export const RenderSettings = memo(() => {
  const { handleInput } = useContext(FormContext)
  const settings = useSelector(selectEditorSettings) as EditorSettingsChapterType
  const { RENDER_SETTINGS } = EDITOR_MAIN_SETTINGS_MENU_ITEMS
  const { t } = useContext(TranslationContext)

  return (
    <Box width="100%">
      <form className="render-settings" key={RenderSettingsEnum.skipEmptySpace}>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={handleInput}
              color="primary"
              name={RenderSettingsEnum.skipEmptySpace}
              defaultChecked={Boolean(settings[RENDER_SETTINGS]?.[RenderSettingsEnum.skipEmptySpace])}
            />
          )}
          label={t('RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP')}
        />
      </form>
    </Box>
  )
})
