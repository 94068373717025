import { setActiveDragElement, setDragElementBounds } from 'actions/mainView'
import { DRAGNDROP_TYPE } from 'enums'
import { useAction } from 'hooks/utils'
import { useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

type UseTransformUI = {
  type: keyof typeof DRAGNDROP_TYPE,
  isMouseDown: boolean,
}

export const useTransformUI = ({ type }: UseTransformUI) => {
  const dragElement = useRef<HTMLDivElement | null>(null)
  const onSetActiveDragElement = useAction(setActiveDragElement)
  const onSetDragElementBounds = useAction(setDragElementBounds)
  const activeElem = type === DRAGNDROP_TYPE.SOURCE_LIST_HEADER
    ? DRAGNDROP_TYPE.SOURCE_LIST_HEADER
    : DRAGNDROP_TYPE.TIMELINE_PANEL

  const item = { type }
  const [{ isDragging }, drag, preview ] = useDrag({
    begin: () => {
      onSetActiveDragElement(activeElem)
      const bounds = dragElement.current?.getBoundingClientRect()
      if (bounds) {
        onSetDragElementBounds(bounds)
      }
    },
    item,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onSetActiveDragElement(null)
      onSetDragElementBounds({})
      dragElement.current = null
    },
  })

  const onDrag = (ref: HTMLDivElement) => {
    dragElement.current = ref
    drag(ref)
  }
  useEffect(
    () => { preview(getEmptyImage()) },
    [ preview ]
  )

  return { drag: onDrag, isDragging, dragElement }
}
