import * as ActionTypes from '~/actions/ActionTypes'
import { loadProject } from './loadProject'
import { setReference } from '~/actions/project/editingTask'

export const initNewProject = () => async dispatch => {
  await dispatch(loadProject(null))
  await dispatch({
    type: ActionTypes.INSERT_LAYER,
    payload: { index: 0 },
  })
  dispatch(setReference())
}
