import { DROP_CONTAINER } from 'config/constants/ui'

export const DROP_CONTAINER_DEFAULT_WIDTH = '16px'
export const DROP_CONTAINER_DEFAULT_HEIGHT = '16px'

export enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}

export interface ITransformUIDropContainer {
  width?: string,
  height?: string,
  position?: 'absolute' | 'relative' | 'static' | 'fixed'
  orientation: Orientation,
  left?: number | string,
  right?: number | string,
  dropContainer: keyof typeof DROP_CONTAINER
}
