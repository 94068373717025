import { isNil } from 'lodash'
import { isVideo } from '~/Util/assets'
import { selectReference } from '~/selectors/select-reference'
import { selectEditorSettings } from '~/selectors/mainView'
import { TIME_CODE_CONTAINER_LOCATION, TIME_CODE_MODE } from '~/config/constants/timecode'
import { EDITOR_MAIN_SETTINGS_MENU_ITEMS } from '~/config/constants/mainView'
import { setTimeCodeMode } from './playback'
import { BehaviourSettingsEnum } from '~/config/constants/editorSettings'


export const setSourceFileTimeCodeFormat = (file, state, dispatch) => {
  const { id: referenceId } = selectReference(state)
  const settings = selectEditorSettings(state)
  if (isNil(referenceId)
      && isVideo(file)
      && settings[
        EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS
      ]?.[BehaviourSettingsEnum.setTimecodeFormatBasedOnMedia]
  ) {
    dispatch(setTimeCodeMode({
      mode: file.dfFlag ? TIME_CODE_MODE.FRAME_DF : TIME_CODE_MODE.FRAME_NDF,
      containerLocation: TIME_CODE_CONTAINER_LOCATION.TIMELINE,
    }))
  }
}
