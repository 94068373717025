import { RENDER_SETTINGS_FORM_STATE, BEHAVIOUR_SETTINGS_FORM_STATE } from 'config/constants/editorSettings'
import { EDITOR_MAIN_SETTINGS_MENU_ITEMS } from 'config/constants/mainView'
import { MenuItemType } from 'types/editorSettings'

export const useEditorSettings = ({ activeMenuItem }: { activeMenuItem: MenuItemType }) => {
  const getInitialFormState = (activeMenuItem: MenuItemType) => {
    switch (activeMenuItem) {
      case EDITOR_MAIN_SETTINGS_MENU_ITEMS.RENDER_SETTINGS: {
        return {
          ...RENDER_SETTINGS_FORM_STATE,
          ...JSON.parse(localStorage.getItem(activeMenuItem) || '{}'),
        }
      }
      case EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS: {
        return {
          ...BEHAVIOUR_SETTINGS_FORM_STATE,
          ...JSON.parse(localStorage.getItem(activeMenuItem) || '{}'),
        }
      }
      default:
        return {}
    }
  }

  return {
    initialState: getInitialFormState(activeMenuItem),
  }
}
