import { setUIElementDisplayType } from 'actions/userInterface'
import { DISPLAY_TYPE, DROP_CONTAINER, UI_ELEMENT } from 'config/constants/ui'
import { DRAGNDROP_TYPE } from 'enums'
import { useAction } from 'hooks/utils'
import { DropTargetMonitor, useDrop } from 'react-dnd'
import { useSelector } from 'react-redux'
import { selectActiveDragElement } from 'selectors/mainView'
import { selectElementDisplayType } from 'selectors/useInterface'
import {
  DROP_CONTAINER_DEFAULT_HEIGHT,
  DROP_CONTAINER_DEFAULT_WIDTH,
  ITransformUIDropContainer,
  Orientation
} from 'types/userInterface'

const acceptedTypes = [ DRAGNDROP_TYPE.SOURCE_LIST_HEADER, DRAGNDROP_TYPE.TIMELINE_PANEL ]
type Item = { type: typeof acceptedTypes[number] }

export const useTransformUIDropContainer = ({
  width,
  height,
  orientation,
  position,
  dropContainer,
  ...rest
}: ITransformUIDropContainer) => {
  const activeDragElement = useSelector(selectActiveDragElement)
  const {
    timeline: timelineDisplayType,
  } = useSelector(selectElementDisplayType)
  const onSetUIElementDisplayType = useAction(setUIElementDisplayType)
  let isShowDropContainer = false

  if (activeDragElement === DRAGNDROP_TYPE.SOURCE_LIST_HEADER) {
    if (dropContainer === DROP_CONTAINER.LEFT_MENU) {
      isShowDropContainer = timelineDisplayType === DISPLAY_TYPE.FULL_WIDTH
    }
    if (dropContainer === DROP_CONTAINER.HEADER_MENU) {
      isShowDropContainer = !(timelineDisplayType === DISPLAY_TYPE.FULL_WIDTH)
    }
  }

  if (activeDragElement === DRAGNDROP_TYPE.TIMELINE_PANEL) {
    if (dropContainer === DROP_CONTAINER.TIMELINE_ON_RIGHT) {
      isShowDropContainer = timelineDisplayType === DISPLAY_TYPE.FULL_WIDTH
    }
    if (dropContainer === DROP_CONTAINER.FOOTER) {
      isShowDropContainer = !(timelineDisplayType === DISPLAY_TYPE.FULL_WIDTH)
    }
  }

  const [ collectedProps, drop ] = useDrop({
    accept: acceptedTypes,
    drop: (item: Item) => {
      switch (item.type) {
        case DRAGNDROP_TYPE.SOURCE_LIST_HEADER:
          if (dropContainer === DROP_CONTAINER.LEFT_MENU) {
            onSetUIElementDisplayType(DISPLAY_TYPE.HALF_WIDTH, UI_ELEMENT.TIMELINE)
          }
          if (dropContainer === DROP_CONTAINER.HEADER_MENU) {
            onSetUIElementDisplayType(DISPLAY_TYPE.FULL_WIDTH, UI_ELEMENT.TIMELINE)
          }
          break
        case DRAGNDROP_TYPE.TIMELINE_PANEL:
          if (dropContainer === DROP_CONTAINER.TIMELINE_ON_RIGHT) {
            onSetUIElementDisplayType(DISPLAY_TYPE.HALF_WIDTH, UI_ELEMENT.TIMELINE)
          }
          if (dropContainer === DROP_CONTAINER.FOOTER) {
            onSetUIElementDisplayType(DISPLAY_TYPE.FULL_WIDTH, UI_ELEMENT.TIMELINE)
          }
          break
        default:
          break
      }
    },
    canDrop: (item: Item) => acceptedTypes.includes(item.type),
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
    }),
  })

  const styles = {
    position: position || 'absolute',
    width: width || (orientation === Orientation.Horizontal ? '100vw' : DROP_CONTAINER_DEFAULT_WIDTH),
    height: height || (orientation === Orientation.Horizontal ? DROP_CONTAINER_DEFAULT_HEIGHT : '100vh'),
    background: 'rgba(47, 128, 237, 0.5)',
    zIndex: 3,
    display: isShowDropContainer ? 'block' : 'none',
    ...rest,
  } as React.CSSProperties

  return { styles, drop, collectedProps, isShowDropContainer }
}
