import React from 'react'
import List from '../BaseAssetsList'
import Item from './ListItem'
import { HeaderContainer } from './HeaderContainer'

export default function TransitionAssetsList(props) {
  return (
    <List
      {...props}
      header={<HeaderContainer />}
      ItemComponent={Item}
    />
  )
}
