import React, { useCallback, useState } from 'react'
import { ContextStateType, IContextProps, ProjectMenuContext } from 'contexts/ProjectContext'

export const ProjectContextMenuProvider = ({ children }: IContextProps) => {
  const initialContextMenuData = { projectId: '', visible: false, top: 0, left: 0 }
  const [ contextMenu, setContextMenu ] = useState<ContextStateType>({ ...initialContextMenuData })

  const handleContextMenu = useCallback((e: MouseEvent | KeyboardEvent | null, data?: ContextStateType) => {
    if (e?.type === 'keydown' && (e as KeyboardEvent)?.code !== 'Escape') {
      return
    }
    if (data) {
      setContextMenu(data)
      document.addEventListener('click', handleContextMenu)
      document.addEventListener('keydown', handleContextMenu)
    } else {
      setContextMenu({ ...initialContextMenuData })
      document.removeEventListener('click', handleContextMenu)
      document.removeEventListener('keydown', handleContextMenu)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setContextMenu ])

  return (
    <ProjectMenuContext.Provider value={{
      contextMenu,
      handleContextMenu,
    }}
    >
      {children}
    </ProjectMenuContext.Provider>
  )
}
