export default {
  LICENSE_PING_INTERVAL: 10_000,
  LONG_KEY_CHECK_PERIOD: 1210000000, // 2 weeks
  SHORT_KEY_CHECK_PERIOD: 259200000, // 3 days
  PERIOD_WITH_FAILED_AND_WITH_ERROR: 2592000000, // 1 month
  LOCAL_STORAGE_TOKEN_VAR: 'authTokenForLicenceKeys',
  LOCAL_STORAGE_KEY_VAR: 'activeLicenseKey',
  LOCAL_STORAGE_ACTIVATION_KEY_DATE_VAR: 'activationKeyDate',
  LOCAL_STORAGE_LAST_CHECK_KEY_DATE_VAR: 'lastCheckKeyDate',
  LOCAL_STORAGE_FAILED_CHECK_DATE_VAR: 'failedCheckDate',
  LOCAL_STORAGE_EDITION_VAR: 'licenseEdition',
  FREE_KEY: 'Free',
  ERROR_TYPE: 'ACTIVATION_NO_CONNECTION',
  BASE_AUTH_URL: '',
  WSS_AUTH_URL: '',
  PRODUCT_NAME: '',
  AUTHORIZATION_REQUIRED: false,
  KEY_REQUIRED: false,
}
