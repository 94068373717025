import React, { useCallback, useContext, useState } from 'react'
import { Box } from '@material-ui/core'
import cs from 'classnames'
import { EDITOR_MAIN_SETTINGS_MENU } from 'config/constants/mainView'
import { TranslationContext } from 'contexts/TranslationContext'
import { useAction } from 'hooks'
import { setEditorSettings, showEditorMainSettings } from 'actions/mainView'
import { FormContextProvider } from 'providers/FormContextProvider'
import { MenuItemType } from 'types/editorSettings'
import { useEditorSettings } from 'hooks/editor/useEditorSettings'
import { EditorSettingsParams } from './EditorSettingsParams'
import './EditorSettings.scss'
import { Header } from './components/Header'
import { Footer } from './components/Footer'

export const EditorSettingsComponent = () => {
  const { t } = useContext(TranslationContext)
  const [ activeMenuItem, setActive ] = useState<MenuItemType>(EDITOR_MAIN_SETTINGS_MENU[0].key)
  const hideEditorMainSettings = useAction(showEditorMainSettings, false)
  const { initialState } = useEditorSettings({ activeMenuItem })
  const action = setEditorSettings.bind(null, activeMenuItem)

  const onClose = useCallback(() => {
    hideEditorMainSettings()
  }, [ hideEditorMainSettings ])
  return (
    <Box className="editor-settings">
      <Header t={t} onClose={onClose} />
      <FormContextProvider<typeof initialState>
        initialState={initialState}
        action={action}
      >
        <Box display="flex" gridGap={10} className="editor-settings__body">
          <Box className="editor-settings__menu">
            {EDITOR_MAIN_SETTINGS_MENU.map(menuItem => (
              <div
                key={menuItem.name}
                onClick={() => setActive(menuItem.key)}
                className={cs('editor-settings__menu-item', {
                  active: menuItem.key === activeMenuItem,
                })}
              >
                {t(menuItem.name)}
              </div>
            ))}
          </Box>
          <EditorSettingsParams activeMenuItem={activeMenuItem} />
        </Box>
        <Footer t={t} onClose={onClose} />
      </FormContextProvider>
    </Box>
  )
}
