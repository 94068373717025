import { SOURCE_FILE_TYPES, TRANSITIONS, TEXTS } from 'enums'
import { PlaceholderAsset, TransitionAsset } from 'models/Asset'
import { getCoordsFromOffset } from '~/components/Preview/AssetsOverlay/lib'


export const isTransition = assetType => Object.values(TRANSITIONS).includes(assetType)

export const isText = assetType => Object.values(TEXTS).includes(assetType)

const imageExtensions = [ 'png', 'jpg', 'apng', 'avif', 'gif', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp' ]

export const isImage = asset => imageExtensions.includes(asset.filetype)
|| asset.sourceFileType === SOURCE_FILE_TYPES.IMAGES

const audioExtensions = [ 'mp3', 'm4a', 'flac', 'wav', 'wma', 'aac' ]

export const isAudio = assetFileType => audioExtensions.includes(assetFileType)

export const isResizableAsset = asset => !(asset instanceof TransitionAsset
  || asset instanceof PlaceholderAsset)

export const isVideo = asset => {
  let isVideo = true
  for (const fn of [ isTransition, isText, isImage, isAudio ]) {
    isVideo = fn === isImage ? !fn(asset) : !fn(asset.type)
    if (!isVideo) {
      break
    }
  }
  return isVideo
}

export const getVideoPosition = ({ offset, size,
  scale, boundingRect, rotation }) => {
  const scaledBounding = {
    width: boundingRect.width / scale.x,
    height: boundingRect.height / scale.y,
    x: boundingRect.offsetX / scale.x,
    y: boundingRect.offsetY / scale.y,
  }
  const { x, y, rotatedWidth, rotatedHeight } = getCoordsFromOffset(offset, { x: 0, y: 0 },
    { boundingRect: scaledBounding,
      assetKonvaSize: size,
      rotation,
      scale })

  return {
    x: Math.round(x - (rotatedWidth / 2)),
    y: Math.round(y - (rotatedHeight / 2)),
  }
}
