import React, { memo, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import { noop } from 'lodash'
import './DragTransformUIContainer.scss'
import { DRAGNDROP_TYPE, MENU_ITEMS } from 'enums'
import { CustomDragLayerContext } from 'components/CustomDragLayerContext'
import { selectDragElementBounds, selectMenuItem } from 'selectors/mainView'
import { SourceMediaListHeader } from 'components/AssetPanels/BaseAssetsList'
import MediaHeaderContainer from 'components/AssetPanels/Media/HeaderContainer'
import { HeaderContainer as AudioHeaderContainer } from 'components/AssetPanels/Audio/HeaderContainer'
import { HeaderContainer as TextHeaderContainer } from 'components/AssetPanels/Text/HeaderContainer'
import { HeaderContainer as TransitionHeaderContainer } from 'components/AssetPanels/Transition/HeaderContainer'
import { HeaderContainer as ImageHeaderContainer } from 'components/AssetPanels/Images/HeaderContainer'
import TimelineToolbar from '../Timeline/Toolbar'

const headersMap = {
  [MENU_ITEMS.AUDIO]: AudioHeaderContainer,
  [MENU_ITEMS.IMAGES]: ImageHeaderContainer,
  [MENU_ITEMS.TEXT]: TextHeaderContainer,
  [MENU_ITEMS.TRANSITIONS]: TransitionHeaderContainer,
  [MENU_ITEMS.MEDIA]: MediaHeaderContainer,
}

const DragUIComponent = () => {
  const { clientOffset, itemType } = useContext(CustomDragLayerContext)
  const startBounds = useSelector(selectDragElementBounds)
  const menuItem = useSelector(selectMenuItem) as keyof typeof headersMap

  const dragElement = useRef<HTMLDivElement | null>(null)
  const clickCoords = useRef<{ x: number, y: number }>({ x: 0, y: 0 })

  useEffect(() => {
    const setX = (e: MouseEvent) => {
      clickCoords.current = { x: e.clientX, y: e.clientY }
    }
    const resetX = () => {
      clickCoords.current = { x: 0, y: 0 }
    }

    window.addEventListener('mousedown', setX)
    window.addEventListener('mouseup', resetX)
    return () => {
      window.removeEventListener('mousedown', setX)
      window.removeEventListener('mouseup', resetX)
    }
  }, [ itemType ])

  const x = clientOffset?.x ?? 0
  const y = clientOffset?.y ?? 0

  const SourceFileHeaderComponent = headersMap[menuItem]

  switch (itemType) {
    case DRAGNDROP_TYPE.SOURCE_LIST_HEADER:
      return (
        <div
          ref={dragElement}
          style={{
            width: `${startBounds.width}px`,
            transform: `
              translate(
              ${x - (clickCoords.current.x - startBounds.left)}px,
              ${y - (clickCoords.current.y - startBounds.top)}px
              )
            `,
          }}
          className="drag-transform-ui-container__body"
        >
          <SourceMediaListHeader header={<SourceFileHeaderComponent type={menuItem} />} asDragContainer />
        </div>
      )
    case DRAGNDROP_TYPE.TIMELINE_PANEL:
      return (
        <div
          ref={dragElement}
          style={{
            width: `${startBounds.width}px`,
            transform: `
              translate(
              ${x - (clickCoords.current.x - startBounds.left)}px,
              ${y - (clickCoords.current.y - startBounds.top)}px
              )
            `,
          }}
          className="drag-transform-ui-container__body"
        >
          <TimelineToolbar disabled={false} onMoveSlider={noop} asDraggable />
        </div>
      )
    default:
      return null
  }
}

export const DragTransformUIContainer = memo(() => createPortal(
  <div className="drag-transform-ui-container">
    <DragUIComponent />
  </div>, document.body
))
