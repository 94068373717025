import produce from 'immer'
import * as ActionTypes from 'actions/ActionTypes'
import { UI_ELEMENT } from 'config/constants/ui'

/**
 * @typedef {object} ElementelementDisplayTypeObject
 * @property {string} timeline
 * @property {string} sourceList
 */

/**
 * @typedef {object} InterfaceState
 * @property {object} splitSize
 * @property {boolean} resizing
 * @property {string | null} resizer
 * @property {ElementelementDisplayTypeObject} elementelementDisplayType
 */

/**
 * @type {InterfaceState}
 */

const DISPLAY_TYPE_PREFIX = 'UI_ELEMENT_DISPLAY_TYPE_'
const ELEMENT_SIZE_PREFIX = 'UI_ELEMENT_SIZE_'

const initialState = {
  splitSize: {
    media: Number(sessionStorage.getItem(
      `${ELEMENT_SIZE_PREFIX}${UI_ELEMENT.SOURCE_LIST}`
    ) || __CFG__.INITIAL_WIDTH.SOURCE_MEDIA),
  },
  resizing: false,
  resizer: null,
  elementDisplayType: {
    [UI_ELEMENT.TIMELINE]: sessionStorage.getItem(
      `${DISPLAY_TYPE_PREFIX}${UI_ELEMENT.TIMELINE}`
    ) || __CFG__.INTERFACE.TIMELINE,
    [UI_ELEMENT.SOURCE_LIST]: sessionStorage.getItem(
      `${DISPLAY_TYPE_PREFIX}${UI_ELEMENT.SOURCE_LIST}`
    ) || __CFG__.INTERFACE.SOURCE_MEDIA,
  },
}

const userInterface = (state, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SPLIT_SIZE: {
      const { value, type } = action.payload
      state.splitSize[type] = value
      sessionStorage.setItem(
        `UI_ELEMENT_SIZE_${UI_ELEMENT.SOURCE_LIST}`, String(value)
      )
    }
      break
    case ActionTypes.SET_RESIZING: {
      const { resizing, resizer } = action.payload
      if (resizing) {
        state.resizing = resizing
        state.resizer = resizer
      } else {
        state.resizing = false
        state.resizer = null
      }
    }
      break
    case ActionTypes.SET_UI_ELEMENT_DISPLAY_TYPE: {
      const { type, element } = action.payload
      state.elementDisplayType[element] = type
      sessionStorage.setItem(
        `${DISPLAY_TYPE_PREFIX}${UI_ELEMENT.TIMELINE}`,
        state.elementDisplayType[UI_ELEMENT.TIMELINE]
      )
      sessionStorage.setItem(
        `${DISPLAY_TYPE_PREFIX}${UI_ELEMENT.SOURCE_LIST}`,
        state.elementDisplayType[UI_ELEMENT.SOURCE_LIST]
      )
    }
      break
    default:
      break
  }
}

export default produce(userInterface, initialState)
