import * as ActionTypes from 'actions/ActionTypes'
import * as ActionsLayer from 'actions/layer'
import Layer from '~/models/Layer'
import * as Selectors from '~/selectors'
import { createAssetFromSourceFile } from './createAssetFromSourceFile'
import { insertLayer } from './insertLayer'
import { selectInOutPointsTimeLineAssetID } from '~/selectors/preview'
import { isVideo } from '~/Util/assets'

export const addSourceFileToTimePosition = ({ file, dispatch, getState, timePosition }) => {
  // @link http://18.184.210.86/issues/380
  // Always insert clip at top layer
  const state = getState()
  const { sliderTime } = state.timeline
  const targetLayerIndex = 0
  const targetLayer = Selectors.getLayers(state)[targetLayerIndex]
  const referenceVideo = Selectors.getReferenceVideoAsset(state)

  const assetAtCursor = Selectors.getPlayingAssets(state)
    .find(asset => asset.layerId === targetLayer.id)

  const inOutPointsTimeLineAssetId = selectInOutPointsTimeLineAssetID(state)
  const inOutPointsTimeLineAsset = Selectors.selectAssetById(state, inOutPointsTimeLineAssetId)

  const currentFile = file.id === inOutPointsTimeLineAsset?.fileId
    ? inOutPointsTimeLineAsset
    : file


  let fitBetweenSliderTimeAndNearestAsset = false
  if (assetAtCursor === undefined) {
    let rightSideAssets = []
    let leftBound = sliderTime
    if (timePosition !== sliderTime) {
      leftBound = timePosition
      const layerAssets = Selectors.getLayerAssets(state, targetLayer.id)
      rightSideAssets = layerAssets
        .filter(a => a.startTime >= timePosition)
        .sort((a, b) => a.startTime - b.startTime)
    } else {
      rightSideAssets = Selectors.getRightSideAssetsBySliderTimeAndLayer(targetLayer.id)(state)
    }
    if (((rightSideAssets[0]?.startTime ?? 0) - leftBound) >= currentFile.duration) {
      fitBetweenSliderTimeAndNearestAsset = true
    }
  }

  let assetLayerId
  if (assetAtCursor === undefined && fitBetweenSliderTimeAndNearestAsset) {
    assetLayerId = targetLayer.id
  } else {
    const newLayer = new Layer()
    dispatch(insertLayer(targetLayerIndex, newLayer))
    assetLayerId = newLayer.id
  }

  const asset = createAssetFromSourceFile(currentFile, {
    layerId: assetLayerId,
    startTime: timePosition,
    videoSize: { width: referenceVideo.width, height: referenceVideo.height },
  })

  if (Selectors.getAssets(state).length === 0 && isVideo(currentFile)) {
    const { width, height, fpsNum, fpsDenum } = currentFile
    dispatch({
      type: ActionTypes.SET_REFERENCE,
      payload: { width, height, fpsNum, fpsDenum, id: currentFile.fileId },
    })
  }
  dispatch(ActionsLayer.addAsset(asset))
}
